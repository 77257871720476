import React from "react";

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Button
} from "@material-ui/core";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import StepIconComponent from "./StepIconComponent";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  gridButtonsGroup: {
    paddingLeft: 24
  }
}));

function getSteps() {
  return [
    "Create advertiser",
    "Create a bussiness user",
    "Inform the bussiness user payment method"
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <Step1 />;
    case 1:
      return <Step2 />;
    case 2:
      return <Step3 />;
    default:
      return "Unknown step";
  }
}

function Wizard(props) {
  const classes = useStyles();
  const { open, handleSave, handleClose } = props;
  const [activeStep, setActiveStep] = React.useState(0);

  const steps = getSteps();

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<StepConnector />}
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel StepIconComponent={StepIconComponent}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogTitle>
      <DialogContent dividers>{getStepContent(activeStep)}</DialogContent>
      <DialogActions>
        {activeStep === steps.length ? (
          <>
            <h3>All steps completed - you are finished</h3>
            <Button onClick={handleReset}>Reset</Button>
          </>
        ) : (
          <>
            <Button disabled={activeStep === 0} onClick={handleBack}>
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleReset();
                  handleSave();
                }}
              >
                Finish
              </Button>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNext}
              >
                Next
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default Wizard;
