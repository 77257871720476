import React from "react";

import {
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  TextField,
  Select,
  MenuItem,
  InputLabel
} from "@material-ui/core";

import PaymentCard from "react-payment-card-component";

function Step3() {
  const [value, setValue] = React.useState("pm_bacs");
  const [entity, setEntity] = React.useState("personal");
  const [accountType, setAccountType] = React.useState("iban");

  const [numberCard, setNumberCard] = React.useState("4111111111111111");
  const [brandCard, setBrandCard] = React.useState("");
  const [holderName, setHolderName] = React.useState("KAU MEDIA GROUP");
  const [expiration, setExpiration] = React.useState("02/22");
  const [flipped, setFlipped] = React.useState(false);
  const [cvv, setCVV] = React.useState("202");

  const handlerNumberCard = e => setNumberCard(e.target.value);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Payment method</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={e => setValue(e.target.value)}
          >
            <FormControlLabel
              value="pm_bacs"
              control={<Radio />}
              label="BACS payment"
            />
            <FormControlLabel
              value="pm_cc"
              control={<Radio />}
              label="Credit or Debit card"
            />
            <FormControlLabel
              value="pm_dd"
              control={<Radio />}
              label="Direct debit"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      {value === "pm_bacs" && (
        <Grid item xs={6}>
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Bank details</FormLabel>
            <TextField
              // id="outlined-multiline-static"
              // label="Multiline"
              name="pm_bacs_info"
              multiline
              rows={6}
              inputProps={{ readOnly: true }}
              defaultValue={`KAU Media Group Ltd
              Company Number: 9669756
              VAT number: 217741902
              IBAN: GB07BUKB20359360221945
              Account number: 60221945
              Sort code: 20 - 35 - 93`.replace(/^\s+|\s+$/gm, "")}
              variant="outlined"
            />
          </FormControl>
        </Grid>
      )}
      {value === "pm_cc" && (
        <>
          <Grid container item justify="center" xs={12}>
            <PaymentCard
              bank="default"
              model="personnalite"
              type="black"
              brand={brandCard}
              number={numberCard}
              cvv={cvv}
              holderName={holderName}
              expiration="12/20"
              flipped={flipped}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Card Type</InputLabel>
              <Select
                label="Card Type"
                onChange={e => {
                  switch (e.target.value) {
                    case "visa":
                    case "visa_debit":
                    case "visa_electron":
                    case "visa_credit":
                      setBrandCard("visa");
                      return;
                    case "master":
                      setBrandCard("mastercard");
                      return;
                    default:
                      setBrandCard("");
                      return;
                  }
                }}
              >
                <MenuItem value="visa">Visa</MenuItem>
                <MenuItem value="visa_debit">Visa Debit</MenuItem>
                <MenuItem value="visa_electron">Visa Electron</MenuItem>
                <MenuItem value="visa_credit">Visa Credit</MenuItem>
                <MenuItem value="master">Master</MenuItem>
                <MenuItem value="mastro">Maestro</MenuItem>
                <MenuItem value="amex">Amex</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="pm_card_holder"
              label="Name on Card"
              variant="outlined"
              value={holderName}
              onChange={e => setHolderName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="pm_card_number"
              label="Card Number (digits only)"
              // type="number"
              variant="outlined"
              onChange={handlerNumberCard}
              value={numberCard}
              fullWidth
              inputProps={{ pattern: "^\\d*$", maxlength: "16" }}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="month-select-label">
                Expiration Date Month
              </InputLabel>
              <Select
                labelId="month-select-label"
                label="Expiration Date Month"
              >
                <MenuItem value="jan">Jan(01)</MenuItem>
                <MenuItem value="feb">Feb(02)</MenuItem>
                <MenuItem value="mar">Mar(03)</MenuItem>
                <MenuItem value="apr">Apr(04)</MenuItem>
                <MenuItem value="may">May(05)</MenuItem>
                <MenuItem value="jun">Jun(06)</MenuItem>
                <MenuItem value="jul">Jul(07)</MenuItem>
                <MenuItem value="aug">Aug(08)</MenuItem>
                <MenuItem value="sep">Sep(09)</MenuItem>
                <MenuItem value="oct">Oct(10)</MenuItem>
                <MenuItem value="nov">Nov(11)</MenuItem>
                <MenuItem value="dec">Dec(12)</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Expiration Date Year</InputLabel>
              <Select label="Expiration Date Year">
                <MenuItem value="2021">2021</MenuItem>
                <MenuItem value="2022">2022</MenuItem>
                <MenuItem value="2023">2023</MenuItem>
                <MenuItem value="2024">2024</MenuItem>
                <MenuItem value="2025">2025</MenuItem>
                <MenuItem value="2026">2026</MenuItem>
                <MenuItem value="2027">2027</MenuItem>
                <MenuItem value="2028">2028</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="pm_card_cvv"
              label="Card CVV"
              variant="outlined"
              value={cvv}
              onFocus={() => setFlipped(!flipped)}
              onBlur={() => setFlipped(!flipped)}
              onChange={e => {
                console.log("flipped: ", flipped);

                setCVV(e.target.value);
                // setTimeout(setFlipped(false), 2000);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="pm_address"
              label="Address"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="pm_city"
              label="City"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="pm_postal_code"
              label="Postal Code"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </>
      )}
      {value === "pm_dd" && (
        <>
          <Grid item xs={12}>
            <RadioGroup
              row
              value={entity}
              name="entity"
              onChange={e => {
                setEntity(e.target.value);
              }}
            >
              <FormControlLabel
                value="personal"
                control={<Radio />}
                label="Personal"
              />
              <FormControlLabel
                value="company"
                control={<Radio />}
                label="Company"
              />
            </RadioGroup>
          </Grid>
          {entity === "personal" && (
            <>
              <Grid item xs={6}>
                <TextField
                  name="pm_dd_personal_first_name"
                  label="First Name"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="pm_dd_personal_last_name"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </>
          )}
          {entity === "company" && (
            <Grid item xs={6}>
              <TextField
                name="pm_dd_company_name"
                label="Company Name"
                variant="outlined"
                fullWidth
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <RadioGroup
              row
              value={accountType}
              name="accountType"
              onChange={e => {
                setAccountType(e.target.value);
              }}
            >
              <FormControlLabel value="iban" control={<Radio />} label="IBAN" />
              <FormControlLabel
                value="account_number"
                control={<Radio />}
                label="Account Number"
              />
            </RadioGroup>
          </Grid>
          {accountType === "iban" && (
            <Grid item xs={6}>
              <TextField
                name="pm_personal_iban"
                label="IBAN"
                variant="outlined"
                placeholder="IBAN (e.g. GB60 BARC 2000 0055 7799 11)"
                fullWidth
              />
            </Grid>
          )}
          {accountType === "account_number" && (
            <>
              <Grid item xs={6}>
                <TextField
                  name="pm_dd_sort_code"
                  label="Sort Code"
                  variant="outlined"
                  placeholder="Sort code (e.g. 10-20-30)"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="pm_dd_account_number"
                  label="Account Number"
                  variant="outlined"
                  placeholder="Account number (e.g. 12345678)"
                  fullWidth
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <TextField
              name="pm_dd_billing_address_line_1"
              label="Billing Address Line 1"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="pm_dd_billing_address_line_2"
              label="Billing Address Line 2"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="pm_dd_country_code"
              label="Country Code(For United Kingdom use GB)"
              placeholder="(e.g. GB, DE, FR)"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="pm_dd_town_city"
              label="Town or City"
              placeholder="Town or City"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="pm_dd_postcode"
              label="Post code"
              placeholder="Post Code"
              variant="outlined"
              fullWidth
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default Step3;
