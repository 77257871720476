import moment from "moment";

const dateFormat = "MMMM D, YYYY";

function formatDate(date) {
  return moment(date).format(dateFormat);
}

function formatNumber(number) {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP"
  }).format(parseFloat(number));
}

function getTotals(data) {
  if (data.length == 0)
    return { totalBudget: 0.0, totalSpend: 0.0, totalRawSpend: 0.0 };

  const totalSpend = data
    .map(item => parseFloat(item.spend))
    .reduce((prev, next) => prev + next);

  const totalBudget = data
    .map(item => parseFloat(item.budget))
    .reduce((prev, next) => prev + next);

  const totalRawSpend = data
    .map(item => parseFloat(item.real_spend))
    .reduce((prev, next) => prev + next);

  return { totalBudget, totalSpend, totalRawSpend };
}

export { formatDate, formatNumber, getTotals };
