import {
  Grid,
  Typography,
  Chip,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Collapse,
  Paper,
  TextField,
  InputAdornment,
  Box
} from "@material-ui/core";
import React from "react";

import enGBLocale from "date-fns/locale/en-GB";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { DatePicker } from "@material-ui/pickers";

import ErrorIcon from "@material-ui/icons/Error";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

import { format, compareAsc } from "date-fns";

import api from "services/api";

import { makeStyles } from "@material-ui/core/styles";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset"
    }
  }
});

const useStyles = makeStyles(theme => ({}));

const sumClientCost = function (array) {
  return array.reduce(
    (accumulator, currentValue) =>
      accumulator + parseFloat(currentValue.client_cost),
    0
  );
};

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: "Google Ads", customerId: "2.00", amount: 2.98 },
      { date: "Facebook", customerId: "3.00", amount: 3.98 }
    ]
  };
}

const rows = [
  createData("2Rent", 770.82, 1541.64, 24, 4.0, 3.99),
  createData("Abbey House Dental", 460.96, 768.26, 37, 4.3, 4.99),
  createData("ABV Solicitors", 1041.43, 1735.74, 24, 6.0, 3.79),
  createData("Andre Ubhi Dentistry", 664.75, 1107.92, 67, 4.3, 2.5),
  createData("Andre & Andrew", 1932.97, 3221.61, 49, 3.9, 1.5)
];

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function Row(props) {
  const { row, fee } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  const increaseGoogleClientCost = function (fee, data) {
    const result = data.filter(item => item.source === "Google Ads");
    if (result) {
      const { margin, spend_cost } = result[0];
      const newMargin = +margin + fee / 100;
      const total = parseFloat(spend_cost) / (1 - newMargin);
      return total.toFixed(2);
    }
    return 0;
  };

  const diffGoogleClientCost = function (fee, data) {
    const result = data.filter(item => item.source === "Google Ads");
    if (result) {
      const { margin, spend_cost, client_cost } = result[0];
      const newMargin = +margin + fee / 100;
      const total = parseFloat(spend_cost) / (1 - newMargin);
      return (total - +client_cost).toFixed(2);
    }
    return 0;
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          {row.data_sources.length ? (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            ""
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.client_name}
        </TableCell>
        <TableCell align="right">{row.spend_cost}</TableCell>
        <TableCell align="right">{row.googlefee}</TableCell>
        <TableCell align="right">{row.client_cost}</TableCell>
        <TableCell align="right">{row.margin}</TableCell>
        <TableCell align="right">
          {+row.margin === 0 ? "0.00" : row.new_margin}
        </TableCell>
        {/* <TableCell align="right">{row.protein}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Other Spends
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Data Source</TableCell>
                    <TableCell align="right">Spend Cost</TableCell>
                    <TableCell align="right">Client Cost</TableCell>
                    <TableCell align="right">Margin</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.data_sources.map(historyRow => (
                    <TableRow key={historyRow.source}>
                      <TableCell component="th" scope="row">
                        {historyRow.source}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.spend_cost}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.client_cost}
                      </TableCell>
                      <TableCell align="right">{historyRow.margin}</TableCell>
                    </TableRow>
                  ))}
                  {/* <TableRow>
                    <TableCell style={{ borderBottom: "none" }} rowSpan={4} />
                    <TableCell colSpan={2} style={{ fontWeight: 600 }}>
                      Subtotal
                    </TableCell>
                    <TableCell align="right">
                      {sumClientCost(row.data_sources)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 600 }}>
                      Google Tax Fees
                    </TableCell>
                    <TableCell align="right">{`+ ${fee.toFixed(
                      0
                    )} %`}</TableCell>
                    <TableCell align="right">
                      {increaseGoogleClientCost(fee, row.data_sources)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 600 }}>
                      Client Cost Diff(Defalcation)
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right">
                      {diffGoogleClientCost(fee, row.data_sources)}
                    </TableCell>
                  </TableRow> */}
                  {/* <TableRow>
                    <TableCell
                      colSpan={2}
                      style={{ fontWeight: 600, borderBottom: "none" }}
                    >
                      Total
                    </TableCell>
                    <TableCell style={{ borderBottom: "none" }} align="right">
                      {(
                        parseFloat(sumClientCost(row.data_sources)) +
                        parseFloat(diffGoogleClientCost(fee, row.data_sources))
                      ).toFixed(2)}
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const formatDate = function (date) {
  return format(date, "MM/yyyy");
};

function GoogleadsFeeSimulator() {
  const staticDateAdapter = new DateFnsAdapter({ locale: enGBLocale });
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState(new Date("2020-09-01"));
  const [fee, setFee] = React.useState(2.0);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    async function loadData() {
      const result = await api.get(
        `/simulations/googleads-fee?date=${formatDate(date)}`
      );
      console.log(result.data);
      setData(result.data);
    }
    console.log("loadData()");
    loadData();
  }, [date]);

  const handleDateChange = args => {
    console.log(args);
    setDate(args);
  };

  return (
    <Grid container>
      <Grid container item style={{ marginBottom: 18 }}>
        <Grid container item xs={11} alignItems="center">
          <Typography variant="h5">Googleads Fee Simulator</Typography>
        </Grid>
        <Grid container item xs={1} justify="flex-end" alignItems="center">
          <Chip
            className={classes.chip}
            icon={<ErrorIcon />}
            color="secondary"
            label="2 Days Behind"
          />
        </Grid>
      </Grid>
      <Grid
        container
        justify="center"
        spacing={3}
        style={{ marginBottom: 8 }}
        xs={12}
      >
        <Grid item xs={1}>
          {/* <DatePicker
            // variant="inline"
            inputVariant="outlined"
            openTo="month"
            views={["year", "month"]}
            label="Date"
            format="MM/yyyy"
            maxDate={new Date("2020-09-30")}
            // helperText="With min and max"
            // minDate={new Date("2019-10-01")}
            // maxDate={new Date()}
            value={date}
            // inputRef={register}
            // onChange={handleDateChange}
            dateAdapter={staticDateAdapter}
            onChange={handleDateChange}
            name="live_date"
            fullWidth
          /> */}
        </Grid>
        <Grid item xs={1}>
          <TextField
            name="fee"
            variant="outlined"
            defaultValue={2}
            label="Fee"
            value={fee}
            onChange={e => setFee(e.target.value)}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
          />
        </Grid>
      </Grid>

      <Grid xs={12}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Advertiser Name</TableCell>
                <TableCell align="right">Spend Cost</TableCell>
                <TableCell align="right">Google Fee 2%</TableCell>
                <TableCell align="right">Client Cost</TableCell>
                <TableCell align="right">Margin</TableCell>
                <TableCell align="right">New Margin</TableCell>
                {/* <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(row => (
                <Row key={row.client_id} row={row} fee={fee} />
              ))}
              {/* <TableRow>
                <TableCell rowSpan={3} />
                <TableCell rowSpan={3} />
                <TableCell colSpan={2} style={{ fontWeight: 600 }}>
                  Subtotal
                </TableCell>
                <TableCell align="right">{ccyFormat(0.0)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 600 }}>Tax</TableCell>
                <TableCell align="right">{`${Number(fee).toFixed(
                  0
                )} %`}</TableCell>
                <TableCell align="right">{ccyFormat(0.0)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} style={{ fontWeight: 600 }}>
                  Total
                </TableCell>
                <TableCell align="right">{ccyFormat(0.0)}</TableCell>
              </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

export default GoogleadsFeeSimulator;
