import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { actions as toastrActions } from "react-redux-toastr";
import toastrOptions from "variables/toastr";
import api from "../../services/api";

import AuthActions from "../ducks/auth";

export function* signIn({ email, password }) {
  try {
    yield put(AuthActions.isLoading(true));
    console.log("sagas/auth.js -> signIn chamado");
    const response = yield call(api.post, "auth", { email, password });
    console.log("sagas/auth.js -> ", response);

    localStorage.setItem("@KMG:token", response.data);

    yield put(AuthActions.signInSuccess(response.data));

    const profile = yield call(api.get, "users/me");
    localStorage.setItem("@KMG:profile", JSON.stringify(profile.data));

    console.log("profile: ", profile);
    console.log("push to /");
    yield put(AuthActions.isLoading(false));
    yield put(push("/"));
  } catch (err) {
    yield put(AuthActions.isLoading(false));
    console.log(err);
    yield put(
      toastrActions.add({
        type: "error",
        title: "Failed Login",
        message: "Verify your email/password!",
        options: toastrOptions
      })
    );
  }
}

export function* signOut() {
  console.log("signOut baby!!");
  localStorage.removeItem("@KMG:token");
  localStorage.removeItem("@KMG:profile");

  yield put(push("/auth"));
}
