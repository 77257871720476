import { Button } from "@material-ui/core";
import React from "react";

// import { Container } from './styles';

function DataImport() {
  const [filename, setFilename] = React.useState("");

  function handleFileInput(event) {
    setFilename(event.target.files[0].name);
  }

  return (
    <div>
      <Button variant="contained" component="label">
        BROWSE
        <input onChange={handleFileInput} type="file" hidden />
      </Button>
      <span>{filename}</span>
    </div>
  );
}

export default DataImport;
