import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Action Creators */

const { Types, Creators } = createActions({
  signInRequest: ["email", "password"],
  signInSuccess: ["token"],
  signOut: null,
  isLoading: ["loading"]
});

export const AuthTypes = Types;
export default Creators;

/* Initial State */
export const INITIAL_STATE = Immutable({
  signedIn: !!localStorage.getItem("@KMG:token"),
  token: localStorage.getItem("@KMG:token") || null,
  profile: localStorage.getItem("@KMG:profile") || null,
  loading: false
});

/* Reducers */

export const success = (state, { token }) => {
  console.log("ducks/auth.js -> ", token);
  return state.merge({ signedIn: true, token });
};

export const logout = state => state.merge({ signedIn: false, token: null });

export const isloading = (state, { loading }) => state.merge({ loading });

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGN_IN_SUCCESS]: success,
  [Types.SIGN_OUT]: logout,
  [Types.IS_LOADING]: isloading
});
