import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import CheckCircle from "@material-ui/icons/CheckCircle";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import Stop from "@material-ui/icons/RemoveCircle";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import CircularProgress from "@material-ui/core/CircularProgress";

import MUIDataTable from "mui-datatables";

import TextField from "@material-ui/core/TextField";

import Autocomplete from "@material-ui/lab/Autocomplete";
import api from "services/api";
import { formatDate, formatNumber, getTotals } from "utils/formats";

import {
  hexToRgb,
  blackColor
} from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles({
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    marginBottom: "8px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex"
  },
  card: {
    marginTop: "0px",
    marginBottom: "8px"
  }
});

const styles = {
  status: {
    display: "flex",
    alignItems: "center"
  },
  statusActive: {
    color: "green"
  },
  statusComplete: {
    color: "#1565C0"
  },
  statusStopped: {
    color: "#c62828"
  },
  statusPaused: {
    color: "#c62828"
  },
  statusPaid: {
    color: "#9E9D24"
  },
  statusCancelled: {
    color: "#c62828"
  },
  statusPending: {
    color: "#EF6C00"
  }
};

export default function KMGSelectPage() {
  const [data, setData] = React.useState([]);
  const [advertiserList, setAdvertiserList] = React.useState([]);
  const [serviceList, setServiceList] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);

  const [advertiserSelected, setAdvertiserSelected] = React.useState(null);
  const [serviceSelected, setServiceSelected] = React.useState(null);
  const [statusSelected, setStatusSelected] = React.useState({
    status: "Active"
  });

  const [filter, setFilter] = React.useState({ status: "Active" });

  const classes = useStyles();
  const options = {
    filter: false,
    selectableRows: "none",
    pagination: false
  };
  const columns = [
    {
      name: "Cycle ID"
    },
    {
      name: "Cycle Number"
    },
    {
      name: "Name"
    },
    {
      name: "Service"
    },
    {
      name: "From"
    },
    {
      name: "To"
    },
    {
      name: "Budget"
    },
    {
      name: "Status",
      options: {
        searchable: false,
        filter: true,
        customFilterListOptions: { render: v => `Status: ${v}` },
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <th
            key={columnMeta.name}
            className="MuiTableCell-root MuiTableCell-head"
          >
            {columnMeta.name}
            <br />
            <br />
            &nbsp;
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === "Active")
            return (
              <div style={styles.status}>
                <OfflineBoltIcon style={styles.statusActive} /> Active
              </div>
            );

          if (value === "Complete")
            return (
              <div style={styles.status}>
                <CheckCircle style={styles.statusComplete} /> Complete
              </div>
            );

          if (value === "Stopped")
            return (
              <div style={styles.status}>
                <Stop style={styles.statusStopped} /> Stopped
              </div>
            );
          if (value === "Paused")
            return (
              <div style={styles.status}>
                <PauseCircleFilledIcon style={styles.statusPaused} /> Paused
              </div>
            );
          if (value === "Paid")
            return (
              <div style={styles.status}>
                <MonetizationOnIcon style={styles.statusPaid} /> Paid
              </div>
            );
          if (value === "Cancelled")
            return (
              <div style={styles.status}>
                <CancelIcon style={styles.statusCancelled} />
                Cancelled
              </div>
            );
          if (value === "Pending")
            return (
              <div style={styles.status}>
                <ErrorIcon style={styles.statusPending} />
                Pending
              </div>
            );
        }
      }
    }
  ];

  React.useEffect(() => {
    async function loadData() {
      setIsLoading(true);
      const response = await api.get("cycles/kmgselect", {
        params: {
          state: "Active"
        }
      });

      const data = response.data.map(item => [
        item.id,
        "1",
        item.advertiser_name,
        item.service,
        formatDate(item.live_date),
        formatDate(item.date_of_paid),
        formatNumber(item.budget),
        item.state
      ]);
      setData(data);
      setIsLoading(false);

      const advertisers = await api.get("advertisers?fields=advertiser_name");
      setAdvertiserList(advertisers.data);

      const services = await api.get("services?type=kgm");
      setServiceList(services.data);
    }
    loadData();
  }, []);

  const buildQueryFilter = (advertiser, service, status) => {
    let query = {};

    if (advertiser && advertiser.advertiser_name)
      query.advertiser = advertiser.advertiser_name;

    if (service && service.name) query.service = service.name;

    if (status && status.status) query.state = status.status;
    console.log("the query: ", query);
    if (query) {
      return query;
    }
    return {};
  };

  const fetchData = filter => {
    setIsLoading(true);
    api
      .get("cycles/kmgselect", {
        params: filter
      })
      .then(response => {
        const data = response.data.map(item => [
          item.id,
          "1",
          item.advertiser_name,
          item.service,
          formatDate(item.live_date),
          formatDate(item.date_of_paid),
          formatNumber(item.budget),
          item.state
        ]);
        setData(data);
        setIsLoading(false);
      });
  };

  const handleChangeAdvertiser = (event, value) => {
    setAdvertiserSelected(value);
    const filter = buildQueryFilter(value, serviceSelected, statusSelected);
    fetchData(filter);
  };
  const handleChangeService = (event, value) => {
    setServiceSelected(value);
    const filter = buildQueryFilter(advertiserSelected, value, statusSelected);
    fetchData(filter);
  };
  const handleChangeStatus = (event, value) => {
    console.log("status value: => ", value);
    setStatusSelected(value);
    const filter = buildQueryFilter(advertiserSelected, serviceSelected, value);
    fetchData(filter);
  };
  return (
    <GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card className={classes.card}>
            <CardBody>
              <InputLabel className={classes.label}>Filters</InputLabel>
              <GridContainer>
                <GridItem xs={12} md={12} lg={4}>
                  <Autocomplete
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Advertiser"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    id="advertiser"
                    value={advertiserSelected}
                    options={advertiserList}
                    getOptionLabel={option => option.advertiser_name}
                    onChange={handleChangeAdvertiser}
                  />
                </GridItem>
                <GridItem xs={12} md={6} lg={4}>
                  <Autocomplete
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Service"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    id="service"
                    value={serviceSelected}
                    options={serviceList}
                    getOptionLabel={option => option.name}
                    onChange={handleChangeService}
                  />
                </GridItem>
                <GridItem xs={12} md={6} lg={4}>
                  <Autocomplete
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Status"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    id="status"
                    value={statusSelected}
                    options={[
                      { status: "Pending" },
                      { status: "Paid" },
                      { status: "Active" },
                      { status: "Paused" },
                      { status: "Complete" },
                      { status: "Cancelled" },
                      { status: "Stopped" }
                    ]}
                    getOptionLabel={option => option.status}
                    onChange={handleChangeStatus}
                    renderOption={({ status: value }) => {
                      // console.log("value: ", value);
                      if (value === "Active")
                        return (
                          <div style={styles.status}>
                            <OfflineBoltIcon style={styles.statusActive} />{" "}
                            Active
                          </div>
                        );

                      if (value === "Complete")
                        return (
                          <div style={styles.status}>
                            <CheckCircle style={styles.statusComplete} />{" "}
                            Complete
                          </div>
                        );

                      if (value === "Stopped")
                        return (
                          <div style={styles.status}>
                            <Stop style={styles.statusStopped} /> Stopped
                          </div>
                        );
                      if (value === "Paused")
                        return (
                          <div style={styles.status}>
                            <PauseCircleFilledIcon
                              style={styles.statusPaused}
                            />{" "}
                            Paused
                          </div>
                        );
                      if (value === "Paid")
                        return (
                          <div style={styles.status}>
                            <MonetizationOnIcon style={styles.statusPaid} />{" "}
                            Paid
                          </div>
                        );
                      if (value === "Cancelled")
                        return (
                          <div style={styles.status}>
                            <CancelIcon style={styles.statusCancelled} />
                            Cancelled
                          </div>
                        );
                      if (value === "Pending")
                        return (
                          <div style={styles.status}>
                            <ErrorIcon style={styles.statusPending} />
                            Pending
                          </div>
                        );
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {/* Table view  */}
      <GridContainer>
        <GridItem xs={12}>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                zIndex: "999"
              }}
            >
              <CircularProgress />
            </div>
          )}
          <MUIDataTable columns={columns} data={data} options={options} />
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
}
