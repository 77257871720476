import React from "react";

import { toastr } from "react-redux-toastr";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";

// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import {
  blackColor,
  hexToRgb,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

// form validations
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  ERROR_MESSAGE_REQUIRED,
  ERROR_MESSAGE_NUMBER,
  ERROR_MESSAGE_NUMBER_MIN
} from "variables/messages";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedStyles from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
const useStyles = makeStyles(styles);
const useExtendedStyles = makeStyles(extendedStyles);
const adminUsersStyles = makeStyles({
  MarginTop: {
    marginTop: "10px"
  },
  labelHorizontal: {
    color: grayColor[3] + " !important",
    cursor: "pointer",
    display: "inline-flex",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    // paddingTop: "29px",
    paddingBottom: "7px",
    marginRight: "0",
    "@media (min-width: 992px)": {
      float: "left"
    }
  }
});

const toastrOptions = {
  closeButton: false,
  timeOut: 4000,
  progressBar: true
};

export default function AdminUsersAddPage(props) {
  const { handleClose } = props;

  const classes = useStyles();
  const adminUsersClasses = adminUsersStyles();
  const extendedClasses = useExtendedStyles();

  const [active, setActive] = React.useState(true);
  const [selectedValue, setSelectedValue] = React.useState("c");
  const [multipleSelect, setMultipleSelect] = React.useState([]);

  const AdminUsersSchema = yup.object().shape({
    first_name: yup.string().required(ERROR_MESSAGE_REQUIRED),
    last_name: yup.string().required(ERROR_MESSAGE_REQUIRED),
    email: yup
      .string()
      .email()
      .required(ERROR_MESSAGE_REQUIRED),
    password: yup.string().required(ERROR_MESSAGE_REQUIRED),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
  });

  const { register, handleSubmit, errors } = useForm({
    validationSchema: AdminUsersSchema,
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirm_password: "",
      active: true,
      mngm_fee: "0.00",
      lead_fee: "0.00"
    },
    mode: "onBlur"
  });

  const onSubmit = (data, e) => {
    console.log(data);
    e.preventDefault();
    toastr.success(
      "Admin's User",
      "The admin's user has been successfully added!",
      toastrOptions
    );
    handleClose();
  };

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const handleMultiple = event => {
    setMultipleSelect(event.target.value);
  };

  console.log(errors);
  return (
    <div>
      {props.open === true && (
        <div>
          <Dialog
            open
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="md"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent>
                <DialogTitle>Create an Admin's User</DialogTitle>
                <DialogContent>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          variant: "filled"
                        }}
                        labelText="First Name"
                        inputRef={register}
                        error={!!errors.first_name}
                        helperText={
                          errors.first_name ? errors.first_name.message : ""
                        }
                        id="first_name"
                        name="first_name"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          variant: "filled"
                        }}
                        labelText="Last Name"
                        inputRef={register}
                        error={!!errors.last_name}
                        helperText={
                          errors.last_name ? errors.last_name.message : ""
                        }
                        id="last_name"
                        name="last_name"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          variant: "filled"
                        }}
                        labelText="E-mail"
                        inputRef={register}
                        error={!!errors.email}
                        helperText={errors.email ? errors.email.message : ""}
                        id="email"
                        name="email"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          variant: "filled"
                        }}
                        inputProps={{
                          type: "password"
                        }}
                        labelText="Password"
                        inputRef={register}
                        error={!!errors.password}
                        helperText={
                          errors.password ? errors.password.message : ""
                        }
                        id="password"
                        name="password"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          variant: "filled"
                        }}
                        inputProps={{
                          type: "password"
                        }}
                        labelText="Confirm Password"
                        inputRef={register}
                        error={!!errors.confirm_password}
                        helperText={
                          errors.confirm_password
                            ? errors.confirm_password.message
                            : ""
                        }
                        id="confirm_password"
                        name="confirm_password"
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                      <FormLabel className={adminUsersClasses.labelHorizontal}>
                        Admin profile image
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <ImageUpload
                        avatar
                        addButtonProps={{
                          color: "rose",
                          round: true
                        }}
                        changeButtonProps={{
                          color: "rose",
                          round: true
                        }}
                        removeButtonProps={{
                          color: "danger",
                          round: true
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={active}
                            onChange={event => setActive(event.target.checked)}
                            // classes={{
                            //   switchBase: classes.switchBase,
                            //   checked: classes.switchChecked,
                            //   thumb: classes.switchIcon,
                            //   track: classes.switchBar
                            // }}
                            inputRef={register}
                            id="active"
                            name="active"
                          />
                        }
                        // classes={{
                        //   label: classes.label
                        // }}
                        labelPlacement="start"
                        label="This account is"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12}>
                      <FormLabel
                        className={
                          adminUsersClasses.labelHorizontal +
                          " " +
                          classes.labelHorizontalRadioCheckbox
                        }
                      >
                        Role
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={10}>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedValue === "a"}
                              onChange={handleChange}
                              value="a"
                              name="radio button demo"
                              aria-label="A"
                              icon={
                                <FiberManualRecord
                                  className={classes.radioUnchecked}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classes.radioChecked}
                                />
                              }
                              classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Super Admin"
                        />
                      </div>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedValue === "b"}
                              onChange={handleChange}
                              value="b"
                              name="radio button demo"
                              aria-label="B"
                              icon={
                                <FiberManualRecord
                                  className={classes.radioUnchecked}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classes.radioChecked}
                                />
                              }
                              classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Admin"
                        />
                      </div>
                      <div
                        className={
                          classes.checkboxAndRadio +
                          " " +
                          classes.checkboxAndRadioHorizontal
                        }
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              checked={selectedValue === "c"}
                              onChange={handleChange}
                              value="c"
                              name="radio button demo"
                              aria-label="C"
                              icon={
                                <FiberManualRecord
                                  className={classes.radioUnchecked}
                                />
                              }
                              checkedIcon={
                                <FiberManualRecord
                                  className={classes.radioChecked}
                                />
                              }
                              classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                              }}
                            />
                          }
                          classes={{
                            label: classes.label,
                            root: classes.labelRoot
                          }}
                          label="Consultant"
                        />
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={5} lg={5}>
                      <FormControl
                        fullWidth
                        className={extendedClasses.selectFormControl}
                      >
                        <InputLabel
                          htmlFor="multiple-select"
                          className={extendedClasses.selectLabel}
                        >
                          Assigned advertisers
                        </InputLabel>
                        <Select
                          multiple
                          value={multipleSelect}
                          onChange={handleMultiple}
                          MenuProps={{ className: extendedClasses.selectMenu }}
                          classes={{ select: extendedClasses.select }}
                          inputProps={{
                            name: "multipleSelect",
                            id: "multiple-select"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: extendedClasses.selectMenuItem
                            }}
                          >
                            Choose City
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="2"
                          >
                            Paris
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="3"
                          >
                            Bucharest
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="4"
                          >
                            Rome
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="5"
                          >
                            New York
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="6"
                          >
                            Miami
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="7"
                          >
                            Piatra Neamt
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="8"
                          >
                            Paris
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="9"
                          >
                            Bucharest
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="10"
                          >
                            Rome
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="11"
                          >
                            New York
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="12"
                          >
                            Miami
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="13"
                          >
                            Piatra Neamt
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="14"
                          >
                            Paris
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="15"
                          >
                            Bucharest
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="16"
                          >
                            Rome
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="17"
                          >
                            New York
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="18"
                          >
                            Miami
                          </MenuItem>
                          <MenuItem
                            classes={{
                              root: extendedClasses.selectMenuItem,
                              selected:
                                extendedClasses.selectMenuItemSelectedMultiple
                            }}
                            value="19"
                          >
                            Piatra Neamt
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </DialogContent>
                <DialogContent>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <DialogActions
                        classes={{ root: classes.marginDialogActions }}
                      >
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit" variant="contained" color="rose">
                          Create a Admin's User
                        </Button>
                      </DialogActions>
                    </GridItem>
                  </GridContainer>
                </DialogContent>
              </DialogContent>
            </form>
          </Dialog>
        </div>
      )}
    </div>
  );
}
