import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
// import { Container } from './styles';

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

// form validations
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  ERROR_MESSAGE_REQUIRED,
  ERROR_MESSAGE_NUMBER,
  ERROR_MESSAGE_NUMBER_MIN
} from "variables/messages";

import api from "services/api";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

const useStyles = makeStyles(styles);

export default function ServicesEditPage(props) {
  const classes = useStyles();
  const [renew, setRenew] = React.useState(false);
  const [kgmSelect, setKgmSelect] = React.useState(false);

  const ServicesSchema = yup.object().shape({
    service_name: yup.string().required(ERROR_MESSAGE_REQUIRED),
    setup_fee: yup
      .number()
      .min(0, ERROR_MESSAGE_NUMBER_MIN)
      .required()
      .typeError(ERROR_MESSAGE_NUMBER),
    mngm_fee: yup
      .number()
      .min(0, ERROR_MESSAGE_NUMBER_MIN)
      .required()
      .typeError(ERROR_MESSAGE_NUMBER),
    lead_fee: yup
      .number()
      .min(0, ERROR_MESSAGE_NUMBER_MIN)
      .required()
      .typeError(ERROR_MESSAGE_NUMBER)
  });

  const { register, handleSubmit, errors, reset } = useForm({
    validationSchema: ServicesSchema,
    defaultValues: {
      service_name: "...",
      setup_fee: "0.00",
      mngm_fee: "0.00",
      lead_fee: "0.00"
    },
    mode: "onBlur"
  });

  const { open, serviceID, handleClose } = props;

  React.useEffect(() => {
    if (open)
      api.get(`services/${serviceID}`).then(res => {
        console.log(res.data);
        setRenew(res.data.renew ? true : false);
        setKgmSelect(res.data.kgm ? true : false);
        reset({
          service_name: res.data.name,
          setup_fee: res.data.setup_fee,
          mngm_fee: res.data.mngm_fee,
          lead_fee: res.data.lead_fee
        });
      });
  }, [open]);

  const onSubmit = (data, e) => {
    console.log(data);
    e.preventDefault();
    handleClose();
  };

  console.log(errors);
  return (
    <div>
      {open === true && (
        <div>
          <Dialog
            open
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="md"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogContent>
                <DialogTitle>Edit Service</DialogTitle>
                <DialogContent>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          variant: "filled"
                        }}
                        labelText="Service Name"
                        inputRef={register}
                        error={!!errors.service_name}
                        helperText={
                          errors.service_name ? errors.service_name.message : ""
                        }
                        id="service_name"
                        name="service_name"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          variant: "filled"
                        }}
                        labelText="Acc. Setup Fee"
                        inputRef={register}
                        error={!!errors.setup_fee}
                        helperText={
                          errors.setup_fee ? errors.setup_fee.message : ""
                        }
                        id="setup_fee"
                        name="setup_fee"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          variant: "filled"
                        }}
                        labelText="Tracking & Tech Fee"
                        inputRef={register}
                        error={!!errors.mngm_fee}
                        helperText={
                          errors.mngm_fee ? errors.mngm_fee.message : ""
                        }
                        id="mngm_fee"
                        name="mngm_fee"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        formControlProps={{
                          fullWidth: true,
                          variant: "filled"
                        }}
                        labelText="Lead Fee"
                        inputRef={register}
                        error={!!errors.lead_fee}
                        helperText={
                          errors.lead_fee ? errors.lead_fee.message : ""
                        }
                        id="lead_fee"
                        name="lead_fee"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={kgmSelect}
                            tabIndex={-1}
                            onClick={e => setKgmSelect(e.target.checked)}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                            inputRef={register}
                            id="kgm_select"
                            name="kgm_select"
                          />
                        }
                        classes={{
                          label: classes.label,
                          root: classes.labelRoot
                        }}
                        label="KMG Select"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={renew}
                            onChange={event => setRenew(event.target.checked)}
                            // classes={{
                            //   switchBase: classes.switchBase,
                            //   checked: classes.switchChecked,
                            //   thumb: classes.switchIcon,
                            //   track: classes.switchBar
                            // }}
                            inputRef={register}
                            id="renew"
                            name="renew"
                          />
                        }
                        // classes={{
                        //   label: classes.label
                        // }}
                        label="Auto renew"
                      />
                    </GridItem>
                  </GridContainer>
                </DialogContent>
                <DialogContent>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <DialogActions
                        classes={{ root: classes.marginDialogActions }}
                      >
                        <Button onClick={props.handleClose}>Cancel</Button>
                        <Button
                          type="submit"
                          // className={classes.marginDialogActions}
                          variant="contained"
                          color="rose"
                        >
                          Save Your Changes
                        </Button>
                      </DialogActions>
                    </GridItem>
                  </GridContainer>
                </DialogContent>
              </DialogContent>
            </form>
          </Dialog>
        </div>
      )}
    </div>
  );
}
