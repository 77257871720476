import React from "react";

import {
  Grid,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";

function Step2() {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          name="bu_first_name"
          label="First Name"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="bu_last_name"
          label="Last Name"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="bu_address_1"
          label="Address 1"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="bu_address_2"
          label="Address 2"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField name="bu_city" label="City" variant="outlined" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="bu_country"
          label="Country"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="bu_state_province"
          label="State/Province"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="bu_postal_code"
          label="Postal Code"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="bu_phone_1"
          label="Phone 1"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField name="bu_fax" label="Fax" variant="outlined" fullWidth />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="bu_email"
          label="E-mail"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="bu_email_confirmation"
          label="Confirm E-mail"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="bu_password"
          label="Password"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="bu_confirm_password"
          label="Confirm Password"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                onChange={() => {}}
                name="bu_user_primary"
              />
            }
            label="Make this Business User as Primary"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={6}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                onChange={() => {}}
                name="bu_send_login"
              />
            }
            label="Send Login Info via E-mail"
          />
        </FormGroup>
      </Grid>
      <Grid item xs={6}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={true}
                onChange={() => {}}
                name="bu_reset_password"
              />
            }
            label="Reset Password"
          />
        </FormGroup>
      </Grid>
    </Grid>
  );
}

export default Step2;
