import React, { useEffect, useState } from "react";

import {
  Button,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Chip
} from "@material-ui/core";

import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import InfoIcon from "@material-ui/icons/Info";
import CircularProgress from "@material-ui/core/CircularProgress";
import DescriptionIcon from "@material-ui/icons/Description";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import EmailIcon from "@material-ui/icons/Email";

import Autocomplete from "@material-ui/lab/Autocomplete";

import { DateRangePicker, DateRangeDelimiter } from "@material-ui/pickers";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import enGBLocale from "date-fns/locale/en-GB";
import { format, compareAsc } from "date-fns";

import api from "services/api";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";

import { withStyles } from "@material-ui/core/styles";

import { formatNumber, formatDate } from "../../../utils/formats";

import fileDownload from "js-file-download";

import moment from "moment";

const staticDateAdapter = new DateFnsAdapter({ locale: enGBLocale });

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  //   root: {
  //     "&:focus": {
  //       backgroundColor: theme.palette.primary.main,
  //       "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
  //         color: theme.palette.common.white
  //       }
  //     }
  //   }
}))(MenuItem);

const myTheme = createMuiTheme({
  overrides: {
    MUIDataTable: {
      paper: {
        height: "inherit",
        marginTop: "10px"
      },
      responsiveScroll: {
        maxHeight: "none",
        height: "calc(100% - 128px)"
      }
    },
    MuiDataTableBodyCell: {
      "12-0": {
        display: "flex",
        alignItems: "center"
      }
    }
  }
});

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: "34px"
  },
  paperContainerBody: {
    height: "338px",
    marginTop: "8px",
    padding: "34px",
    backgroundColor: "#a8afb4",
    "& h4": {
      marginTop: "40px"
    }
  },
  paperFormContainer: {
    padding: "32px 25px",
    width: "95%"
  },
  dataRangeContainer: {
    "& .MuiPickersDateRangePickerInput-root": { alignItems: "flex-start" },
    " & p": {
      alignSelf: "center"
    }
  },
  exportContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "20px"
  }
}));

const columns = [
  {
    name: "id",
    label: "Invoice Number"
  },
  {
    name: "service",
    label: "Service"
  },
  {
    name: "live_date_format",
    label: "Issue Date"
  },
  {
    name: "invoice_amount_currency",
    label: "Invoice Amount"
  },
  {
    name: "invoice_amount_with_vat_currency",
    label: "Invoice Amount (inc. VAT)"
  },
  {
    name: "status",
    label: "Payment Status",
    options: {
      customBodyRender: value => {
        console.log(value);
        if (value === 2)
          return <Chip size="small" label="Paid" color="primary" />;

        return <Chip size="small" label="Pending" color="secondary" />;
      }
    }
  },
  {
    name: "date_of_paid_format",
    label: "Payment Date"
  }
];

const options = {
  filter: false,
  filterType: "multisselect",
  responsive: "scrollFullHeight",
  selectableRows: "none",
  rowsPerPage: 100,
  rowsPerPageOptions: [50, 100, 200, 500]
  // onRowClick: handleRowClick
};

export function StatementOfAccounsReport() {
  const [advertiserList, setAdvertiserList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const FilterSchema = yup.object().shape({
    advertiser: yup.object().required(),
    services: yup.array().required(),
    dateRange: yup
      .array()
      .of(yup.date())
      .min(2)
      .required()
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues
  } = useForm({
    resolver: yupResolver(FilterSchema)
    // defaultValues
  });

  const classes = useStyles();

  useEffect(() => {
    async function loadData() {
      const advertisers = await api.get(
        "advertisers?fields=id,advertiser_name"
      );
      console.log(advertisers.data);
      setAdvertiserList(advertisers.data);
    }
    loadData();
  }, []);

  useEffect(() => {
    async function loadData() {
      const services = await api.get("services");
      console.log(services.data);
      setServiceList(services.data);
    }
    loadData();
  }, []);

  const onSubmit = data => {
    /*
    JSON Stringify was creating a issue with the date format.
    The code below is a workaround to fix the issue.
    */
    const [startDate, endDate] = data.dateRange;
    const dateRange = [
      format(startDate, "yyyy-MM-dd"),
      format(endDate, "yyyy-MM-dd")
    ];

    data.dateRange = dateRange;
    console.log(JSON.stringify(data));
    setIsLoading(true);

    api.post("/reports/statement-of-accounts", data).then(response => {
      const { data } = response;

      const dataTransformed = data.map(record => {
        const obj = {
          ...record,
          invoice_amount:
            Number(record.budget) +
            Number(record.mnmg_fee) +
            Number(record.setup_fee)
        };
        obj.invoice_amount_with_vat = Number(obj.advertiser_vat)
          ? Number(obj.invoice_amount) * 1.2
          : Number(obj.invoice_amount);

        obj.invoice_amount_currency = formatNumber(obj.invoice_amount);
        obj.invoice_amount_with_vat_currency = formatNumber(
          obj.invoice_amount_with_vat
        );

        obj.live_date_format = formatDate(obj.live_date);
        obj.date_of_paid_format = formatDate(obj.date_of_paid);

        return obj;
      });

      setData(dataTransformed);
      setIsLoading(false);

      console.log(dataTransformed);
    });
    // setTimeout(() => {
    //   setData([
    //     {
    //       invoice_number: "104245",
    //       service: "Smartchat",
    //       issue_date: "2021-01-01",
    //       invoice_amount: 250.0,
    //       invoice_amount_with_vat: 300.0,
    //       payment_date: "2021-01-20"
    //     }
    //   ]);
    //   setIsLoading(false);
    // }, 2000);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportPDF = event => {
    console.log("PDF");
  };

  const handleExportExcel = event => {
    console.log("Excel");

    console.log(data);

    const [dateRange, advertiser] = getValues([
      "dateRange",
      "advertiser.advertiser_name"
    ]);

    const postData = { advertiser, dateRange, data };

    const filename = `${advertiser} ${moment(dateRange[0]).format(
      "DD-MM-YYYY"
    )}_${moment(dateRange[1]).format("DD-MM-YYYY")}.xlsx`;

    console.log(postData);

    api
      .post("/reports/statement-of-accounts/export/excel", postData, {
        responseType: "blob"
      })
      .then(response => {
        fileDownload(response.data, filename);
      });
  };

  const handleExportEmail = event => {
    console.log("Email");
  };

  return (
    <>
      <Paper variant="outlined" className={classes.paperContainer}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container justify="center" alignItems="center">
            <Paper variant="outlined" className={classes.paperFormContainer}>
              <Grid container justify="center" spacing={3}>
                <Grid item md={3}>
                  <Controller
                    name="advertiser"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Select a Advertiser"
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={!!errors.advertiser}
                            helperText={
                              errors.advertiser ? errors.advertiser.message : ""
                            }
                          />
                        )}
                        id="advertiserList"
                        options={advertiserList}
                        getOptionLabel={option => option.advertiser_name}
                        onChange={(e, data) => onChange(data)}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={3}>
                  <Controller
                    name="services"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Select the services"
                            variant="outlined"
                            size="small"
                            fullWidth
                            error={!!errors.services}
                            helperText={
                              errors.services ? errors.services.message : ""
                            }
                          />
                        )}
                        multiple
                        options={serviceList}
                        getOptionLabel={option => option.name}
                        onChange={(e, data) => onChange(data)}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={4} className={classes.dataRangeContainer}>
                  <Controller
                    name="dateRange"
                    control={control}
                    defaultValue={[null, null]}
                    render={({ field }) => (
                      <DateRangePicker
                        startText="Start date"
                        endText="End date"
                        {...field}
                        renderInput={(startProps, endProps) => (
                          <React.Fragment>
                            <TextField
                              size="small"
                              {...startProps}
                              error={!!errors.dateRange?.[0]}
                              helperText={
                                errors.dateRange?.[0]
                                  ? "start date is a required field"
                                  : ""
                              }
                            />
                            <DateRangeDelimiter> to </DateRangeDelimiter>
                            <TextField
                              size="small"
                              {...endProps}
                              error={!!errors.dateRange}
                              helperText={
                                errors.dateRange?.[1]
                                  ? "end date is a required field"
                                  : ""
                              }
                            />
                            <Tooltip title="Date of paid">
                              <IconButton aria-label="info">
                                <InfoIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </React.Fragment>
                        )}
                        dateAdapter={staticDateAdapter}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    // className={classes.button}
                    startIcon={<Icon>search</Icon>}
                    size="large"
                    type="submit"
                  >
                    Filter
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </form>
      </Paper>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "400px",
            zIndex: "999"
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!isLoading && data.length === 0 && (
        <Paper className={classes.paperContainerBody}>
          <ListAltOutlinedIcon fontSize="large" />
          <Typography variant="h3">Statement of Accounts</Typography>

          <Typography variant="h4">
            To get started, simply fill out the options on the top and click
            filter
          </Typography>
        </Paper>
      )}

      {data.length > 0 && !isLoading && (
        <>
          <MuiThemeProvider theme={myTheme}>
            <MUIDataTable
              title={""}
              data={data}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
          <div className={classes.exportContainer}>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="contained"
              color="primary"
              size="large"
              onClick={handleClick}
            >
              Export
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <StyledMenuItem onClick={handleExportPDF}>
                <ListItemIcon>
                  <PictureAsPdfIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Export to pdf" />
              </StyledMenuItem>
              <StyledMenuItem onClick={handleExportExcel}>
                <ListItemIcon>
                  <DescriptionIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Export to excel" />
              </StyledMenuItem>
              <StyledMenuItem onClick={handleExportEmail}>
                <ListItemIcon>
                  <EmailIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Email report" />
              </StyledMenuItem>
            </StyledMenu>
          </div>
        </>
      )}
    </>
  );
}
