import React, { Component } from "react";

import moment from "moment";

// @material-ui/core components
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CheckCircle from "@material-ui/icons/CheckCircle";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import Stop from "@material-ui/icons/RemoveCircle";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import CircularProgress from "@material-ui/core/CircularProgress";

import api from "services/api";

const columns = [
  { id: "cycleid", label: "Name", minWidth: 40 },
  { id: "cyclenumber", label: "Cycle Number", minWidth: 100 },
  {
    id: "name",
    label: "Name",
    minWidth: 170,
    align: "left",
    format: value => value.toLocaleString()
  },
  {
    id: "from",
    label: "From",
    minWidth: 170,
    align: "right",
    format: value => value.toLocaleString()
  },
  {
    id: "to",
    label: "To",
    minWidth: 170,
    align: "right",
    format: value => value.toFixed(2)
  },
  {
    id: "budget",
    label: "Budget"
  },
  {
    id: "clientcost",
    label: "Client Cost"
  },
  {
    id: "lead",
    label: "Cost"
  },
  {
    id: "perspent",
    label: "% Spent"
  },
  {
    id: "spendrate",
    label: "Spend Rate"
  },
  {
    id: "expcycledaysleft",
    label: "Exp. Cycle Days Left"
  },
  {
    id: "projclicost",
    label: "Projected Client Cost"
  },
  {
    id: "status",
    label: "Status"
  }
];

const totalColumns = [
  { id: "total", label: "Total", minWidth: 40 },
  { id: "cyclenumber", label: "", minWidth: 100 },
  { id: "name", label: "Name", minWidth: 170, align: "left" },
  { id: "from", label: "", minWidth: 170, align: "left" },
  { id: "to", label: "", minWidth: 170, align: "left" },
  { id: "totalbudget", label: "£ 441321.00", minWidth: 170, align: "left" },
  {
    id: "toalclientcostbudget",
    label: "£ 242747.7",
    minWidth: 170,
    align: "left"
  },
  {
    id: "toalclientcost",
    label: "£ 164475.68",
    minWidth: 170,
    align: "left"
  },
  { id: "perspent", label: "", minWidth: 170, align: "left" },
  { id: "spendrate", label: "", minWidth: 170, align: "left" },
  { id: "expcycledaysleft", label: "", minWidth: 170, align: "left" },
  { id: "projclicost", label: "", minWidth: 170, align: "left" },
  { id: "status", label: "", minWidth: 170, align: "left" }
];

// const useStyles = makeStyles({
const classes = {
  root: {
    width: "100%"
  },
  tableWrapper: {
    maxHeight: 440,
    overflow: "auto"
  }
};
// });

const styles = {
  statusActive: {
    color: "green"
  },
  statusComplete: {
    color: "#1565C0"
  },
  statusStopped: {
    color: "#c62828"
  },
  statusPaused: {
    color: "#c62828"
  },
  statusPaid: {
    color: "#9E9D24"
  },
  statusCancelled: {
    color: "#c62828"
  },
  statusPending: {
    color: "#EF6C00"
  },
  checkboxAllCycles: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "10px"
  }
};

class CyclesRaw extends Component {
  state = {
    advertiserList: [],
    page: 0,
    rowsPerPage: 10,
    data: [],
    cycleStatusSelected: { status: "Active" },
    advertiserSelected: null,
    isShowAllCycles: false,
    isLoading: false
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    api.get("cycles/ppc").then(result => {
      // this.setState({ isLoading: true });

      const data = result.data.map(item => ({
        cycleid: item.cycle_id,
        cyclenumber: "1",
        name: item.advertiser_name,
        from: moment(item.date_range_from).format("MMMM D, YYYY"),
        to: moment(item.date_range_to).format("MMMM D, YYYY"),
        budget: this.numberFormat(item.budget),
        clientcost: this.numberFormat(item.spend),
        lead: this.numberFormat(item.real_spend),
        perspent: "",
        spendrate: "",
        expcycledaysleft: "",
        projclicost: "",
        status: item.state
      }));
      console.log(data);
      this.setState({
        isLoading: false,
        data
      });
    });

    // http://localhost:3000/advertisers?fields=advertiser_name
    api.get("advertisers?fields=advertiser_name").then(result => {
      // const data = result.data.map(item => item.advertiser_name);
      const data = result.data;
      console.log(data);
      this.setState({ advertiserList: data });
    });
  }

  buildQueryFilter = (advertiserSelected, cycleStatusSelected) => {
    let query = [];
    console.log("advertiserSelected: ", advertiserSelected);
    console.log("cycleStatusSelected: ", cycleStatusSelected);
    if (advertiserSelected) query.push(`name=${advertiserSelected}`);

    if (cycleStatusSelected) query.push(`state=${cycleStatusSelected}`);
    else query.push(`state=Active`);

    return query.join("&");
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = event => {
    // setRowsPerPage(+event.target.value);
    // setPage(0);
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };

  handleChangeCycleState = (event, value) => {
    if (value) {
      const { advertiserSelected } = this.state;
      const cycleStatusSelected = value.status;
      console.log(value);

      const queryFilter = this.buildQueryFilter(
        advertiserSelected.advertiser_name,
        cycleStatusSelected
      );

      this.setState({ isLoading: true });
      console.log("queryFilter: ", queryFilter);
      api.get(`cycles/ppc?${queryFilter}`).then(result => {
        const data = result.data.map(item => ({
          cycleid: item.cycle_id,
          cyclenumber: "1",
          name: item.advertiser_name,
          from: item.date_range_from,
          to: item.date_range_to,
          budget: this.numberFormat(item.budget),
          clientcost: this.numberFormat(item.spend),
          lead: this.numberFormat(item.real_spend),
          perspent: "",
          spendrate: "",
          expcycledaysleft: "",
          projclicost: "",
          status: item.state
        }));
        this.setState({
          isLoading: false,
          data,
          cycleStatusSelected: value
        });
        // console.log(res.data);
      });
    }
  };

  handleChangeAdvertiser = (event, value) => {
    if (value) {
      const { cycleStatusSelected } = this.state;
      console.log(value.advertiser_name);
      const advertiserSelected = value.advertiser_name;

      const queryFilter = this.buildQueryFilter(
        advertiserSelected,
        cycleStatusSelected.status
      );

      this.setState({ isLoading: true });
      console.log(queryFilter);
      api.get(`cycles/ppc?${queryFilter}`).then(result => {
        const data = result.data.map(item => ({
          cycleid: item.cycle_id,
          cyclenumber: "1",
          name: item.advertiser_name,
          from: item.date_range_from,
          to: item.date_range_to,
          budget: this.numberFormat(item.budget),
          clientcost: this.numberFormat(item.spend),
          lead: this.numberFormat(item.real_spend),
          perspent: "",
          spendrate: "",
          expcycledaysleft: "",
          projclicost: "",
          status: item.state
        }));
        this.setState({
          isLoading: false,
          data,
          advertiserSelected: value
        });
        // console.log(res.data);
      });
    }
  };

  handleChangeShowAllCycles = event => {
    const { checked } = event.target;
    console.log("checked:", checked);

    this.setState({ cycleStatusSelected: null, isShowAllCycles: checked });
  };

  numberFormat(number) {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP"
    }).format(parseFloat(number));
  }

  render() {
    const states = {
      state: null
    };
    // const classes = useStyles();

    const { page, rowsPerPage, data, cycleStatusSelected } = this.state;
    // console.log(rows);
    return (
      <div>
        {this.state.isLoading && (
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <CircularProgress />
          </div>
        )}
        <div style={styles.checkboxAllCycles}>
          <FormControl component="fieldset">
            <FormLabel component="legend"></FormLabel>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                checked={this.state.isShowAllCycles}
                control={<Checkbox color="primary" />}
                label="Show all cycles"
                labelPlacement="end"
                onChange={this.handleChangeShowAllCycles}
              />
            </FormGroup>
          </FormControl>
        </div>
        <Autocomplete
          value={cycleStatusSelected}
          id="combo-box-demo"
          options={[
            { status: "Pending" },
            { status: "Paid" },
            { status: "Active" },
            { status: "Paused" },
            { status: "Complete" },
            { status: "Cancelled" },
            { status: "Stopped" }
            // { status: "Failed" }
          ]}
          getOptionLabel={option => option.status}
          renderOption={({ status: value }) => {
            // console.log("value: ", value);
            if (value === "Active")
              return (
                <div style={styles.status}>
                  <OfflineBoltIcon style={styles.statusActive} /> Active
                </div>
              );

            if (value === "Complete")
              return (
                <div style={styles.status}>
                  <CheckCircle style={styles.statusComplete} /> Complete
                </div>
              );

            if (value === "Stopped")
              return (
                <div style={styles.status}>
                  <Stop style={styles.statusStopped} /> Stopped
                </div>
              );
            if (value === "Paused")
              return (
                <div style={styles.status}>
                  <PauseCircleFilledIcon style={styles.statusPaused} /> Paused
                </div>
              );
            if (value === "Paid")
              return (
                <div style={styles.status}>
                  <MonetizationOnIcon style={styles.statusPaid} /> Paid
                </div>
              );
            if (value === "Cancelled")
              return (
                <div style={styles.status}>
                  <CancelIcon style={styles.statusCancelled} />
                  Cancelled
                </div>
              );
            if (value === "Pending")
              return (
                <div style={styles.status}>
                  <ErrorIcon style={styles.statusPending} />
                  Pending
                </div>
              );
          }}
          // style={{ width: 600 }}
          renderInput={params => (
            <TextField
              {...params}
              label="Cycle Status"
              variant="outlined"
              // variant="filled"
              fullWidth
            />
            // value={this.setState({ state: })}
          )}
          onChange={this.handleChangeCycleState}
        />
        <br />
        <Autocomplete
          id="combo-box-demo"
          options={this.state.advertiserList}
          getOptionLabel={option => option.advertiser_name}
          // style={{ width: 600 }}
          renderInput={params => (
            <TextField
              {...params}
              label="Advertiser"
              variant="outlined"
              // variant="filled"
              fullWidth
            />
          )}
          onChange={this.handleChangeAdvertiser}
        />
        <br />
        <Paper>
          <div>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow>
                  {totalColumns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(row => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.cycleid}
                      >
                        {columns.map(column => {
                          // console.log("column:", column);
                          // console.log("row:", row);
                          const value = row[column.id];
                          if (column.id === "status") {
                            if (value === "Active")
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <OfflineBoltIcon
                                    style={styles.statusActive}
                                  />{" "}
                                  Active
                                </TableCell>
                              );

                            if (value === "Complete")
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <CheckCircle style={styles.statusComplete} />{" "}
                                  Complete
                                </TableCell>
                              );

                            if (value === "Stopped")
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <Stop style={styles.statusStopped} /> Stopped
                                </TableCell>
                              );
                            if (value === "Paused")
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <PauseCircleFilledIcon
                                    style={styles.statusPaused}
                                  />{" "}
                                  Paused
                                </TableCell>
                              );
                            if (value === "Paid")
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <MonetizationOnIcon
                                    style={styles.statusPaid}
                                  />{" "}
                                  Paid
                                </TableCell>
                              );
                            if (value === "Cancelled")
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <CancelIcon style={styles.statusCancelled} />
                                  Cancelled
                                </TableCell>
                              );
                            if (value === "Pending")
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  <ErrorIcon style={styles.statusPending} />
                                  Pending
                                </TableCell>
                              );
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100, { value: -1, label: "All" }]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  }
}

// export default withStyles(styles)(CyclesRaw);
export default CyclesRaw;
