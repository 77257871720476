import React from "react";

import { Grid, Typography, Chip, IconButton, Fab } from "@material-ui/core";

import ErrorIcon from "@material-ui/icons/Error";
import AddIcon from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";

import MUIDataTable from "mui-datatables";

import Wizard from "./WizardSteps/Wizard";

import api from "services/api";

import { makeStyles } from "@material-ui/core/styles";

import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles(theme => ({
  chip: {
    color: "#FFF"
  },
  buttonAdd: {
    marginLeft: 20
  },
  tableViewArea: {
    marginTop: 37
  }
}));

const MUIOptions = {
  filter: false,
  filterType: "multisselect",
  responsive: "scrollFullHeight",
  selectableRows: "none",
  download: false,
  print: false
};

function ClientManagement() {
  const classes = useStyles();

  const [data, setData] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [alert, setAlert] = React.useState(null);

  const handleHideModal = () => {
    setOpen(false);
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const successAlert = () => {
    console.log("successAlert");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        // confirmBtnCssClass={classes.button + " " + classes.success}
        showConfirm={false}
      >
        The advertiser has been succesfully created!
      </SweetAlert>
    );
    setTimeout(hideAlert, 4000);
  };

  const handleSave = data => {
    setOpen(false);
    successAlert();
  };

  const columns = [
    { name: "ID" },
    { name: "Advertiser Name" },
    { name: "Sales Representative" },
    { name: "E-mail" },
    { name: "Country" },
    {
      name: "Action",
      options: {
        print: false,
        filter: false,
        customBodyRender: function IconCell(value, tableMeta, updateValue) {
          return (
            <IconButton
              size="small"
              color="secondary"
              aria-label="edit"
              // className={classes.margin}
            >
              <Edit
                onClick={e => {
                  // setServiceID(tableMeta.rowData[0]);
                  // setOpenEdit(true);
                }}
              />
            </IconButton>
          );
        }
      }
    }
  ];

  React.useEffect(() => {
    try {
      api.get("advertisers").then(res => {
        const data = res.data.map(item => [
          item.id,
          item.advertiser_name,
          "",
          item.email,
          item.country
        ]);
        setData(data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleClickOpenWizard = () => {
    setOpen(true);
  };

  return (
    <Grid container>
      {alert}
      <Wizard
        open={open}
        handleClose={handleHideModal}
        handleSave={handleSave}
      />
      <Grid container item>
        <Grid container item xs={11} alignItems="center">
          <Typography variant="h5">Client Management</Typography>
          <Fab
            // variant="contained"
            color="secondary"
            className={classes.buttonAdd}
            size="small"
            onClick={handleClickOpenWizard}
          >
            <AddIcon />
          </Fab>
        </Grid>
        <Grid container item xs={1} justify="flex-end" alignItems="center">
          <Chip
            className={classes.chip}
            icon={<ErrorIcon />}
            color="secondary"
            label="2 Days Behind"
          />
        </Grid>
        <Grid className={classes.tableViewArea} xs={12}>
          <MUIDataTable options={MUIOptions} columns={columns} data={data} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ClientManagement;
