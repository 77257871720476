import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import CircularProgress from "@material-ui/core/CircularProgress";

import CheckCircle from "@material-ui/icons/CheckCircle";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import Stop from "@material-ui/icons/RemoveCircle";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import MUIDataTable from "mui-datatables";

import api from "services/api";

import { formatDate, formatNumber, getTotals } from "utils/formats";

import {
  hexToRgb,
  blackColor
} from "assets/jss/material-dashboard-pro-react.js";

const useStyles = makeStyles({
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    marginBottom: "8px",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex"
  },
  card: {
    marginTop: "0px",
    marginBottom: "8px"
  }
});

const styles = {
  status: {
    display: "flex",
    alignItems: "center"
  },
  statusActive: {
    color: "green"
  },
  statusComplete: {
    color: "#1565C0"
  },
  statusStopped: {
    color: "#c62828"
  },
  statusPaused: {
    color: "#c62828"
  },
  statusPaid: {
    color: "#9E9D24"
  },
  statusCancelled: {
    color: "#c62828"
  },
  statusPending: {
    color: "#EF6C00"
  }
};

export default function SmartchatPage() {
  const [data, setData] = React.useState([]);
  const [advertiserList, setAdvertiserList] = React.useState([]);

  const [advertiserSelected, setAdvertiserSelected] = React.useState(null);
  const [statusSelected, setStatusSelected] = React.useState({
    status: "Active"
  });

  const [isLoading, setIsLoading] = React.useState(false);
  const [totalBudget, setTotalBudget] = React.useState(0.0);
  const [totalSpend, setTotalSpend] = React.useState(0.0);
  // const [totalRawSpend, setTotalRawSpend] = React.useState(0.0);
  const classes = useStyles();
  const options = {
    filter: false,
    selectableRows: "none",
    pagination: false
  };

  const columns = [
    {
      name: "Cycle ID",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        }
      }
    },
    {
      name: "Cycle Number",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        }
      }
    },
    {
      name: "Name",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        }
      }
    },
    {
      name: "From",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        }
      }
    },
    {
      name: "To",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        }
      }
    },
    {
      name: "Budget",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              {formatNumber(totalBudget)}
            </th>
          );
        }
      }
    },
    {
      name: "Client Cost(£)",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              {formatNumber(totalSpend)}
            </th>
          );
        }
      }
    },
    {
      name: "Lead",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              ...
            </th>
          );
        }
      }
    },
    {
      name: "Cost(£)",
      options: {
        customHeadRender: (columnMeta, handleToggleColumn) => {
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              {formatNumber(0.0)}
            </th>
          );
        }
      }
    },
    {
      name: "Status",
      options: {
        searchable: false,
        filter: true,
        customFilterListOptions: { render: v => `Status: ${v}` },
        customHeadRender: (columnMeta, handleToggleColumn) => (
          <th
            key={columnMeta.name}
            className="MuiTableCell-root MuiTableCell-head"
          >
            {columnMeta.name}
            <br />
            <br />
            &nbsp;
          </th>
        ),
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === "Active")
            return (
              <div style={styles.status}>
                <OfflineBoltIcon style={styles.statusActive} /> Active
              </div>
            );

          if (value === "Complete")
            return (
              <div style={styles.status}>
                <CheckCircle style={styles.statusComplete} /> Complete
              </div>
            );

          if (value === "Stopped")
            return (
              <div style={styles.status}>
                <Stop style={styles.statusStopped} /> Stopped
              </div>
            );
          if (value === "Paused")
            return (
              <div style={styles.status}>
                <PauseCircleFilledIcon style={styles.statusPaused} /> Paused
              </div>
            );
          if (value === "Paid")
            return (
              <div style={styles.status}>
                <MonetizationOnIcon style={styles.statusPaid} /> Paid
              </div>
            );
          if (value === "Cancelled")
            return (
              <div style={styles.status}>
                <CancelIcon style={styles.statusCancelled} />
                Cancelled
              </div>
            );
          if (value === "Pending")
            return (
              <div style={styles.status}>
                <ErrorIcon style={styles.statusPending} />
                Pending
              </div>
            );
        }
      }
    }
  ];

  React.useEffect(() => {
    async function loadData() {
      const response = await api.get("/cycles/smartchat", {
        params: {
          state: "Active"
        }
      });

      const { totalBudget, totalSpend, totalRawSpend } = getTotals(
        response.data
      );

      const data = response.data.map(item => [
        item.id,
        "1",
        item.advertiser_name,
        formatDate(item.date_range_from),
        formatDate(item.date_range_to),
        formatNumber(item.budget),
        formatNumber(item.spend),
        item.lead,
        formatNumber(item.real_spend),
        item.state
      ]);

      setData(data);
      setTotalBudget(totalBudget);
      setTotalSpend(totalSpend);
      // setTotalRawSpend(totalRawSpend);

      const advertisers = await api.get("advertisers?fields=advertiser_name");
      setAdvertiserList(advertisers.data);
    }
    loadData();
  }, []);

  const buildQueryFilter = (advertiser, status) => {
    let query = {};

    if (advertiser && advertiser.advertiser_name)
      query.advertiser = advertiser.advertiser_name;

    if (status && status.status) query.state = status.status;

    if (query) {
      return query;
    }
    return {};
  };

  const fetchData = filter => {
    setIsLoading(true);
    api
      .get("cycles/smartchat", {
        params: filter
      })
      .then(response => {
        const { totalBudget, totalSpend, totalRawSpend } = getTotals(
          response.data
        );

        const data = response.data.map(item => [
          item.id,
          "1",
          item.advertiser_name,
          formatDate(item.date_range_from),
          formatDate(item.date_range_to),
          formatNumber(item.budget),
          formatNumber(item.spend),
          item.lead,
          formatNumber(item.real_spend),
          item.state
        ]);

        setData(data);
        setTotalBudget(totalBudget);
        setTotalSpend(totalSpend);
        // setTotalRawSpend(totalRawSpend);
        setIsLoading(false);
      });
  };

  const handleChangeAdvertiser = (event, value) => {
    setAdvertiserSelected(value);
    const filter = buildQueryFilter(value, statusSelected);
    fetchData(filter);
  };

  const handleChangeStatus = (event, value) => {
    console.log("status value: => ", value);
    setStatusSelected(value);
    const filter = buildQueryFilter(advertiserSelected, value);
    fetchData(filter);
  };

  return (
    <GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card className={classes.card}>
            <CardBody>
              <InputLabel className={classes.label}>Filters</InputLabel>
              <GridContainer>
                <GridItem xs={6}>
                  <Autocomplete
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Advertiser"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    id="advertiser"
                    value={advertiserSelected}
                    options={advertiserList}
                    getOptionLabel={option => option.advertiser_name}
                    onChange={handleChangeAdvertiser}
                  />
                </GridItem>
                <GridItem xs={6}>
                  <Autocomplete
                    id="status"
                    style={styles.stateSelectBox}
                    value={statusSelected}
                    options={[
                      { status: "Pending" },
                      { status: "Paid" },
                      { status: "Active" },
                      { status: "Paused" },
                      { status: "Complete" },
                      { status: "Cancelled" },
                      { status: "Stopped" }
                      // { status: "Failed" }
                    ]}
                    getOptionLabel={option => option.status}
                    onChange={handleChangeStatus}
                    renderOption={({ status: value }) => {
                      // console.log("value: ", value);
                      if (value === "Active")
                        return (
                          <div style={styles.status}>
                            <OfflineBoltIcon style={styles.statusActive} />{" "}
                            Active
                          </div>
                        );

                      if (value === "Complete")
                        return (
                          <div style={styles.status}>
                            <CheckCircle style={styles.statusComplete} />{" "}
                            Complete
                          </div>
                        );

                      if (value === "Stopped")
                        return (
                          <div style={styles.status}>
                            <Stop style={styles.statusStopped} /> Stopped
                          </div>
                        );
                      if (value === "Paused")
                        return (
                          <div style={styles.status}>
                            <PauseCircleFilledIcon
                              style={styles.statusPaused}
                            />{" "}
                            Paused
                          </div>
                        );
                      if (value === "Paid")
                        return (
                          <div style={styles.status}>
                            <MonetizationOnIcon style={styles.statusPaid} />{" "}
                            Paid
                          </div>
                        );
                      if (value === "Cancelled")
                        return (
                          <div style={styles.status}>
                            <CancelIcon style={styles.statusCancelled} />
                            Cancelled
                          </div>
                        );
                      if (value === "Pending")
                        return (
                          <div style={styles.status}>
                            <ErrorIcon style={styles.statusPending} />
                            Pending
                          </div>
                        );
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Cycle"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer className={classes.gridTableContainer}>
        <GridItem xs={12}>
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                zIndex: "999"
              }}
            >
              <CircularProgress />
            </div>
          )}
          <MUIDataTable
            title={""}
            data={data}
            columns={columns}
            options={options}
          />
        </GridItem>
      </GridContainer>
    </GridContainer>
  );
}
