import React from "react";
import PropTypes from "prop-types";
// import { loginUser } from "../../../actions/authActions";
import { Link } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

// @material-ui/icons
import Email from "@material-ui/icons/Email";

// core components

import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import loginPageStyle from "assets/jss/admin/views/loginPageStyle.js";
import logo from "assets/img/kmg-logo-226-76.svg";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AuthActions from "../../../store/ducks/auth";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

function LoginPage(props) {
  const [cardAnimation, setCardAnimation] = React.useState("cardHidden");
  // const [isLoading, setIsLoading] = React.useState(false);

  const isLoading = props.auth.loading;

  React.useEffect(() => {
    setTimeout(() => setCardAnimation(""), 700);
  }, []);
  /*
  static propTypes = {
    signInRequest: PropTypes.func.isRequired
  };*/

  /*
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }*/

  const onSubmit = (data, e) => {
    console.log("hey mhayk:", data);
    e.preventDefault();

    const { email, password } = data;
    const { signInRequest } = props;

    // this.props.loginUser(email, password);
    console.log("onSubmit Mhayk");
    // setIsLoading(true);
    signInRequest(email, password);
  };

  const { classes } = props;
  const ele = document.getElementById("ipl-progress-indicator");

  const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
    password: yup.string().required()
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    validationSchema: LoginSchema,
    mode: "onBlur"
  });

  console.log(errors);

  return (
    // const { isAuthenticated, isLoading } = this.props.auth;

    // if (isLoading && isAuthenticated === false) {
    //   console.log(this.props.auth);
    //   console.log("carregando ....");
    //   // const ele = document.getElementById('ipl-progress-indicator')
    //   if (ele) {
    //     // fade out
    //     ele.classList.remove("available");
    //     // setTimeout(() => {
    //     //   // remove from DOM
    //     //   ele.outerHTML = ''
    //     // }, 2000)
    //   }
    // }
    // const { isAuthenticated } = this.props.auth
    // if (isAuthenticated === true) {
    //   console.log("I'm authenticated...");
    //   return <Redirect to="/" />;
    // }

    // <div className={classes.container}>
    //   <GridContainer justify="center">
    //     <GridItem xs={12} sm={6} md={4}>
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.container}
    >
      <Card
        login
        className={classes[cardAnimation]}
        style={{
          width: "509px",
          height: "418px",
          padding: "27px 60px 27px 60px"
        }}
      >
        {/* <CardHeader
            className={`${classes.cardHeader} ${classes.textCenter}`}
            color="warning"
          > */}

        {/* </CardHeader> */}
        <CardBody>
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Grid container direction="column">
              <img className={classes.logo} src={logo} alt="Logo KMG" />
              <TextField
                className={classes.emailTextField}
                formcontrolprops={{
                  fullWidth: true
                }}
                inputProps={{
                  endadornment: errors.email ? (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
                  autoFocus: true
                }}
                variant="filled"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ""}
                id="email"
                name="email"
                label="E-mail"
              />
              <TextField
                className={classes.passwordTextField}
                formcontrolprops={{
                  fullWidth: true
                }}
                inputProps={{
                  endadornment: errors.password ? (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
                  type: "password"
                }}
                variant="filled"
                {...register("password")}
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ""}
                label="Password"
                id="password"
                name="password"
              />
              <Box className={classes.groupButtons}>
                <Grid container justify="flex-end">
                  <Link
                    to="forgot-password"
                    className={classes.forgotPasswordLink}
                  >
                    <Button color="secondary" size="large">
                      FORGOT PASSWORD ?
                    </Button>
                  </Link>
                  {!isLoading ? (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                    >
                      SIGN IN
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      size="large"
                    >
                      SIGNING IN...
                      <CircularProgress
                        className={classes.CircularProgress}
                        size={20}
                      />
                    </Button>
                  )}
                </Grid>
              </Box>
            </Grid>
          </form>
        </CardBody>
      </Card>
    </Grid>
  );
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  return state;
};

// const mapDispatchToProps = (dispatch, props) => ({
//   loginUser: (email, password) => dispatch(loginUser(email, password))
// });

const mapDispatchToProps = dispatch =>
  bindActionCreators(AuthActions, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(loginPageStyle)(LoginPage));
