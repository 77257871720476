import React from "react";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Typography from "@material-ui/core/Typography";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import Fab from "@material-ui/core/Fab";
import Divider from "@material-ui/core/Divider";
import AddIcon from "@material-ui/icons/Add";

import Chip from "@material-ui/core/Chip";

import Label from "views/Components/ApproveCycles/Label";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: "20px",
    textAlign: "center"
  },
  margin: {
    margin: theme.spacing(1),
    minWidth: "140px"
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  cardBody: {
    paddingTop: "10px"
  },
  popover: {
    pointerEvents: "none"
  },
  paper: {
    padding: theme.spacing(1)
  },
  gridWrapperFirst: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr"
  },
  gridWrapper: {
    display: "grid",
    gridTemplateColumns: "2fr 1fr"
  },
  chip: {
    fontSize: "26px"
  },
  buttonActions: {
    textAlign: "center"
  },
  cardCycle: {
    marginBottom: "10px"
  },
  retainerHeader: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "5px"
  },
  intervals: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "16px",
    padding: "5px"
  },
  selfCell: {
    alignSelf: "end"
  }
}));

export default function Pages() {
  const classes = useStyles();

  const cards = [0, 1, 1, 1, 1, 1, 1, 1, 1, , 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1];

  return (
    <GridContainer>
      <GridContainer direction="row" justify="center" alignItems="center">
        <GridItem xs={3} className={classes.title}>
          <Typography variant="h4">Cycles to be Approved</Typography>
        </GridItem>
      </GridContainer>
      <GridContainer justify="center" alignItems="center">
        {cards.map((item, index) => (
          <GridItem
            key={index}
            xs={10}
            md={10}
            lg={12}
            className={classes.cardCycle}
          >
            <Card>
              <GridContainer
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={9}>
                  <CardContent>
                    <GridContainer>
                      <GridItem xs={8}>
                        <Chip
                          className={classes.chip}
                          variant="outlined"
                          label="MelodyVR"
                        />
                      </GridItem>
                      <GridItem xs={4}>01/07/2019 - 31/07/2019</GridItem>
                    </GridContainer>
                    <GridContainer className={classes.cardBody}>
                      <GridItem xs={5}>
                        <div className={classes.gridWrapperFirst}>
                          <Typography>Service</Typography>
                          <Typography>Retainer</Typography>
                          <Typography>Base Retainer Interval:</Typography>
                          <Typography>0.00 - 5,000.00</Typography>
                          <Typography>Base Retainer Fee:</Typography>
                          <Typography>£3,000.00</Typography>
                        </div>
                        <div className={classes.retainerHeader}>
                          <Chip
                            variant="outlined"
                            label="Retainers Intervals"
                          />
                        </div>
                        <div
                          className={`${classes.gridWrapperFirst} ${classes.intervals}`}
                        >
                          <Typography>#1</Typography>
                          <Typography></Typography>
                          <Typography>Retainer Interval:</Typography>
                          <Typography>5,000.01 - 10,000.00</Typography>
                          <Typography>Surplus Fee:</Typography>
                          <Typography>35%</Typography>
                          <Divider />
                          <Divider />
                          <Typography>#2</Typography>
                          <Typography></Typography>
                          <Typography>Retainer Interval:</Typography>
                          <Typography>10,000.01 - 500,000.00</Typography>
                          <Typography>Surplus Fee:</Typography>
                          <Typography>15%</Typography>
                        </div>
                      </GridItem>
                      <GridItem xs={2}>
                        <Divider orientation="vertical" variant="inset" />
                      </GridItem>

                      <GridItem xs={5}>
                        <div className={classes.gridWrapper}>
                          <Typography>Total Media Spend:</Typography>
                          <Typography>23,036.06</Typography>
                          <Typography>Surplus Fee #1:</Typography>
                          <Typography>1,750.00</Typography>
                          <Typography>Surplus Fee #2:</Typography>
                          <Typography>1,955.41</Typography>
                          {/* <Label
                            title="Media Budget:"
                            popover={
                              <>
                                <Typography>
                                  £3,000.00 + £3,910.81(July/2019)
                                </Typography>
                              </>
                            }
                          />
                          <Typography>£6,910.81</Typography>
                          <Label
                            title="Surplus Retainer Fee"
                            popover={
                              <>
                                <Typography>
                                  30% of surplus spend (£13,036.06)
                                </Typography>
                                <Typography>Date Start: 01/07/2019</Typography>
                                <Typography>Date End: 31/07/2019</Typography>
                              </>
                            }
                          />
                          <Typography>£3,910.81</Typography> */}
                        </div>
                        <div className={classes.retainerHeader}>
                          <Chip
                            variant="outlined"
                            label="Upcoming Cycle Value"
                          />
                        </div>
                        <div
                          className={`${classes.gridWrapperFirst} ${classes.intervals}`}
                        >
                          <Typography>Base Retainer Fee:</Typography>
                          <Typography>3,000.00</Typography>
                          <Typography>Surplus Fee #1:</Typography>
                          <Typography>1,750.00</Typography>
                          {/* <AddIcon className={classes.selfCell} /> */}
                          <Typography>Surplus Fee #2:</Typography>
                          <Typography>1,955.41</Typography>
                          <Divider />
                          <Divider />
                          <Typography className={classes.selfCell} variant="h6">
                            Total
                          </Typography>
                          <Typography variant="h6">6,705.41</Typography>
                        </div>
                      </GridItem>
                    </GridContainer>
                  </CardContent>
                </GridItem>
                <GridItem className={classes.buttonActions} xs={3}>
                  <Fab
                    variant="extended"
                    color="primary"
                    aria-label="add"
                    className={classes.margin}
                  >
                    <ThumbUpAltIcon className={classes.extendedIcon} />
                    Approve
                  </Fab>
                  <Fab
                    variant="extended"
                    color="secondary"
                    aria-label="add"
                    className={classes.margin}
                  >
                    <ThumbDownAltIcon className={classes.extendedIcon} />
                    Reject
                  </Fab>
                </GridItem>
              </GridContainer>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    </GridContainer>
  );
}
