import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import LoginPage from "components/Admin/Auth/LoginPage";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        console.log(rest);
        if (rest.auth.isAuthenticated && rest.auth.token != null) {
          if (props.location.pathname === "/") {
            // const ele = document.getElementById("ipl-progress-indicator");
            // ele.classList.add("available");
            return <Redirect from="/" to="/admin/dashboard" />;
          } else return <Component {...props} />;
        }
        // return <Redirect to={
        //     {
        //         pathname: "/auth",
        //         state: {
        //             from: rest.location
        //         }
        //     }
        // } />
        else {
          console.log("auth wrong...");
          // return <LoginPage />;
          return <Redirect from="/" to="/auth" />;
        }
      }}
    />
  );
};

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(ProtectedRoute);
