const ERROR_MESSAGE_REQUIRED = "This Field is required!";
const ERROR_MESSAGE_NUMBER_MIN =
  "This Field must be greater than or equal to 0";
const ERROR_MESSAGE_NUMBER = "This Field should only contain numbers!";

export {
  ERROR_MESSAGE_REQUIRED,
  ERROR_MESSAGE_NUMBER_MIN,
  ERROR_MESSAGE_NUMBER
};
