import React from "react";

import { Grid, TextField } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({}));

function Step1() {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <TextField
          name="advertiser_name"
          label="Advertiser Name"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="sales_representative"
          label="Sales Representative"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="address_1"
          label="Address 1"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="address_2"
          label="Address 2"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField name="city" label="City" variant="outlined" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="country"
          label="Country"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="state_province"
          label="State/Province"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="postal_code"
          label="Postal Code"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="phone_1"
          label="Phone 1"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="phone_2"
          label="Phone 2"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField name="fax" label="Fax" variant="outlined" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextField name="email" label="E-mail" variant="outlined" fullWidth />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="confirm_email"
          label="Confirm E-mail"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="company_website"
          label="Company Website"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField
          name="business_description"
          label="Business description"
          variant="outlined"
          fullWidth
        />
      </Grid>

      <Grid item xs={6}>
        <TextField name="notes" label="Notes" variant="outlined" fullWidth />
      </Grid>
    </Grid>
  );
}

export default Step1;
