import React, { useEffect } from "react";

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Button,
  InputAdornment
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import { makeStyles } from "@material-ui/core/styles";

import Autocomplete from "@material-ui/lab/Autocomplete";

import enGBLocale from "date-fns/locale/en-GB";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { DatePicker } from "@material-ui/pickers";

import { useForm } from "react-hook-form";
import * as yup from "yup";

import { toastr } from "react-redux-toastr";
import toastrOptions from "variables/toastr";

import format from "date-fns/format";

import api from "services/api";

import {
  ERROR_MESSAGE_REQUIRED,
  ERROR_MESSAGE_NUMBER,
  ERROR_MESSAGE_NUMBER_MIN
} from "variables/messages";

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

const validationSchema = yup.object().shape({
  setup_fee: yup.number().required(ERROR_MESSAGE_NUMBER_MIN),
  mngm_fee: yup.number().required(ERROR_MESSAGE_NUMBER_MIN),
  // lead_fee: yup.number().required(ERROR_MESSAGE_NUMBER_MIN),
  budget: yup.number().required(ERROR_MESSAGE_NUMBER_MIN),
  cycles_amount: yup.number().required(ERROR_MESSAGE_NUMBER_MIN)
});

const defaultValues = {
  setup_fee: "0.00",
  mngm_fee: "0.00",
  budget: "0.00",
  lead_fee: "0.00",
  cycles_amount: "1",
  live_date: format(new Date(), "dd/MM/yyyy")
};

function CreateCycleDialog(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue
  } = useForm({
    validationSchema,
    defaultValues,
    mode: "onBlur"
  });
  const inputSelectServiceRef = React.useRef();

  const cycleTotal =
    parseFloat(watch("setup_fee")) +
    (parseFloat(watch("mngm_fee")) + parseFloat(watch("budget")));

  const leadsPerCycle =
    parseFloat(watch("budget")) / parseFloat(watch("price_lead"));

  const staticDateAdapter = new DateFnsAdapter({ locale: enGBLocale });

  const {
    open,
    handleClose,
    handleSave,
    handleUpdate,
    advertiser,
    initialValues
  } = props;
  const classes = useStyles();

  const [serviceList, setServiceList] = React.useState([]);
  const [serviceSelected, setServiceSelected] = React.useState(null);
  const [date, setDate] = React.useState(new Date());

  React.useEffect(() => {
    async function loadServices() {
      const services = await api.get("services");
      setServiceList(services.data);
    }
    loadServices();
  }, []);

  React.useEffect(() => {
    // console.log(`${initialValues}: initialValues`);
    if (initialValues) {
      const {
        service_selected,
        setup_fee,
        mngm_fee,
        budget,
        lead_fee,
        cycles_amount,
        live_date
      } = initialValues.data;
      setServiceSelected(service_selected);
      const defaultValues = {
        setup_fee,
        mngm_fee,
        budget,
        lead_fee,
        cycles_amount,
        live_date
      };
      reset(defaultValues);
    }
  }, [initialValues]);

  const handleDateChange = args => setDate(args);

  const handleChangeService = (event, value) => {
    console.log(`handleChangeService: ${value}`);
    if (value) {
      setServiceSelected(value);
      const { name, setup_fee, mngm_fee, lead_fee } = value;
      console.log(value);

      if (name !== "Smartchat")
        setValue("lead_fee", "0.00", { shouldValidate: true });

      reset({
        setup_fee,
        mngm_fee,
        lead_fee,
        budget: "0.00",
        cycles_amount: "1"
      });
    } else {
      reset(defaultValues);
      setServiceSelected(null);
    }
  };

  const onClose = () => {
    reset(defaultValues);
    setServiceSelected(null);
    // setShowSmartChatFields(false);
    handleClose();
  };

  const onSubmit = (data, e) => {
    // console.log(data);
    e.preventDefault();

    if (!serviceSelected) {
      toastr.warning(
        "Select Service",
        "You need to select a service to move forward.",
        toastrOptions
      );
      inputSelectServiceRef.current.focus();
      return;
    }

    if (initialValues) {
      handleUpdate(
        {
          ...data,
          service_selected: serviceSelected,
          cycleTotal
        },
        initialValues.index
      );
    } else
      handleSave({ ...data, service_selected: serviceSelected, cycleTotal });

    setServiceSelected(null);
    reset(defaultValues);
    handleClose();
  };

  console.log(errors);
  // console.log(`serviceSelected: ${serviceSelected}`);

  return (
    <>
      {open && (
        <Dialog open fullWidth maxWidth="sm">
          <DialogTitle>Add New Cycle</DialogTitle>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <DialogContent dividers>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Autocomplete
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Services"
                        variant="outlined"
                        fullWidth
                        autoFocus
                        inputRef={inputSelectServiceRef}
                      />
                    )}
                    id="service"
                    value={serviceSelected}
                    options={serviceList}
                    getOptionLabel={option => option.name}
                    onChange={handleChangeService}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="setup_fee"
                    {...register("setup_fee", { required: true })}
                    label="Set Up Fee"
                    variant="outlined"
                    fullWidth
                    helperText={
                      errors.setup_fee
                        ? errors.setup_fee.message
                        : "Typically 10%"
                    }
                    error={!!errors.setup_fee}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      )
                    }}
                    inputProps={{
                      pattern: "^\\d*(\\.\\d{0,2})?$",
                      step: "0.01"
                    }}
                    type="number"
                    disabled={!serviceSelected}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="mngm_fee"
                    {...register("mngm_fee", { required: true })}
                    label="Tracking & Tech Fee"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      )
                    }}
                    inputProps={{
                      pattern: "^\\d*(\\.\\d{0,2})?$"
                    }}
                    type="number"
                    helperText={
                      errors.mngm_fee
                        ? errors.mngm_fee.message
                        : "Typically 10%"
                    }
                    error={!!errors.mngm_fee}
                    disabled={!serviceSelected}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="budget"
                    {...register("budget", { required: true })}
                    label="Media Budget"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      )
                    }}
                    inputProps={{
                      pattern: "^\\d*(\\.\\d{0,2})?$",
                      step: ".01"
                    }}
                    type="number"
                    helperText={
                      errors.mngm_fee
                        ? errors.mngm_fee.message
                        : "Minimum £1,500"
                    }
                    error={!!errors.mngm_fee}
                    disabled={!serviceSelected}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="cycles_amount"
                    {...register("cycles_amount", { required: true })}
                    label="Number of Cycles"
                    variant="outlined"
                    fullWidth
                    type="number"
                    helperText={
                      errors.cycles_amount
                        ? errors.cycles_amount.message
                        : "Default 6"
                    }
                    error={!!errors.cycles_amount}
                    disabled={!serviceSelected}
                  />
                </Grid>

                {serviceSelected && serviceSelected.name === "Smartchat" && (
                  <Grid item xs={6}>
                    <TextField
                      name="lead_fee"
                      {...register("lead_fee", { required: true })}
                      label="Price Per Lead"
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">£</InputAdornment>
                        )
                      }}
                      inputProps={{
                        pattern: "^\\d*(\\.\\d{0,2})?$",
                        step: ".01"
                      }}
                      type="number"
                      helperText={
                        errors.lead_fee
                          ? errors.lead_fee.message
                          : "Must be 8.00 or 9.60"
                      }
                      error={!!errors.lead_fee}
                      disabled={!serviceSelected}
                    />
                  </Grid>
                )}

                <Grid item xs={6}>
                  {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
                  {/* <DatePicker
                    // variant="inline"
                    inputVariant="outlined"
                    // views={["year", "month"]}
                    label="Go live date"
                    format="dd/MM/yyyy"
                    // helperText="With min and max"
                    // minDate={new Date("2019-10-01")}
                    // maxDate={new Date()}
                    value={date}
                    inputRef={register}
                    // onChange={handleDateChange}
                    onChange={handleDateChange}
                    name="live_date"
                    fullWidth
                    disabled={!serviceSelected}
                    dateAdapter={staticDateAdapter}
                  /> */}
                  {/* </MuiPickersUtilsProvider> */}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary">
                SAVE CHANGES
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </>
  );
}

export default CreateCycleDialog;
