import React, { useEffect, useState } from "react";

// core componentes
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// @material-ui/icons
import CheckCircle from "@material-ui/icons/CheckCircle";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import Stop from "@material-ui/icons/RemoveCircle";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";

// @material-ui/lab
import Autocomplete from "@material-ui/lab/Autocomplete";

import MUIDataTable from "mui-datatables";

import api from "services/api";

import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import { DatePicker, LocalizationProvider } from "@material-ui/pickers";
import { format, subMonths } from "date-fns";

import { formatDate, formatNumber } from "utils/formats";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";

const styles = {
  filters: {
    display: "flex",
    flex: 1
  },
  status: {
    display: "flex",
    alignItems: "center"
  },
  statusPaid: {
    color: "#9E9D24"
  },
  statusPending: {
    color: "#EF6C00"
  }
};

const myTheme = createMuiTheme({
  overrides: {
    MUIDataTable: {
      paper: {
        height: "inherit"
      },
      responsiveScroll: {
        maxHeight: "none",
        height: "calc(100% - 128px)"
      }
    },
    MuiDataTableBodyCell: {
      "12-0": {
        display: "flex",
        alignItems: "center"
      }
    }
  }
});

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "860px"
  },
  invoiceContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > button": {
      marginTop: "20px"
    }
  },
  invoiceHeader: {
    display: "flex",
    justifyContent: "center"
  },
  invoiceSubHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  invoiceClientSection: {
    width: "100%",
    "& > div": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",

      "& > div > label": {
        display: "inline-block",
        width: "150px"
      }
    }
  },
  invoiceOrderSummarySection: {},
  colSpanCell: {
    textAlign: "right"
  }
}));

let render = 0;
function InvoicesPage() {
  const [advertiserList, setAdvertiserList] = useState([]);
  const [serviceList, setServiceList] = useState([]);

  const [isLoading, setIsLoading] = React.useState(false);

  const [advertiserSelected, setAdvertiserSelected] = useState(null);
  const [serviceSelected, setServiceSelected] = useState(null);
  const [statusSelected, setStatusSelected] = useState(null);

  const [data, setData] = React.useState([]);
  const [invoiceSelectedRow, setInvoiceSelectedRow] = useState(null);

  const [isInvoicePreviewModalOpen, setIsInvoicePreviewModalOpen] = useState(
    false
  );

  const [selectedDate, setSelectedDate] = React.useState(
    format(subMonths(new Date(), 2), `yyyy-MM-dd`)
  );

  const classes = useStyles();

  const columns = [
    {
      name: "cycle_id",
      label: "Cycle ID",
      sortDirection: "desc"
    },
    {
      name: "advertiser_name",
      label: "Name"
    },
    {
      name: "service",
      label: "Service"
    },
    {
      name: "live_date",
      label: "Date of Invoice",
      options: {
        customBodyRender: value => formatDate(value)
      }
    },
    {
      name: "date_of_paid",
      label: "Date of Paid",
      options: {
        customBodyRender: value => formatDate(value)
      }
    },
    {
      name: "id",
      label: "Invoice No"
    },
    {
      name: "status",
      label: "Status",
      options: {
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          /* Pending Status */
          if (value === 1)
            return (
              <div style={styles.status}>
                <ErrorIcon style={styles.statusPending} />
                Pending
              </div>
            );
          else
            return (
              <div style={styles.status}>
                <MonetizationOnIcon style={styles.statusPaid} /> Paid
              </div>
            );
        }
      }
    }
  ];

  const buildQueryFilter = (advertiser, service, status, date) => {
    let query = {};

    if (advertiser && advertiser.advertiser_name)
      query.advertiser = advertiser.advertiser_name;

    if (service && service.name) query.service = service.name;

    if (status === "Paid") query.status = 2;
    if (status === "Pending") query.status = 1;

    if (date) query.source_date = date;

    if (query) return query;

    return {};
  };

  const fetchData = filter => {
    setIsLoading(true);
    api
      .get("/invoices", {
        params: {
          sort: "desc",
          ...filter
        }
      })
      .then(response => {
        setData(response.data);
        setIsLoading(false);
      });
  };

  const handleChangeAdvertiser = (event, value) => {
    setAdvertiserSelected(value);
    const filter = buildQueryFilter(
      value,
      serviceSelected,
      statusSelected,
      selectedDate
    );
    fetchData(filter);
  };

  const handleChangeService = (event, value) => {
    setServiceSelected(value);
    const filter = buildQueryFilter(
      advertiserSelected,
      value,
      statusSelected,
      selectedDate
    );
    fetchData(filter);
  };

  const handleChangeStatus = (event, value) => {
    setStatusSelected(value);
    const filter = buildQueryFilter(advertiserSelected, serviceSelected, value);
    fetchData(filter);
  };

  const handleDateChange = date => {
    const formatedDate = format(date, `yyyy-MM-dd`);
    setSelectedDate(formatedDate);
    const filter = buildQueryFilter(
      advertiserSelected,
      serviceSelected,
      statusSelected,
      formatedDate
    );
    fetchData(filter);
  };

  function handleOpenInvoicePreviewModal() {
    setIsInvoicePreviewModalOpen(true);
  }

  function handleCloseInvoicePreviewModal() {
    setIsInvoicePreviewModalOpen(false);
  }

  const handleRowClick = rowData => {
    setIsInvoicePreviewModalOpen(true);

    let invoiceDataToPreview = data.filter(
      invoice => invoice.cycle_id === rowData[0]
    )[0];

    const subtotal =
      Number(invoiceDataToPreview.setup_fee) +
      Number(invoiceDataToPreview.mnmg_fee) +
      Number(invoiceDataToPreview.budget);
    console.log(invoiceDataToPreview);
    const vat = invoiceDataToPreview.advertiser_vat ? subtotal * 0.2 : 0;
    const total = subtotal + vat;

    invoiceDataToPreview = {
      ...invoiceDataToPreview,
      subtotal,
      vat,
      total
    };

    setInvoiceSelectedRow(invoiceDataToPreview);
  };

  const options = {
    filter: false,
    filterType: "multisselect",
    responsive: "scrollFullHeight",
    selectableRows: "none",
    rowsPerPage: 100,
    rowsPerPageOptions: [50, 100, 200, 500],
    onRowClick: handleRowClick
  };

  React.useEffect(() => {
    setIsLoading(true);
    api
      .get("/invoices", {
        params: {
          sort: "desc"
        }
      })
      .then(response => {
        setData(response.data);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    api
      .get("advertisers?fields=advertiser_name")
      .then(({ data }) => setAdvertiserList(data));
  }, []);

  useEffect(() => {
    api.get("services?fields=name").then(({ data }) => setServiceList(data));
  }, []);

  return (
    // <div className="animated fadeInRight">
    <div>
      <GridContainer>
        CyclePage render: {++render}
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            {/* <div style={styles.filters}> */}
            <Autocomplete
              renderInput={params => (
                <TextField
                  {...params}
                  label="Seleact a Advertiser"
                  variant="outlined"
                  fullWidth
                />
              )}
              id="advertisers"
              value={advertiserSelected}
              options={advertiserList}
              getOptionLabel={option => option.advertiser_name}
              onChange={handleChangeAdvertiser}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Autocomplete
              renderInput={params => (
                <TextField
                  {...params}
                  label="Select a Service"
                  variant="outlined"
                  fullWidth
                />
              )}
              id="services"
              value={serviceSelected}
              options={serviceList}
              getOptionLabel={option => option.name}
              onChange={handleChangeService}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Autocomplete
              renderInput={params => (
                <TextField
                  {...params}
                  label="Invoice Status"
                  variant="outlined"
                  fullWidth
                />
              )}
              id="status"
              value={statusSelected}
              options={["Pending", "Paid"]}
              onChange={handleChangeStatus}
              renderOption={value => {
                if (value === "Pending")
                  return (
                    <div style={styles.status}>
                      <ErrorIcon style={styles.statusPending} />
                      Pending
                    </div>
                  );
                if (value === "Paid")
                  return (
                    <div style={styles.status}>
                      <MonetizationOnIcon style={styles.statusPaid} /> Paid
                    </div>
                  );
              }}
            />
            {/* </div> */}
          </GridItem>
          <GridItem>
            <LocalizationProvider dateAdapter={DateFnsAdapter}>
              <DatePicker
                disableToolbar
                variant="inline"
                inputFormat="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Select Source Date"
                value={selectedDate}
                onChange={handleDateChange}
                autoOk
                renderInput={props => <TextField {...props} />}
                allowKeyboardControl
              />
            </LocalizationProvider>
            <Tooltip title="Date of paid">
              <IconButton aria-label="info">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px",
                  zIndex: "999"
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <MuiThemeProvider theme={myTheme}>
                <>
                  <MUIDataTable
                    title={""}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </>
              </MuiThemeProvider>
            )}
          </GridItem>
        </GridContainer>
      </GridContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isInvoicePreviewModalOpen}
        onClose={handleCloseInvoicePreviewModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isInvoicePreviewModalOpen}>
          <div className={classes.paper}>
            {/* <h2 id="transition-modal-title">Transition modal</h2>
            <p id="transition-modal-description">
              react-transition-group animates me.
            </p> */}
            {invoiceSelectedRow && (
              <main className={classes.invoiceContainer}>
                <div className={classes.invoiceHeader}>
                  {`Invoice #${invoiceSelectedRow.id}`}
                </div>
                <div className={classes.invoiceSubHeader}>
                  <div>
                    <label>Payment status:</label>{" "}
                    <span>
                      {invoiceSelectedRow.status === 1 ? "Pending" : "Paid"}
                    </span>
                  </div>
                  <div>
                    <label>Date of Paid:</label>
                    {invoiceSelectedRow.date_of_paid}
                  </div>
                </div>

                <section className={classes.invoiceClientSection}>
                  <h3>Client Business Information</h3>
                  <div>
                    <div>
                      <label>Business Name</label>
                      <span>{invoiceSelectedRow.advertiser_name}</span>
                    </div>
                    <div>
                      <label>Landline Phone</label>
                      <span>{invoiceSelectedRow.advertiser_phone_1}</span>
                    </div>
                    <div>
                      <label>Email</label>
                      <span>{invoiceSelectedRow.advertiser_email}</span>
                    </div>
                    <div>
                      <label>URL</label>
                      <span>
                        {invoiceSelectedRow.advertiser_company_website}
                      </span>
                    </div>
                    <div>
                      <label>Address</label>
                      <span>{invoiceSelectedRow.advertiser_address_1}</span>
                    </div>
                    <div>
                      <label>City</label>
                      <span>{invoiceSelectedRow.advertiser_city}</span>
                    </div>
                    <div>
                      <label>Country</label>
                      <span>{invoiceSelectedRow.advertiser_country}</span>
                    </div>
                    <div>
                      <label>Postcode</label>
                      <span>{invoiceSelectedRow.advertiser_postcode}</span>
                    </div>
                  </div>
                </section>

                <section className={classes.invoiceOrderSummarySection}>
                  <h3>Order Summary</h3>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Product/Service</TableCell>
                        <TableCell>Setup Fee</TableCell>
                        <TableCell>Tracking&Tech Fee</TableCell>
                        <TableCell>
                          Media budget, Management & Optimisation
                        </TableCell>
                        <TableCell>Line Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{invoiceSelectedRow.service}</TableCell>
                        <TableCell>
                          {formatNumber(invoiceSelectedRow.setup_fee)}
                        </TableCell>
                        <TableCell>
                          {formatNumber(invoiceSelectedRow.mnmg_fee)}
                        </TableCell>
                        <TableCell>
                          {formatNumber(Number(invoiceSelectedRow.budget))}
                        </TableCell>
                        <TableCell>
                          {formatNumber(Number(invoiceSelectedRow.subtotal))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.colSpanCell} colSpan={4}>
                          SubTotal
                        </TableCell>
                        <TableCell>
                          {formatNumber(Number(invoiceSelectedRow.subtotal))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.colSpanCell} colSpan={4}>
                          VAT
                        </TableCell>
                        <TableCell>
                          {formatNumber(Number(invoiceSelectedRow.vat))}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.colSpanCell} colSpan={4}>
                          Total
                        </TableCell>
                        <TableCell>
                          {formatNumber(Number(invoiceSelectedRow.total))}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </section>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    window.location.href = `https://admin.kaumediagroup.com/auth/invoice/generate/${invoiceSelectedRow.id}`;
                  }}
                >
                  Download Invoice
                </Button>
              </main>
            )}
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default InvoicesPage;
