export default function(resouce) {
  const { path } = resouce;
  const { acl_user, role } = JSON.parse(localStorage.getItem("@KMG:profile"));
  // console.log("Permission:", resouce);
  // console.log("acl_user: ", acl_user);
  let canAccess = false;
  if (role === 1) return true;
  switch (path) {
    case "/settings":
      canAccess = acl_user.includes("settings");
      break;
    case "/admin-users":
      break;
    case "/bank-details":
      canAccess =
        acl_user.includes("payment_mnmg") && (role !== 0 && role !== 3);
      break;
    case "/reports":
      canAccess = acl_user.includes("reports") || role === 3;
      break;
    case "/sales-dashboard":
      canAccess = acl_user.includes("reports_sales_dashboard") || role === 3;
      break;
    default:
      canAccess = false;
      break;
  }
  return canAccess;
}
