import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

import { Link } from "react-router-dom";

import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import logo from "assets/img/kmg-logo-226-76.svg";

const useStyles = makeStyles({
  container: {
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#474747"
  },
  card: {
    maxWidth: 509,
    minWidth: 509,
    maxHeight: 418,
    padding: "20px",
    paddingBottom: "43px"
  },
  logo: {
    display: "block",
    margin: "auto",
    marginTop: "9.16px",
    marginBottom: "34.77px"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "42.16px",
    paddingRight: "62px"
  },
  emailInput: {
    marginTop: 22
  },
  button: {
    marginTop: 46,
    alignSelf: "center"
  }
});

function ForgotPasswordPage() {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Card className={classes.card}>
        <Link to="/" style={{ color: "black" }}>
          <ArrowBackIcon />
        </Link>
        <img src={logo} className={classes.logo} alt="Logo KMG" />
        <form className={classes.form}>
          <Typography style={{ textTransform: "none" }} variant="h6">
            Forgot password ?
          </Typography>
          <Typography variant="body2">
            For a new password, please enter your e-mail address
          </Typography>
          <TextField
            className={classes.emailInput}
            fullWidth
            id="filled-basic"
            label="E-mail"
            variant="filled"
            autoFocus
          />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            size="large"
          >
            SUBMIT
          </Button>
        </form>
      </Card>
    </Grid>
  );
}

export default ForgotPasswordPage;
