import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AuthActions from "store/ducks/auth";
// import { setLogoutSuccess } from "../../../actions/authActions";
import { Redirect } from "react-router-dom";

class LogoutPage extends React.Component {
  constructor(props) {
    super(props);

    const { signOut } = this.props;
    signOut();
  }

  render() {
    // console.log(this.props);
    // const { signOut } = this.props;
    // console.log(this.props);
    // signOut();
    return <Redirect to="/auth" />;
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...AuthActions }, dispatch);

export default connect(
  undefined,
  mapDispatchToProps
)(LogoutPage);
