import React from "react";

// import { Container } from './styles';

// @material-ui/core components
import Fab from "@material-ui/core/Fab";

// core componentes
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import Badge from "components/Badge/Badge.js";

// @material-ui/icons
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import Edit from "@material-ui/icons/Edit";
import Add from "@material-ui/icons/AddSharp";
import CircularProgress from "@material-ui/core/CircularProgress";

import ServicesAddPage from "views/Pages/ServicesAddPage";
import ServicesEditPage from "views/Pages/ServicesEditPage";

import {
  makeStyles,
  createTheme,
  MuiThemeProvider
} from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";

import api from "services/api";

import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
const useStyles = makeStyles(styles);

const myTheme = createTheme({
  overrides: {
    MUIDataTable: {
      paper: {
        height: "inherit"
      },
      responsiveScroll: {
        maxHeight: "none",
        height: "calc(100% - 128px)"
      }
    },
    MuiDataTableBodyCell: {
      "12-0": {
        display: "flex",
        alignItems: "center"
      }
    }
  }
});

const options = {
  filter: false,
  filterType: "multisselect",
  responsive: "scrollFullHeight",
  selectableRows: "none"
};

// const data = [[1, "PPC", "0.00", 100.0, "0.00", "Not Active", "On"]];

const servicesStyles = makeStyles({
  MarginTop: {
    marginTop: "10px"
  }
});

export default function ServicesPage() {
  const classes = useStyles();
  const servicesClasses = servicesStyles();

  const columns = [
    {
      name: "ID"
    },
    {
      name: "Name"
    },
    {
      name: "Acc. setup fee"
    },
    {
      name: "Tracking & Tech Fee"
    },
    {
      name: "Lead Fee"
    },
    {
      name: "KMG Select",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value == 1 ? (
            // <Button size="sm" disabled color="danger">
            //   Not Active
            // </Button>
            <Badge color="info">Active</Badge>
          ) : (
            // <Button size="sm" disabled color="success">
            //   Active
            // </Button>
            <Badge color="warning">Not Active</Badge>
          );
        }
      }
    },
    {
      name: "Auto renew",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value == 1 ? (
            // <Switch disableRipple checked />
            <Badge color="success">On</Badge>
          ) : (
            // <Switch disableRipple />
            <Badge color="danger">Off</Badge>
          );
        }
      }
    },
    {
      name: "Action",
      options: {
        print: false,
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              size="small"
              color="secondary"
              aria-label="add"
              // className={classes.margin}
            >
              <Edit
                onClick={e => {
                  setServiceID(tableMeta.rowData[0]);
                  setOpenEdit(true);
                }}
              />
            </Fab>
          );
        }
      }
    }
  ];

  const [isLoading, setIsloading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [serviceID, setServiceID] = React.useState();
  const [data, setData] = React.useState([]);

  const handleHideModal = () => setOpen(false);
  const handleHideEditModal = () => setOpenEdit(false);

  React.useEffect(() => {
    try {
      api.get("services").then(res => {
        const data = res.data.map(item => [
          item.id,
          item.name,
          item.setup_fee,
          item.mngm_fee,
          item.lead_fee,
          item.kgm,
          item.renew
        ]);
        setData(data);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <div className="animated fadeInRight">
      <GridContainer>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <SettingsOutlinedIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>List of Services</h4>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Button
                  justIcon
                  round
                  color="success"
                  size="lg"
                  onClick={() => setOpen(true)}
                >
                  <Add />
                </Button>
              </GridItem>
              <ServicesAddPage open={open} handleClose={handleHideModal} />
              <ServicesEditPage
                open={openEdit}
                handleClose={handleHideEditModal}
                serviceID={serviceID}
              />
            </GridContainer>
            <GridContainer className={servicesClasses.MarginTop}>
              <GridItem xs={12} sm={12} md={12}>
                <MuiThemeProvider theme={myTheme}>
                  <>
                    {isLoading && (
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%"
                        }}
                      >
                        <CircularProgress />
                      </div>
                    )}
                    <MUIDataTable
                      title={""}
                      data={data}
                      columns={columns}
                      options={options}
                    />
                  </>
                </MuiThemeProvider>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </GridContainer>
    </div>
  );
}
