import { createMuiTheme } from "@material-ui/core";

const kmgPrimaryMain = "#03A9F4";
const kmgPrimaryDark = "#01579B";
const kmgPrimaryLight = "#67DAFF";
const kmgSecondaryMain = "#F18841";
const kmgSecondaryDark = "#B95A11";
const kmgSecondaryLight = "#FFB96F";

export default createMuiTheme({
  palette: {
    // common: {
    //   blue: `${arcBlue}`,
    //   orange: `${arcOrange}`
    // },
    primary: {
      main: `${kmgPrimaryMain}`,
      dark: `${kmgPrimaryDark}`,
      light: `${kmgPrimaryLight}`,
      contrastText: "#fff"
    },
    secondary: {
      main: `${kmgSecondaryMain}`,
      dark: `${kmgSecondaryDark}`,
      light: `${kmgSecondaryLight}`,
      contrastText: "#fff"
    }
  },
  typography: {
    tab: {
      fontFamily: "Raleway",
      textTransform: "none",
      fontWeight: 700,
      fontSize: "1rem"
    },
    estimate: {
      fontFamily: "Pacifico",
      fontSize: "1rem",
      textTransform: "none",
      color: "white"
    }
  }
});
