import React from "react";

import { toastr } from "react-redux-toastr";

import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import Close from "@material-ui/icons/Close";

// core componentes
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import api from "services/api";

// import { Container } from './styles';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);
const settingsStyles = makeStyles({
  saveButton: {
    float: "center"
  }
});

const toastrOptions = {
  closeButton: false,
  timeOut: 4000,
  progressBar: true
};

let renderCount = 0;

export default function SettingsPage() {
  const classes = useStyles();
  const settingsClasses = settingsStyles();

  const ERROR_MESSAGE_REQUIRED = "This Field is required!";
  const ERROR_MESSAGE_NUMBER = "This Field should only contain numbers!";
  const ERROR_MESSAGE_NUMBER_MIN =
    "This Field must be greater than or equal to 0";

  const DirectDebitSettingsSchema = yup.object().shape({
    directdebitkey: yup.string().required(ERROR_MESSAGE_REQUIRED),
    direct_debit_webhook_key: yup.string().required(ERROR_MESSAGE_REQUIRED),
    max_repeat_direct_debit: yup
      .number()
      .min(0, ERROR_MESSAGE_NUMBER_MIN)
      .required()
      .typeError(ERROR_MESSAGE_NUMBER)
  });

  const CreditCardSettingsSchema = yup.object().shape({
    sagepay_vendor: yup.string().required(ERROR_MESSAGE_REQUIRED),
    sagepay_integration_key: yup.string().required(ERROR_MESSAGE_REQUIRED),
    sagepay_integration_password: yup.string().required(ERROR_MESSAGE_REQUIRED),
    max_retry_credit_card: yup
      .number()
      .min(0, ERROR_MESSAGE_NUMBER_MIN)
      .required()
      .typeError(ERROR_MESSAGE_NUMBER),
    tax_credit_card: yup
      .number()
      .min(0, ERROR_MESSAGE_NUMBER_MIN)
      .typeError(ERROR_MESSAGE_NUMBER)
  });

  const { register, handleSubmit, errors, reset } = useForm({
    // defaultValues: {
    //   directdebitkey: "abcdef"
    // },
    validationSchema: DirectDebitSettingsSchema,
    mode: "onBlur"
  }); // initialise the hook

  const {
    register: registerForm2,
    handleSubmit: handleSubmitForm2,
    errors: errorsForm2,
    reset: resetForm2
  } = useForm({
    validationSchema: CreditCardSettingsSchema
  }); // initialise the hook

  React.useEffect(() => {
    // async function loadData() {
    //   try {
    //     const response = await api.get("settings");
    //     setdirectDebitAPIKey(response.data[4].val);
    //     setdirectDebitWebHookKey(response.data[5].val);
    //     setmaxRepeatsDirectDebit(response.data[6].val);

    //     setSagePayVendor(response.data[0].val);
    //     setsagePayIntegrationKey(response.data[1].val);
    //     setsagePayIntegrationPassword(response.data[2].val);
    //     setmaxRepeatsCreditCard(response.data[3].val);
    //     settaxCreditCardPayments(response.data[7].val);

    //     setVaue("directdebitkey", response.data[4].val);
    //     // await reset({
    //     //   directdebitkey: directDebitAPIKey
    //     // });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
    api.get("settings").then(res => {
      console.log(res.data);

      reset({
        directdebitkey: res.data[4].val,
        direct_debit_webhook_key: res.data[5].val,
        max_repeat_direct_debit: res.data[6].val
      });

      resetForm2({
        sagepay_vendor: res.data[0].val,
        sagepay_integration_key: res.data[1].val,
        sagepay_integration_password: res.data[2].val,
        max_retry_credit_card: res.data[3].val,
        tax_credit_card: res.data[7].val
      });
    });
    // loadData();
  }, []);

  const onSubmit = (data, e) => {
    e.preventDefault();
    console.log(data);
    toastr.success(
      "Direct Debit Settigs",
      "The direct debit settings has been successfully saved",
      toastrOptions
    );
    // props.history.push("/");
  };
  const onSubmitCCSettings = (data, e) => {
    // console.log(arguments);
    e.preventDefault();
    console.log(data);
    toastr.success(
      "Credit Card Settigs",
      "The credit card settings has been successfully saved",
      toastrOptions
    );
    // props.history.push("/");
  };
  console.log(errors);
  console.log(errorsForm2);

  renderCount++;

  return (
    <div className="animated fadeInRight">
      <span className="counter">Render Count: {renderCount}</span>
      <GridContainer>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <SettingsOutlinedIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Direct Debit Settings</h4>
          </CardHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardBody>
              <GridContainer
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridContainer xs={8} item={true}>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Direct Debit API Key
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: errors.directdebitkey ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }}
                      inputRef={register}
                      error={!!errors.directdebitkey}
                      helperText={
                        errors.directdebitkey
                          ? errors.directdebitkey.message
                          : ""
                      }
                      id="directdebitkey"
                      name="directdebitkey"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer xs={8} item={true}>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Direct Debit Web Hook Key *
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: errors.direct_debit_webhook_key ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }}
                      inputRef={register}
                      error={!!errors.direct_debit_webhook_key}
                      helperText={
                        errors.direct_debit_webhook_key
                          ? errors.direct_debit_webhook_key.message
                          : ""
                      }
                      id="direct_debit_webhook_key"
                      name="direct_debit_webhook_key"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer xs={8} item={true}>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Max number of repeats for Direct Debit (when payment
                      failed)
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        endAdornment: errors.max_repeat_direct_debit ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }}
                      inputRef={register}
                      error={!!errors.max_repeat_direct_debit}
                      helperText={
                        errors.max_repeat_direct_debit
                          ? errors.max_repeat_direct_debit.message
                          : ""
                      }
                      id="max_repeat_direct_debit"
                      name="max_repeat_direct_debit"
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button type="submit" color="rose">
                Save Your Changes
              </Button>
            </CardFooter>
          </form>
        </Card>
      </GridContainer>
      <GridContainer>
        <Card>
          <form onSubmit={handleSubmitForm2(onSubmitCCSettings)}>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <SettingsOutlinedIcon />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>Credit Card Settings</h4>
            </CardHeader>
            <CardBody>
              <GridContainer
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridContainer xs={8} item={true}>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      SagePay Vendor
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        endAdornment: errorsForm2.sagepay_vendor ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }}
                      inputRef={registerForm2}
                      error={!!errorsForm2.sagepay_vendor}
                      helperText={
                        errorsForm2.sagepay_vendor
                          ? errorsForm2.sagepay_vendor.message
                          : ""
                      }
                      id="sagepay_vendor"
                      name="sagepay_vendor"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer xs={8} item={true}>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      SagePay Integration Key
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        endAdornment: errorsForm2.sagepay_integration_key ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }}
                      inputRef={registerForm2}
                      error={!!errorsForm2.sagepay_integration_key}
                      helperText={
                        errorsForm2.sagepay_integration_key
                          ? errorsForm2.sagepay_integration_key.message
                          : ""
                      }
                      id="sagepay_integration_key"
                      name="sagepay_integration_key"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer xs={8} item={true}>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      SagePay Integration Password
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      id="sagepay_integration_password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "text",
                        endAdornment: errorsForm2.sagepay_integration_password ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }}
                      inputRef={registerForm2}
                      error={!!errorsForm2.sagepay_integration_password}
                      helperText={
                        errorsForm2.sagepay_integration_password
                          ? errorsForm2.sagepay_integration_password.message
                          : ""
                      }
                      name="sagepay_integration_password"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer xs={8} item={true}>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Max number of repeats for Credit card (when payment
                      failed)
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        endAdornment: errorsForm2.max_retry_credit_card ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }}
                      inputRef={registerForm2}
                      error={!!errorsForm2.max_retry_credit_card}
                      helperText={
                        errorsForm2.max_retry_credit_card
                          ? errorsForm2.max_retry_credit_card.message
                          : ""
                      }
                      id="max_retry_credit_card"
                      name="max_retry_credit_card"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer xs={8} item={true}>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Tax for Credit Card payments (in %)
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "number",
                        endAdornment: errorsForm2.tax_credit_card ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }}
                      inputRef={registerForm2}
                      error={!!errorsForm2.tax_credit_card}
                      helperText={
                        errorsForm2.tax_credit_card
                          ? errorsForm2.tax_credit_card.message
                          : ""
                      }
                      id="tax_credit_card"
                      name="tax_credit_card"
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button color="rose" type="submit">
                Save Your Changes
              </Button>
            </CardFooter>
          </form>
        </Card>
      </GridContainer>
    </div>
  );
}
