import React, { useEffect, useState } from "react";

// core componentes
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import CheckCircle from "@material-ui/icons/CheckCircle";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import Stop from "@material-ui/icons/RemoveCircle";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import SearchIcon from "@material-ui/icons/Search";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import Create from "@material-ui/icons/Create";

import Chip from "@material-ui/core/Chip";

// @material-ui/lab
import Autocomplete from "@material-ui/lab/Autocomplete";

import MUIDataTable from "mui-datatables";

import { useQuery } from "react-query";
import api from "services/api";

import { DateRangeDelimiter, DateRangePicker } from "@material-ui/pickers";
import DateFnsAdapter from "@material-ui/pickers/adapter/date-fns";
import enGBLocale from "date-fns/locale/en-GB";
import { format, subMonths } from "date-fns";

import { formatDate, formatNumber } from "utils/formats";
import {
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from "@material-ui/core";
import InvoiceModal from "./InvoiceModal";

const staticDateAdapter = new DateFnsAdapter({ locale: enGBLocale });

const styles = {
  filters: {
    display: "flex",
    flex: 1
  },
  status: {
    display: "flex",
    alignItems: "center"
  },
  statusPaid: {
    color: "#9E9D24"
  },
  statusPending: {
    color: "#EF6C00"
  }
};

const myTheme = createTheme({
  overrides: {
    MUIDataTable: {
      paper: {
        height: "inherit"
      },
      responsiveScroll: {
        maxHeight: "none",
        height: "calc(100% - 128px)"
      }
    },
    MuiDataTableBodyCell: {
      "12-0": {
        display: "flex",
        alignItems: "center"
      }
    },
    MUIDataTableHeadCell: {
      root: {
        padding: "7px 16px 7px 16px"
      }
    }
  }
});

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "860px"
  },
  invoiceContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > button": {
      marginTop: "20px"
    },
    padding: "51px 64px 64px 64px"
  },
  invoiceHeader: {
    display: "flex",
    justifyContent: "center"
  },
  invoiceSubHeader: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "& label": {
      color: "rgba(0, 0, 0, 0.87)",
      lineHeight: "23.24px",
      fontSize: "14px",
      fontWeight: "500",
      marginRight: "1.79px"
    }
    // "& span": {
    //   color: "rgba(0, 0, 0, 0.87)",
    //   lineHeight: "23.24px",
    //   fontSize: "14px",
    //   fontWeight: "700"
    // }
  },
  subTitle: {
    margin: "0",
    lineHeight: "32px",
    fontSize: "20px",
    height: "37px"
  },
  invoiceClientSection: {
    marginTop: "44px",
    width: "100%",
    "& > div": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      fontSize: "10px",

      "& > div > label": {
        display: "inline-block",
        width: "150px",
        color: "#000000",
        fontSize: "10px",
        lineHeight: "16px",
        fontWeight: "500"
      },
      "& > div > span": {
        fontWeight: "400"
      }
    }
  },
  invoiceDateSection: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "27px",
    "& label": {
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "23.24px",
      color: "rgba(0, 0, 0, 0.87)",
      marginRight: "11.75px"
    },
    "& span": {
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: "14px",
      fontWeight: "400"
    }
  },
  invoiceBottomSection: {
    width: "100%",
    marginTop: "27px",
    display: "flex",
    justifyContent: "flex-end",
    "& button + button": {
      marginLeft: "11px"
    }
  },
  gridFilterContainer: {
    marginBottom: "15px"
  },
  tableGridContainer: {
    marginTop: "15px"
  },
  invoiceOrderSummarySection: {
    marginTop: "27px",
    width: "100%",
    "& .MuiTableCell-root": {
      fontSize: "10px",
      height: "36.54px",
      padding: "0px"
    },
    "& .MuiTableCell-head": {
      fontSize: "10px"
    }
  },
  orderSummaryServiceRow: {
    background: "rgba(63, 81, 181, 0.08)"
  },
  colSpanCell: {
    textAlign: "right",
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "700"
  },
  cellTotal: {
    textAlign: "right"
  },
  datePicker: {
    border: "1px solid pink"
  }
  // dateFrom: {
  //   "& fieldset": {
  //     borderRight: "none",
  //     borderTopRightRadius: "0px",
  //     borderBottomRightRadius: "0px"
  //   }
  // },
  // delimiter: {
  //   border: "1px solid red",
  //   display: "flex"
  // }
}));

const fetchInvoices = async ({ queryKey }) => {
  const [
    _key,
    { page, perPage, advertiserSelected, serviceSelected, statusSelected }
  ] = queryKey;
  let statusInvoice = null;

  switch (statusSelected) {
    case "Paid":
      statusInvoice = 2;
      break;
    case "Pending":
      statusInvoice = 1;
      break;
    default:
      statusInvoice = null;
      break;
  }
  const response = await api.get("/invoices", {
    baseURL: "https://api.kau.agency",
    params: {
      sort: "desc",
      page: page + 1,
      perPage,
      advertiser_name: advertiserSelected?.advertiser_name,
      service_name: serviceSelected?.name,
      invoice_status: statusInvoice
    }
  });

  const resultParsed = response.data.data.filter(item => item.cycle !== null);
  let parsedData = resultParsed.map(item => {
    // console.log(item);
    return {
      cycle_id: item.cycle.id,
      advertiser_name: item.cycle.account.advertiser.advertiser_name,
      service: item.cycle.cycle_service.service,
      id: item.id,
      status: item.status,
      date_of_paid: item.status === 1 ? "--" : formatDate(item.date_of_paid),
      live_date: item.date_of_invoice,
      setup_fee: item.cycle.cycle_service.setup_fee,
      mnmg_fee: item.cycle.cycle_service.mnmg_fee,
      budget: item.cycle.cycle_service.budget,
      advertiser_email: item.cycle.account.advertiser.email
    };
  });
  // setData(parsedData);
  // setIsLoading(false);
  return {
    ...response.data,
    data: parsedData
  };
};

const fetchClients = async () => {
  const response = await api.get("advertisers", {
    params: {
      fields: "advertiser_name"
    }
  });

  return response.data;
};

const fetchServices = async () => {
  const response = await api.get("services", {
    params: {
      fields: "name"
    }
  });
  return response.data;
};

let render = 0;
function InvoicesPage() {
  const [page, setPage] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);

  const { data: clients } = useQuery("clients", fetchClients);
  const { data: services } = useQuery("services", fetchServices);

  const [isLoading, setIsLoading] = React.useState(false);

  const [advertiserSelected, setAdvertiserSelected] = useState(null);
  const [serviceSelected, setServiceSelected] = useState(null);
  const [statusSelected, setStatusSelected] = useState(null);

  const [date, setDate] = React.useState([
    new Date(new Date().setMonth(new Date().getMonth() - 10)),
    new Date()
  ]);

  const [invoiceSelectedRow, setInvoiceSelectedRow] = useState(null);

  const [isInvoicePreviewModalOpen, setIsInvoicePreviewModalOpen] = useState(
    false
  );

  const [selectedDate, setSelectedDate] = React.useState(
    format(subMonths(new Date(), 2), `yyyy-MM-dd`)
  );

  const { data, status, error, isFetching } = useQuery(
    [
      "invoices",
      { page, perPage, advertiserSelected, serviceSelected, statusSelected }
    ],
    fetchInvoices,
    { keepPreviousData: true }
  );

  const classes = useStyles();

  const columns = [
    {
      name: "id",
      label: "Invoice No"
    },
    {
      name: "cycle_id",
      label: "Cycle ID",
      sortDirection: "desc"
    },
    {
      name: "advertiser_name",
      label: "Client"
    },
    {
      name: "service",
      label: "Service"
    },
    {
      name: "live_date",
      label: "Invoice Date",
      options: {
        customBodyRender: value => formatDate(value)
      }
    },
    {
      name: "date_of_paid",
      label: "Paid On",
      options: {
        customBodyRender: value => value
      }
    },
    {
      name: "live_date",
      label: "Est. Live Date",
      options: {
        customBodyRender: value => formatDate(value)
      }
    },
    {
      name: "live_date",
      label: "Act. Live Date",
      options: {
        customBodyRender: value => formatDate(value)
      }
    },
    {
      name: "status",
      label: "Status",
      options: {
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          /* Pending Status */
          if (value === 1)
            return (
              // <div style={styles.status}>
              //   <ErrorIcon style={styles.statusPending} />
              //   Pending
              // </div>
              <Chip
                label="Pending"
                fullWidth
                style={{
                  backgroundColor: "#FF7043",
                  color: "#fff",
                  width: "72px"
                }}
              />
            );
          else
            return (
              <Chip
                label="Paid"
                fullWidth
                style={{
                  backgroundColor: "#9CCC65",
                  color: "#fff",
                  width: "72px"
                }}
              />
            );
        }
      }
    },
    {
      name: "View / Edit",
      label: "View / Edit",
      options: {
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div>
              <RemoveRedEye style={{ fill: "#64B6F7" }} />
              <Create style={{ fill: "#64B6F7" }} />
            </div>
          );
        }
      }
    }
  ];

  const buildQueryFilter = (advertiser, service, status, date) => {
    let query = {};

    if (advertiser && advertiser.advertiser_name)
      query.advertiser = advertiser.advertiser_name;

    if (service && service.name) query.service = service.name;

    if (status === "Paid") query.status = 2;
    if (status === "Pending") query.status = 1;

    if (date) query.source_date = date;

    if (query) return query;

    return {};
  };

  const fetchData = filter => {
    setIsLoading(true);
    api
      .get("/invoices", {
        params: {
          sort: "desc",
          ...filter
        }
      })
      .then(response => {
        let parsedData = response.data.map(item => {
          return {
            cycle_id: item.cycle.id,
            advertiser_name: item.cycle.account.advertiser.advertiser_name
          };
        });
        // setData(parsedData);
        setIsLoading(false);
      });
  };

  const handleChangeAdvertiser = (event, value) => {
    setAdvertiserSelected(value);
    // const filter = buildQueryFilter(
    //   value,
    //   serviceSelected,
    //   statusSelected,
    //   selectedDate
    // );
    // fetchData(filter);
  };

  const handleChangeService = (event, value) => {
    setServiceSelected(value);
    // const filter = buildQueryFilter(
    //   advertiserSelected,
    //   value,
    //   statusSelected,
    //   selectedDate
    // );
    // fetchData(filter);
  };

  const handleChangeStatus = (event, value) => {
    setStatusSelected(value);
    // const filter = buildQueryFilter(advertiserSelected, serviceSelected, value);
    // fetchData(filter);
  };

  const handleDateChange = date => {
    const formatedDate = format(date, `yyyy-MM-dd`);
    setSelectedDate(formatedDate);
    const filter = buildQueryFilter(
      advertiserSelected,
      serviceSelected,
      statusSelected,
      formatedDate
    );
    fetchData(filter);
  };

  function handleOpenInvoicePreviewModal() {
    setIsInvoicePreviewModalOpen(true);
  }

  function handleCloseInvoicePreviewModal() {
    setIsInvoicePreviewModalOpen(false);
  }

  const handleRowClick = rowData => {
    setIsInvoicePreviewModalOpen(true);

    let invoiceDataToPreview = data.data.filter(
      invoice => invoice.cycle_id === rowData[1]
    )[0];

    // console.log(data);
    // console.log(invoiceDataToPreview);

    const subtotal =
      Number(invoiceDataToPreview.setup_fee) +
      Number(invoiceDataToPreview.mnmg_fee) +
      Number(invoiceDataToPreview.budget);
    // console.log(invoiceDataToPreview);
    const vat = invoiceDataToPreview.advertiser_vat ? subtotal * 0.2 : 0;
    const total = subtotal + vat;

    invoiceDataToPreview = {
      ...invoiceDataToPreview,
      subtotal,
      vat,
      total
    };

    setInvoiceSelectedRow(invoiceDataToPreview);
  };

  const handleRowsPerPageChange = value => {
    console.log(value);
    setPerPage(value);
  };

  const handleChangePage = () => {
    setPage(current => current + 1);
  };

  const handleTableChange = (action, tableState) => {
    if (action === "changePage") {
      console.log(tableState);
      setPage(tableState.page);
    }
  };

  const options = {
    filter: false,
    filterType: "multisselect",
    responsive: "scrollFullHeight",
    selectableRows: "none",
    rowsPerPage: perPage,
    rowsPerPageOptions: [10, 20, 30, 50, 100, 200, 500],
    serverSide: true,
    onChangeRowsPerPage: handleRowsPerPageChange,
    // onChangePage: handleChangePage,
    onRowClick: handleRowClick,
    onTableChange: handleTableChange,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    count: data?.total || 0,
    page
  };

  // useEffect(() => {
  //   api
  //     .get("advertisers?fields=advertiser_name")
  //     .then(({ data }) => setAdvertiserList(data));
  // }, []);

  // useEffect(() => {
  //   api.get("services?fields=name").then(({ data }) => setServiceList(data));
  // }, []);

  return (
    // <div className="animated fadeInRight">
    <div>
      <Grid container>
        {/* CyclePage render: {++render} */}
        <Grid container spacing={4} className={classes.gridFilterContainer}>
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              variant="outlined"
              label="Quick Search by Invoice No"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item>
            {/* <LocalizationProvider dateAdapter={DateFnsAdapter}>
              <DatePicker
                disableToolbar
                variant="inline"
                inputFormat="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Select Source Date"
                value={selectedDate}
                onChange={handleDateChange}
                autoOk
                renderInput={props => <TextField {...props} />}
                allowKeyboardControl
              />
            </LocalizationProvider>
            <Tooltip title="Date of paid">
              <IconButton aria-label="info">
                <InfoIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            */}

            <DateRangePicker
              className={classes.datePicker}
              value={date}
              startText="From"
              endText="To"
              onChange={newValue => setDate(newValue)}
              renderInput={(startProps, endProps) => (
                <>
                  <TextField className={classes.dateFrom} {...startProps} />
                  <DateRangeDelimiter className={classes.delimiter}>
                    Invoice Date
                  </DateRangeDelimiter>
                  <TextField {...endProps} />
                </>
              )}
              dateAdapter={staticDateAdapter}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} className={classes.gridFilterContainer}>
          <Grid item xs={12} sm={12} md={4}>
            {/* <div style={styles.filters}> */}
            <Autocomplete
              renderInput={params => (
                <TextField
                  {...params}
                  label="Client"
                  variant="outlined"
                  fullWidth
                />
              )}
              id="advertisers"
              value={advertiserSelected}
              options={clients}
              getOptionLabel={option => option.advertiser_name}
              onChange={handleChangeAdvertiser}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Autocomplete
              renderInput={params => (
                <TextField
                  {...params}
                  label="Service"
                  variant="outlined"
                  fullWidth
                />
              )}
              id="services"
              value={serviceSelected}
              options={services}
              getOptionLabel={option => option.name}
              onChange={handleChangeService}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Autocomplete
              renderInput={params => (
                <TextField
                  {...params}
                  label="Invoice Status"
                  variant="outlined"
                  fullWidth
                />
              )}
              id="status"
              value={statusSelected}
              options={["Pending", "Paid"]}
              onChange={handleChangeStatus}
              renderOption={value => {
                if (value === "Pending")
                  return (
                    <div style={styles.status}>
                      <ErrorIcon style={styles.statusPending} />
                      Pending
                    </div>
                  );
                if (value === "Paid")
                  return (
                    <div style={styles.status}>
                      <MonetizationOnIcon style={styles.statusPaid} /> Paid
                    </div>
                  );
              }}
            />
            {/* </div> */}
          </Grid>
        </Grid>
        <Grid container className={classes.tableGridContainer}>
          <Grid item xs={12} sm={12} md={12}>
            {status === "loading" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "400px",
                  zIndex: "999"
                }}
              >
                <CircularProgress />
              </div>
            )}
            {status === "error" && <div>Error fetching data...</div>}
            {status === "success" && (
              <MuiThemeProvider theme={myTheme}>
                <>
                  <MUIDataTable
                    title={""}
                    data={data.data}
                    columns={columns}
                    options={options}
                  />
                </>
              </MuiThemeProvider>
            )}
          </Grid>
        </Grid>
      </Grid>
      {invoiceSelectedRow && (
        <InvoiceModal
          isInvoicePreviewModalOpen={isInvoicePreviewModalOpen}
          handleCloseInvoicePreviewModal={handleCloseInvoicePreviewModal}
          invoiceId={invoiceSelectedRow.id}
        />
      )}
    </div>
  );
}

export default InvoicesPage;
