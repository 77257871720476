import React, { useState, useEffect, useLayoutEffect } from "react";
import moment from "moment";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Edit from "@material-ui/icons/Edit";
import Remove from "@material-ui/icons/Remove";

import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import CheckCircle from "@material-ui/icons/CheckCircle";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import Stop from "@material-ui/icons/RemoveCircle";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorIcon from "@material-ui/icons/Error";

import MUIDataTable from "mui-datatables";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import { makeStyles } from "@material-ui/core/styles";

import api from "services/api";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "0px",
    marginBottom: "0px",
    marginLeft: "0px"
  },
  containerButton: {
    display: "flex",
    justifyContent: "flex-start"
  },
  status: {
    display: "flex",
    alignItems: "center"
  },
  statusActive: {
    color: "green"
  },
  statusComplete: {
    color: "#1565C0"
  },
  statusStopped: {
    color: "#c62828"
  },
  statusPaused: {
    color: "#c62828"
  },
  statusPaid: {
    color: "#9E9D24"
  },
  statusCancelled: {
    color: "#c62828"
  },
  statusPending: {
    color: "#EF6C00"
  },
  checkboxAllCycles: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "10px"
  },
  filters: {
    display: "flex"
  },
  stateSelectBox: {
    display: "flex",
    flexGrow: "1",
    marginLeft: "25px"
  },
  advertiserSelectBox: {
    display: "flex",
    flexGrow: "1"
  }
};

const stylesModal = theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  headGroup: {
    display: "flex",
    // flex: "1",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end"
  },
  daysBehindBox: {
    marginRight: "16px",
    fontFamily: "Roboto",
    fontWeight: "400",
    color: "rgb(170, 170, 170)",
    fontSize: "16px",
    lineHeight: "0px"
    // webkitFontSmoothing: "antialiased"
  },
  daysBehind: {
    fontSize: "17px",
    color: "red",
    fontWeight: "bold"
  }
});

const myTheme = createMuiTheme({
  overrides: {
    MUIDataTable: {
      paper: {
        height: "inherit"
      },
      responsiveScroll: {
        maxHeight: "none",
        height: "calc(100% - 128px)"
      }
    },
    MuiDataTableBodyCell: {
      "12-0": {
        display: "flex",
        alignItems: "center"
      }
    }
  }
});

const cycleSummaryTheme = createMuiTheme({
  overrides: {
    MUIDataTable: {
      paper: {
        // height: "inherit"
        height: "300px"
      },
      responsiveScroll: {
        maxHeight: "none",
        height: "calc(100% - 128px)"
      }
    },
    MuiDataTableBodyCell: {
      "12-0": {
        display: "flex",
        alignItems: "center"
      }
    }
  }
});

const cycleDailySpendTheme = createMuiTheme({
  overrides: {
    MUIDataTable: {
      paper: {
        height: "inherit",
        marginTop: "40px"
      },
      responsiveScroll: {
        maxHeight: "none",
        height: "calc(100% - 128px)"
      }
    },
    MuiDataTableBodyCell: {
      "12-0": {
        display: "flex",
        alignItems: "center"
      }
    }
  }
});

const dateFormat = "MMMM D, YYYY";
// const dateFormat = "D/MM/YYYY";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Cycles(props) {
  const [advertiserList, setAdvertiserList] = useState([]);
  const [data, setData] = useState([]);
  const [cycleSummaryData, setCycleSummaryData] = useState([]);
  const [cycleSpendsData, setCycleSpendsData] = useState([]);
  const [cycleStatusSelected, setCycleStatusSelected] = useState({
    status: "Active"
  });
  const [advertiserSelected, setAdvertiserSelected] = useState(null);
  const [isShowAllCycles, setIsShowAllCycles] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [totalBudget, setTotalBudget] = useState(0.0);
  const [totalSpend, setTotalSpend] = useState(0.0);
  const [totalRawSpend, setTotalRawSpend] = useState(0.0);
  const [open, setOpen] = useState(0.0);
  const [
    modalSelectedAdvertiserName,
    setModalSelectedAdvertiserName
  ] = useState("");
  const [daysbehind, setDaysbehind] = useState(0);
  // state = {
  //   advertiserList: [],
  //   data: [],
  //   cycleSummaryData: [],
  //   cycleSpendsData: [],
  //   cycleStatusSelected: { status: "Active" },
  //   advertiserSelected: null,
  //   isShowAllCycles: false,
  //   isLoading: false,
  //   totalBudget: 0.0,
  //   totalSpend: 0.0,
  //   totalRawSpend: 0.0,
  //   open: false,
  //   modalSelectedAdvertiserName: "",
  //   daysbehind: 0
  // };

  // componentDidMount() {
  //   this.setState({ isLoading: true });

  //   api.get("data-import/daysbehind").then(res => {
  //     console.log(res.data);
  //     this.setState({ daysbehind: res.data.days });

  //     api.get("cycles/ppc?state=Active").then(result => {
  //       const { totalBudget, totalSpend, totalRawSpend } = this.getTotals(
  //         result.data
  //       );

  //       const data = result.data.map(item => [
  //         item.cycle_id,
  //         "1",
  //         item.advertiser_name,
  //         moment(item.date_range_from).format(dateFormat),
  //         moment(item.date_range_to).format(dateFormat),
  //         this.numberFormat(item.budget),
  //         this.numberFormat(item.spend),
  //         this.numberFormat(item.real_spend),
  //         this.getPercSpent(item),
  //         this.getSpendRate(item),
  //         this.getExpCycleDaysLeft(item),
  //         this.getProjectedClientCost(item),
  //         item.state
  //       ]);
  //       this.setState({
  //         isLoading: false,
  //         data,
  //         totalSpend,
  //         totalBudget,
  //         totalRawSpend
  //       });
  //     });
  //   });
  //   // http://localhost:3000/advertisers?fields=advertiser_name
  //   api.get("advertisers?fields=advertiser_name").then(result => {
  //     // const data = result.data.map(item => item.advertiser_name);
  //     const data = result.data;
  //     // console.log(data);
  //     this.setState({ advertiserList: data });
  //   });
  // }

  useLayoutEffect(() => {
    setIsLoading(true);

    api.get("data-import/daysbehind").then(res => {
      console.log(res.data);
      // this.setState({ daysbehind: res.data.days });
      setDaysbehind(res.data.days);

      api.get("cycles/ppc?state=Active").then(result => {
        const { totalBudget, totalSpend, totalRawSpend } = getTotals(
          result.data
        );

        const data = result.data.map(item => [
          item.cycle_id,
          "1",
          item.advertiser_name,
          moment(item.date_range_from).format(dateFormat),
          moment(item.date_range_to).format(dateFormat),
          numberFormat(item.budget),
          numberFormat(item.spend),
          numberFormat(item.real_spend),
          getPercSpent(item),
          getSpendRate(item),
          getExpCycleDaysLeft(item),
          getProjectedClientCost(item),
          item.state
        ]);
        // this.setState({
        //   isLoading: false,
        //   data,
        //   totalSpend,
        //   totalBudget,
        //   totalRawSpend
        // });
        setIsLoading(false);
        setData(data);
        setTotalSpend(totalSpend);
        setTotalBudget(totalBudget);
        setTotalRawSpend(totalRawSpend);
      });
    });
    // http://localhost:3000/advertisers?fields=advertiser_name
    api.get("advertisers?fields=advertiser_name").then(result => {
      // const data = result.data.map(item => item.advertiser_name);
      const data = result.data;
      // console.log(data);
      // this.setState({ advertiserList: data });
      setAdvertiserList(data);
    });
  }, []);

  const getPercSpent = item =>
    `${
      item.days_into_cycle == 0 || item.spend == 0
        ? "-"
        : `${(
            (item.spend / (item.budget * (item.days_into_cycle / 30))) *
            100
          ).toFixed(2)} %`
    }`;

  const getSpendRate = item =>
    `${
      item.days_into_cycle == 0 || item.spend == 0
        ? "-"
        : `${(
            (item.spend / (item.budget * (item.days_into_cycle / 30))) *
            100
          ).toFixed(2)} %`
    }`;

  const getExpCycleDaysLeft = item => {
    if (item.days_into_cycle == 0 || item.spend == 0) return "-";
    else {
      const result =
        (item.budget - item.spend) / (item.spend / item.days_into_cycle) -
        daysbehind;
      if (result < 0) return "+";
      return parseInt(result);
    }
  };

  const getProjectedClientCost = item => {
    if (item.last_7_day_spend == 0) return numberFormat(0);
    const projected = item.spend + (item.last_7_day_spend / 7) * daysbehind;
    if (projected > item.budget) return numberFormat(item.budget);

    return numberFormat(projected);
  };

  const buildQueryFilter = (advertiserSelected, cycleStatusSelected) => {
    let query = [];
    console.log("advertiserSelected: ", advertiserSelected);
    console.log("cycleStatusSelected: ", cycleStatusSelected);
    if (advertiserSelected)
      query.push(`name=${advertiserSelected.advertiser_name}`);

    if (cycleStatusSelected) query.push(`state=${cycleStatusSelected.status}`);
    // else query.push(`state=Active`);

    return query.join("&");
  };

  const getTotals = data => {
    if (data.length == 0)
      return { totalBudget: 0.0, totalSpend: 0.0, totalRawSpend: 0.0 };

    const totalSpend = data
      .map(item => parseFloat(item.spend))
      .reduce((prev, next) => prev + next);

    const totalBudget = data
      .map(item => parseFloat(item.budget))
      .reduce((prev, next) => prev + next);

    const totalRawSpend = data
      .map(item => parseFloat(item.real_spend))
      .reduce((prev, next) => prev + next);

    return { totalBudget, totalSpend, totalRawSpend };
  };

  const handleClickOpen = () => {
    // this.setState({ open: true });
    setOpen(true);
  };

  const handleClose = () => {
    // this.setState({ open: false });
    setOpen(false);
  };

  const handleChangeCycleState = (event, value) => {
    if (value) {
      // const { advertiserSelected } = this.state;
      const cycleStatusSelected = value;
      // console.log(value);

      const queryFilter = buildQueryFilter(
        advertiserSelected,
        cycleStatusSelected
      );

      // this.setState({ isLoading: true, isShowAllCycles: false });
      setIsLoading(true);
      setIsShowAllCycles(false);
      console.log("queryFilter: ", queryFilter);
      api.get(`cycles/ppc?${queryFilter}`).then(result => {
        const { totalBudget, totalSpend, totalRawSpend } = getTotals(
          result.data
        );

        const data = result.data.map(item => [
          item.cycle_id,
          "1",
          item.advertiser_name,
          moment(item.date_range_from).format(dateFormat),
          moment(item.date_range_to).format(dateFormat),
          numberFormat(item.budget),
          numberFormat(item.spend),
          numberFormat(item.real_spend),
          getPercSpent(item),
          getSpendRate(item),
          getExpCycleDaysLeft(item),
          getProjectedClientCost(item),
          item.state
        ]);
        // this.setState({
        //   isLoading: false,
        //   data,
        //   cycleStatusSelected: value,
        //   totalBudget,
        //   totalSpend,
        //   totalRawSpend
        // });
        setIsLoading(false);
        setData(data);
        setCycleStatusSelected(value);
        setTotalBudget(totalBudget);
        setTotalSpend(totalSpend);
        setTotalRawSpend(totalRawSpend);
        // console.log(res.data);
      });
    }
  };

  const handleChangeAdvertiser = (event, value) => {
    if (value) {
      // const { cycleStatusSelected } = this.state;
      // console.log(value.advertiser_name);
      const advertiserSelected = value;

      const queryFilter = buildQueryFilter(
        advertiserSelected,
        cycleStatusSelected
      );

      // this.setState({ isLoading: true, isShowAllCycles: false });
      setIsLoading(true);
      setIsShowAllCycles(false);
      // console.log(queryFilter);
      api.get(`cycles/ppc?${queryFilter}`).then(result => {
        const { totalBudget, totalSpend, totalRawSpend } = getTotals(
          result.data
        );

        const data = result.data.map(item => [
          item.cycle_id,
          "1",
          item.advertiser_name,
          moment(item.date_range_from).format(dateFormat),
          moment(item.date_range_from).format(dateFormat),
          numberFormat(item.budget),
          numberFormat(item.spend),
          numberFormat(item.real_spend),
          getPercSpent(item),
          getSpendRate(item),
          getExpCycleDaysLeft(item),
          getProjectedClientCost(item),
          item.state
        ]);
        // this.setState({
        //   isLoading: false,
        //   data,
        //   advertiserSelected: value,
        //   totalBudget,
        //   totalSpend,
        //   totalRawSpend
        // });
        setIsLoading(false);
        setData(data);
        setAdvertiserSelected(value);
        setTotalBudget(totalBudget);
        setTotalSpend(totalSpend);
        setTotalRawSpend(totalRawSpend);
        // console.log(res.data);
      });
    }
  };

  const handleChangeShowAllCycles = event => {
    const { checked } = event.target;
    // console.log("checked:", checked);
    // const { cycleStatusSelected, advertiserSelected } = this.state;
    const queryFilter = buildQueryFilter(advertiserSelected, null);
    console.log(queryFilter);

    // this.setState({ isLoading: true });
    setIsLoading(true);

    api.get(`cycles/ppc?${queryFilter}`).then(result => {
      const { totalBudget, totalSpend, totalRawSpend } = getTotals(result.data);
      const data = result.data.map(item => [
        item.cycle_id,
        "1",
        item.advertiser_name,
        moment(item.date_range_from).format(dateFormat),
        moment(item.date_range_from).format(dateFormat),
        numberFormat(item.budget),
        numberFormat(item.spend),
        numberFormat(item.real_spend),
        getPercSpent(item),
        getSpendRate(item),
        getExpCycleDaysLeft(item),
        getProjectedClientCost(item),
        item.state
      ]);
      // this.setState({
      //   isLoading: false,
      //   data,
      //   cycleStatusSelected: null,
      //   isShowAllCycles: checked,
      //   totalBudget,
      //   totalSpend,
      //   totalRawSpend
      // });
      setIsLoading(false);
      setData(data);
      setCycleStatusSelected(null);
      setIsShowAllCycles(checked);
      setTotalBudget(totalBudget);
      setTotalSpend(totalSpend);
      setTotalRawSpend(totalRawSpend);
    });
  };

  const numberFormat = function(number) {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP"
    }).format(parseFloat(number));
  };

  const { classes } = props;
  const options = {
    // search: false,
    filter: false,
    // serverSideFilterList: this.state.serverSideFilterList,
    filterType: "multisselect",
    responsive: "scrollFullHeight",
    // responsive: "scrollMaxHeight",
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      const cycleId = rowData[0];
      // this.setState({ open: true, modalSelectedAdvertiserName: rowData[2] });
      setOpen(true);
      setModalSelectedAdvertiserName(rowData[2]);

      api.get(`cycles/summary/${cycleId}`).then(res => {
        const item = res.data;

        const data = [
          [
            item.service,
            numberFormat(item.setup_fee),
            numberFormat(item.mnmg_fee),
            numberFormat(item.budget),
            numberFormat(parseFloat(item.mnmg_fee) + parseFloat(item.budget)),
            numberFormat(
              (parseFloat(item.mnmg_fee) + parseFloat(item.budget)) * 0.2
            ),
            numberFormat(
              (parseFloat(item.mnmg_fee) + parseFloat(item.budget)) * 0.2 +
                parseFloat(item.budget)
            )
          ]
        ];

        // this.setState({ cycleSummaryData: data });
        setCycleSummaryData(data);
      });

      api.get(`cycles/spends/${cycleId}`).then(res => {
        const data = res.data.map(item => [
          cycleId,
          item.advertiser_name,
          moment(item.date_range_from).format(dateFormat),
          numberFormat(item.spend),
          numberFormat(item.real_spend)
        ]);

        // this.setState({ cycleSpendsData: data });
        setCycleSpendsData(data);
      });
    },
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 500]
    // serverSide: true,
    // onFilterChange: (column, filterList, type) => {
    //   console.log("onFilterChange: ", filterList);
    //   if (type === "chip") {
    //     console.log("updating filters via chip");
    //     // this.handleFilterSubmit(filterList)();
    //   }
    // },
    // customFilterDialogFooter: filterList => {
    //   return (
    //     <div style={{ marginTop: "40px" }}>
    //       <Button
    //         variant="contained"
    //         onClick={this.handleFilterSubmit(filterList)}
    //       >
    //         Apply Filters
    //       </Button>
    //       {/* <p>
    //         <em>*(Simulates selecting "Chicago" from "Location")</em>
    //       </p> */}
    //     </div>
    //   );
    // }
  };

  const optionsCycleSummary = {
    selectableRows: "none",
    pagination: false,
    filter: false,
    search: false,
    download: false
  };

  const optionsCycleDailySpend = {
    selectableRows: "none",
    // pagination: false,
    filter: false,
    search: false,
    download: false
  };

  const columns = [
    {
      name: "Cycle ID",
      options: {
        filter: true,
        filterType: "textField",
        // filterList: this.state.filters[0],
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          // console.log("valuuuee:", value);
          return value;
        }
      }
    },
    {
      name: "Cycle No.",
      options: {
        searchable: false,
        filter: false,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        }
      }
    },
    {
      name: "Name",
      options: {
        filter: true,
        searchable: true,
        // filterList: this.state.filters[2],
        // filterOptions: {
        //   names: this.state.advertiserList
        // },
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        }
      }
    },
    {
      name: "From",
      options: {
        searchable: true,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        }
      }
    },
    {
      name: "To",
      options: {
        searchable: true,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        }
      }
    },
    {
      name: "Budget",
      options: {
        searchable: false,
        filter: false,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              {numberFormat(totalBudget)}
            </th>
          );
        }
      }
    },
    {
      name: "Client Cost(£)",
      options: {
        searchable: false,
        filter: false,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              {numberFormat(totalSpend)}
            </th>
          );
        }
      }
    },
    {
      name: "Cost(£)",
      options: {
        searchable: false,
        filter: false,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              {numberFormat(totalRawSpend)}
            </th>
          );
        }
      }
    },
    {
      name: "% Spent",
      options: {
        searchable: false,
        filter: false,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        }
      }
    },
    {
      name: "Spend Rate",
      options: {
        searchable: false,
        filter: false,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        }
      }
    },
    {
      name: "Exp. Cycle Days Left",
      options: {
        searchable: false,
        filter: false,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        }
      }
    },
    {
      name: "Projected Client cost",
      options: {
        searchable: false,
        filter: false,
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        }
      }
    },
    /*
      {
        name: 'Age',

        options: {
          filter: true,
          filterType: 'custom',
          filterList: [25, 50],
          customFilterListRender: v => {
            if (v[0] && v[1]) {
              return `Min Age: ${v[0]}, Max Age: ${v[1]}`;
            } else if (v[0]) {
              return `Min Age: ${v[0]}`;
            } else if (v[1]) {
              return `Max Age: ${v[1]}`;
            }
            return false;
          },
          filterOptions: {
            names: [],
            logic(age, filters) {
              if (filters[0] && filters[1]) {
                return age < filters[0] || age > filters[1];
              } else if (filters[0]) {
                return age < filters[0];
              } else if (filters[1]) {
                return age > filters[1];
              }
              return false;
            },
            display: (filterList, onChange, index, column) => (
              <div>
                <FormLabel>Age</FormLabel>
                <FormGroup row>
                  <TextField
                    label="min"
                    value={filterList[index][0] || ''}
                    onChange={event => {
                      filterList[index][0] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%', marginRight: '5%' }}
                  />
                  <TextField
                    label="max"
                    value={filterList[index][1] || ''}
                    onChange={event => {
                      filterList[index][1] = event.target.value;
                      onChange(filterList[index], index, column);
                    }}
                    style={{ width: '45%' }}
                  />
                </FormGroup>
              </div>
            ),
          },
          print: true,
        },
       options: {
        filter: true,
        filterType: 'checkbox',
        filterOptions: {
          names: ['Lower wages', 'Average wages', 'Higher wages'],
          logic(salary, filterVal) {
            salary = salary.replace(/[^\d]/g, '');
            const show =
              (filterVal.indexOf('Lower wages') >= 0 && salary < 100000) ||
              (filterVal.indexOf('Average wages') >= 0 && salary >= 100000 && salary < 200000) ||
              (filterVal.indexOf('Higher wages') >= 0 && salary >= 200000);
            return !show;
          },
        },
        sort: false,
      },

      },
      
      {
        name: "Actions",
        options: {
          print: false,
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Button
                  // justIcon
                  simple
                  color="info"
                  // size="lg"
                  onClick={() => this.setState({ open: true })}
                >
                  <Edit />
                </Button>
                <Button
                  justIcon
                  round
                  color="danger"
                  // size="lg"
                  onClick={() => this.setState({ open: true })}
                >
                  <Remove />
                </Button>
              </>
            );
          }
        }
      }*/
    {
      name: "Status",
      options: {
        searchable: false,
        filter: true,
        // filterList: this.state.filters[12][0],
        // filterType: "custom",
        // filterList: ["Active"],
        //   "Pending",
        //   "Complete",
        //   "Paused",
        //   "Cancelled",
        //   "Stopped",
        //   "Failed"
        // ],
        // customFilterListOptions: {
        //   render: v => {
        //     console.log(v);
        //     return `Status: ${v}`;
        //   }
        // },
        customFilterListOptions: { render: v => `Status: ${v}` },
        // filterOptions: {
        //   names: ["Active", "Pending", "Complete", "Paused"]
        // },
        customHeadRender: (columnMeta, handleToggleColumn) => {
          // console.log("customHeadRender: ", columnMeta);
          // columnMeta.label = `Cycle ID Total`;
          // return columnMeta;
          return (
            <th
              key={columnMeta.name}
              className="MuiTableCell-root MuiTableCell-head"
            >
              {columnMeta.name}
              <br />
              <br />
              &nbsp;
            </th>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === "Active")
            return (
              <div style={styles.status}>
                <OfflineBoltIcon style={styles.statusActive} /> Active
              </div>
            );

          if (value === "Complete")
            return (
              <div style={styles.status}>
                <CheckCircle style={styles.statusComplete} /> Complete
              </div>
            );

          if (value === "Stopped")
            return (
              <div style={styles.status}>
                <Stop style={styles.statusStopped} /> Stopped
              </div>
            );
          if (value === "Paused")
            return (
              <div style={styles.status}>
                <PauseCircleFilledIcon style={styles.statusPaused} /> Paused
              </div>
            );
          if (value === "Paid")
            return (
              <div style={styles.status}>
                <MonetizationOnIcon style={styles.statusPaid} /> Paid
              </div>
            );
          if (value === "Cancelled")
            return (
              <div style={styles.status}>
                <CancelIcon style={styles.statusCancelled} />
                Cancelled
              </div>
            );
          if (value === "Pending")
            return (
              <div style={styles.status}>
                <ErrorIcon style={styles.statusPending} />
                Pending
              </div>
            );
        }
      }
    }
  ];

  const cycleSummaryColumns = [
    {
      name: "Product/Service"
    },
    {
      name: "Setup Fee"
    },
    {
      name: "Tracking & Tech Fee"
    },
    {
      name: "Budget"
    },
    {
      name: "Total Exc. VAT"
    },
    {
      name: "VAT"
    },
    {
      name: "Total"
    }
  ];

  const dailySpendColumns = [
    {
      name: "Cycle ID"
    },
    {
      name: "Name"
    },
    {
      name: "Date"
    },
    {
      name: "Client Cost"
    },
    {
      name: "Cost"
    }
  ];

  return (
    <>
      <div className={classes.headGroup}>
        <div className={classes.daysBehindBox}>
          <span className={classes.daysBehind}>{daysbehind}</span> Days Behind
        </div>
        <div style={styles.checkboxAllCycles}>
          <FormControl component="fieldset">
            <FormLabel component="legend"></FormLabel>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                checked={isShowAllCycles}
                control={<Checkbox color="primary" />}
                label="Show all cycles"
                labelPlacement="end"
                onChange={handleChangeShowAllCycles}
              />
            </FormGroup>
          </FormControl>
        </div>
      </div>
      <div style={styles.filters}>
        <Autocomplete
          style={styles.advertiserSelectBox}
          id="combo-box-demo"
          options={advertiserList}
          getOptionLabel={option => option.advertiser_name}
          // style={{ width: 600 }}
          renderInput={params => (
            <TextField
              {...params}
              label="Advertiser"
              variant="outlined"
              // variant="filled"
              fullWidth
            />
          )}
          onChange={handleChangeAdvertiser}
        />
        <Autocomplete
          style={styles.stateSelectBox}
          value={cycleStatusSelected}
          id="combo-box-demo2"
          options={[
            { status: "Pending" },
            { status: "Paid" },
            { status: "Active" },
            { status: "Paused" },
            { status: "Complete" },
            { status: "Cancelled" },
            { status: "Stopped" }
            // { status: "Failed" }
          ]}
          getOptionLabel={option => option.status}
          renderOption={({ status: value }) => {
            // console.log("value: ", value);
            if (value === "Active")
              return (
                <div style={styles.status}>
                  <OfflineBoltIcon style={styles.statusActive} /> Active
                </div>
              );

            if (value === "Complete")
              return (
                <div style={styles.status}>
                  <CheckCircle style={styles.statusComplete} /> Complete
                </div>
              );

            if (value === "Stopped")
              return (
                <div style={styles.status}>
                  <Stop style={styles.statusStopped} /> Stopped
                </div>
              );
            if (value === "Paused")
              return (
                <div style={styles.status}>
                  <PauseCircleFilledIcon style={styles.statusPaused} /> Paused
                </div>
              );
            if (value === "Paid")
              return (
                <div style={styles.status}>
                  <MonetizationOnIcon style={styles.statusPaid} /> Paid
                </div>
              );
            if (value === "Cancelled")
              return (
                <div style={styles.status}>
                  <CancelIcon style={styles.statusCancelled} />
                  Cancelled
                </div>
              );
            if (value === "Pending")
              return (
                <div style={styles.status}>
                  <ErrorIcon style={styles.statusPending} />
                  Pending
                </div>
              );
          }}
          // style={{ width: 600 }}
          renderInput={params => (
            <TextField
              {...params}
              label="Cycle Status"
              variant="outlined"
              // variant="filled"
              fullWidth
            />
            // value={this.setState({ state: })}
          )}
          onChange={handleChangeCycleState}
        />
      </div>
      <br />
      <ThemeProvider theme={myTheme}>
        <>
          {isLoading && (
            <div style={{ position: "absolute", top: "50%", left: "50%" }}>
              <CircularProgress />
            </div>
          )}
          <MUIDataTable
            title={""}
            data={data}
            columns={columns}
            options={options}
          />
        </>
      </ThemeProvider>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {modalSelectedAdvertiserName}
            </Typography>
          </Toolbar>
        </AppBar>
        <ThemeProvider theme={cycleSummaryTheme}>
          <MUIDataTable
            title={"Cycle Summary"}
            data={cycleSummaryData}
            columns={cycleSummaryColumns}
            options={optionsCycleSummary}
          />
        </ThemeProvider>
        <ThemeProvider theme={cycleDailySpendTheme}>
          <MUIDataTable
            title={"Daily Spend"}
            data={cycleSpendsData}
            columns={dailySpendColumns}
            options={optionsCycleDailySpend}
          />
        </ThemeProvider>
      </Dialog>
    </>
  );
}

export default withStyles(stylesModal)(Cycles);
