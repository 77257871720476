import React from "react";

import { Paper, Grid, TextField, makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

// import { Container } from './styles';

const useStyles = makeStyles(theme => ({
  paperContainer: {
    padding: theme.spacing(4)
  },
  paperFormContainer: {
    width: "100%",
    padding: theme.spacing(4)
  }
}));

function EditCycles() {
  const classes = useStyles();

  return (
    <>
      <Paper variant="outlined" className={classes.paperContainer}>
        <form>
          <Grid container justifyContent="center" alignItems="center">
            <Paper
              variant="outlined"
              className={classes.paperFormContainer}
              md={12}
            >
              <Grid container>
                <Grid item md={3}>
                  <Autocomplete
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select a Advertiser"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    id="advertiserList"
                    options={["All", "Mhayk Corp"]}
                  />
                </Grid>
                <Grid item md={3}>
                  <Autocomplete
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Select a Service"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    id="advertiserList"
                    options={["PPC", "Website Management"]}
                  />
                </Grid>
                <Grid item md={3}>
                  <Autocomplete
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Cycle Status"
                        variant="outlined"
                        size="small"
                      />
                    )}
                    id="advertiserList"
                    options={["Pending", "Paid", "Active"]}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </form>
      </Paper>
    </>
  );
}

export default EditCycles;
