import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Chip from "@material-ui/core/Chip";
import CloseIcon from "@material-ui/icons/Close";

import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@material-ui/core";

import CurrencyTextField from "@unicef/material-ui-currency-textfield";

import { formatDate, formatNumber } from "utils/formats";
import api from "services/api";
import { useQuery } from "react-query";

import "./styles.scss";

const useStyles = makeStyles(theme => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: "860px"
    },
    invoiceContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& > button": {
            marginTop: "20px"
        },
        padding: "51px 64px 64px 64px",
        position: "relative"
    },
    invoiceHeader: {
        display: "flex",
        justifyContent: "center"
    },
    invoiceSubHeader: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        "& label": {
            color: "rgba(0, 0, 0, 0.87)",
            lineHeight: "23.24px",
            fontSize: "14px",
            fontWeight: "500",
            marginRight: "1.79px"
        }
        // "& span": {
        //   color: "rgba(0, 0, 0, 0.87)",
        //   lineHeight: "23.24px",
        //   fontSize: "14px",
        //   fontWeight: "700"
        // }
    },
    subTitle: {
        margin: "0",
        lineHeight: "32px",
        fontSize: "20px",
        height: "37px"
    },
    invoiceClientSectionTable: {
        "&": {
            width: "100%",
        },
        "& > div": {
            display: "grid",
            gridTemplateColumns: "100px 1fr 100px 1fr",
            fontSize: "10px",

            "& label": {
                display: "inline-block",
                color: "#000000",
                fontSize: "10px",
                lineHeight: "16px",
                fontWeight: "500"
            },
            "& span": {
                fontWeight: "400"
            }
        }
    },
    invoiceClientSection: {
        marginTop: "44px",
        width: "100%",
        "& > div": {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            fontSize: "10px",

            "& > div > label": {
                display: "inline-block",
                width: "100px",
                color: "#000000",
                fontSize: "10px",
                lineHeight: "16px",
                fontWeight: "500"
            },
            "& > div > span": {
                fontWeight: "400"
            }
        }
    },
    invoiceDateSection: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: "27px",
        "& label": {
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "23.24px",
            color: "rgba(0, 0, 0, 0.87)",
            marginRight: "11.75px"
        },
        "& span": {
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "14px",
            fontWeight: "400"
        }
    },
    invoiceBottomSection: {
        width: "100%",
        marginTop: "27px",
        display: "flex",
        justifyContent: "flex-end",
        "& button + button": {
            marginLeft: "11px"
        }
    },
    gridFilterContainer: {
        marginBottom: "15px"
    },
    tableGridContainer: {
        marginTop: "15px"
    },
    invoiceOrderSummarySection: {
        marginTop: "27px",
        width: "100%",
        "& .MuiTableCell-root": {
            fontSize: "10px",
            height: "43px",
            padding: "0px"
        },
        "& .MuiTableCell-head": {
            fontSize: "10px"
        }
    },
    orderSummaryServiceRow: {
        background: "rgba(63, 81, 181, 0.08)"
    },
    colSpanCell: {
        textAlign: "right",
        color: "rgba(0, 0, 0, 0.87)",
        fontWeight: "700"
    },
    cellTotal: {
        textAlign: "right"
    },
    datePicker: {
        border: "1px solid pink"
    },
    currencyTextField: {}
}));

const fetchInvoice = async (id, ...rest) => {
    // console.log(id, rest);
    const { data } = await api.get(`/invoices/${id}`, {
        baseURL: "https://api.kau.agency"
    });

    // console.log(data);

    const subtotal =
        Number(data.cycle.cycle_service.setup_fee) +
        Number(data.cycle.cycle_service.mnmg_fee) +
        Number(data.cycle.cycle_service.budget);
    // console.log(invoiceDataToPreview);
    const vat = data.cycle.account.advertiser.advertiser_vat ? subtotal * 0.2 : 0;
    const total = subtotal + vat;

    return {
        ...data,
        subtotal,
        vat,
        total
    };
};

function InvoiceModal({
    isInvoicePreviewModalOpen,
    handleCloseInvoicePreviewModal,
    invoiceId
}) {
    const classes = useStyles();

    const [isEditModeOn, setIsEditeModeOn] = useState(false);
    const {
        data: invoiceSelectedRow,
        isSuccess,
        isLoading,
        status,
        error,
        isFe
    } = useQuery(["invoice", invoiceId], () => fetchInvoice(invoiceId));

    console.log(invoiceSelectedRow);

    const handleEditMode = () => {
        setIsEditeModeOn(!isEditModeOn);
    };

    return (
        <>
            {isLoading && <div id="cover-spin" />}

            {isSuccess && (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={isInvoicePreviewModalOpen}
                    onClose={handleCloseInvoicePreviewModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500
                    }}
                >
                    <Fade in={isInvoicePreviewModalOpen}>
                        <div className={classes.paper}>
                            {/* <h2 id="transition-modal-title">Transition modal</h2>
        <p id="transition-modal-description">
          react-transition-group animates me.
        </p> */}
                            {invoiceSelectedRow && (
                                <main className={classes.invoiceContainer}>
                                    <div>
                                        <IconButton
                                            onClick={handleCloseInvoicePreviewModal}
                                            style={{ position: "absolute", top: "0px", right: "0px" }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </div>
                                    <div className={classes.invoiceSubHeader}>
                                        <div>
                                            <label>Invoice #:</label>
                                            <span style={{ fontWeight: 700 }}>
                                                {invoiceSelectedRow.id}
                                            </span>
                                        </div>
                                        <div>
                                            <label>Payment status:</label>{" "}
                                            {invoiceSelectedRow.status === 1 ? (
                                                <Chip
                                                    label="Pending"
                                                    fullWidth
                                                    style={{
                                                        backgroundColor: "#FF7043",
                                                        color: "#fff",
                                                        width: "72px"
                                                    }}
                                                />
                                            ) : (
                                                <Chip
                                                    label="Paid"
                                                    fullWidth
                                                    style={{
                                                        backgroundColor: "#9CCC65",
                                                        color: "#fff !important",
                                                        width: "72px"
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {/* <section className={classes.invoiceClientSection}>
                                        <h3 className={classes.subTitle}>Client Information</h3>
                                        <div>
                                            <div>
                                                <label>Business Name</label>
                                                <span>{invoiceSelectedRow.cycle.account.advertiser.advertiser_name}</span>
                                            </div>
                                            <div>
                                                <label>Landline Phone</label>
                                                <span>{invoiceSelectedRow.cycle.account.advertiser.phone_1}</span>
                                            </div>
                                            <div>
                                                <label>Email</label>
                                                <span>{invoiceSelectedRow.cycle.account.advertiser.email}</span>
                                            </div>
                                            <div>
                                                <label>URL</label>
                                                <span>
                                                    {invoiceSelectedRow.cycle.account.advertiser.company_website}
                                                </span>
                                            </div>
                                            <div>
                                                <label>Address</label>
                                                <span>{invoiceSelectedRow.cycle.account.advertiser.address_1}</span>
                                            </div>
                                            <div>
                                                <label>City</label>
                                                <span>{invoiceSelectedRow.cycle.account.advertiser.city}</span>
                                            </div>
                                            <div>
                                                <label>Country</label>
                                                <span>{invoiceSelectedRow.cycle.account.advertiser.country}</span>
                                            </div>
                                            <div>
                                                <label>Postcode</label>
                                                <span>{invoiceSelectedRow.cycle.account.advertiser.postal_code}</span>
                                            </div>
                                        </div>
                                    </section> */}

                                    <section className={classes.invoiceClientSectionTable}>
                                        <h3 className={classes.subTitle}>Client Information</h3>
                                        <div>
                                            {/* row 1  */}
                                            <label>Business Name</label>
                                            <span>{invoiceSelectedRow.cycle.account.advertiser.advertiser_name}</span>
                                            <label>Landline Phone</label>
                                            <span>{invoiceSelectedRow.cycle.account.advertiser.phone_1}</span>
                                            {/* row 2  */}
                                            <label>Email</label>
                                            <span>{invoiceSelectedRow.cycle.account.advertiser.email}</span>
                                            <label>URL</label>
                                            <span>
                                                {invoiceSelectedRow.cycle.account.advertiser.company_website}
                                            </span>
                                            {/* row 3 */}
                                            <label>Address</label>
                                            <span>{invoiceSelectedRow.cycle.account.advertiser.address_1}</span>
                                            <label>City</label>
                                            <span>{invoiceSelectedRow.cycle.account.advertiser.city}</span>
                                            {/* row 4 */}
                                            <label>Country</label>
                                            <span>{invoiceSelectedRow.cycle.account.advertiser.country}</span>
                                            <label>Postcode</label>
                                            <span>{invoiceSelectedRow.cycle.account.advertiser.postal_code}</span>

                                        </div>
                                    </section>

                                    <section className={classes.invoiceOrderSummarySection}>
                                        <h3 className={classes.subTitle}>Order Summary</h3>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Product/Service</TableCell>
                                                    <TableCell>Setup Fee</TableCell>
                                                    <TableCell>Tracking&Tech Fee</TableCell>
                                                    <TableCell>
                                                        Media budget, Management & Optimisation
                                                    </TableCell>
                                                    <TableCell className={classes.cellTotal}>
                                                        Line Total
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow className={classes.orderSummaryServiceRow}>
                                                    <TableCell>
                                                        {invoiceSelectedRow.cycle.cycle_service.service}
                                                    </TableCell>
                                                    <TableCell>
                                                        {isEditModeOn ? (
                                                            <CurrencyTextField
                                                                variant="outlined"
                                                                size="small"
                                                                value={
                                                                    invoiceSelectedRow.cycle.cycle_service
                                                                        .setup_fee
                                                                }
                                                                currencySymbol="£"
                                                                //minimumValue="0"
                                                                outputFormat="string"
                                                                decimalCharacter="."
                                                                digitGroupSeparator=","
                                                            //  onChange={(event, value)=> setValue(value)}
                                                            />
                                                        ) : (
                                                            // <input
                                                            //     value={
                                                            //         invoiceSelectedRow.cycle.cycle_service
                                                            //             .setup_fee
                                                            //     }
                                                            // />
                                                            formatNumber(
                                                                invoiceSelectedRow.cycle.cycle_service.setup_fee
                                                            )
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {isEditModeOn ? (
                                                            <CurrencyTextField
                                                                variant="outlined"
                                                                size="small"
                                                                value={
                                                                    invoiceSelectedRow.cycle.cycle_service
                                                                        .mnmg_fee
                                                                }
                                                                currencySymbol="£"
                                                                //minimumValue="0"
                                                                outputFormat="string"
                                                                decimalCharacter="."
                                                                digitGroupSeparator=","
                                                            //  onChange={(event, value)=> setValue(value)}
                                                            />
                                                        ) : (
                                                            formatNumber(
                                                                invoiceSelectedRow.cycle.cycle_service.mnmg_fee
                                                            )
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {isEditModeOn ? (
                                                            <CurrencyTextField
                                                                className={classes.currencyTextField}
                                                                variant="outlined"
                                                                size="small"
                                                                value={
                                                                    invoiceSelectedRow.cycle.cycle_service.budget
                                                                }
                                                                currencySymbol="£"
                                                                //minimumValue="0"
                                                                outputFormat="string"
                                                                decimalCharacter="."
                                                                digitGroupSeparator=","
                                                            //  onChange={(event, value)=> setValue(value)}
                                                            />
                                                        ) : (
                                                            formatNumber(
                                                                Number(
                                                                    invoiceSelectedRow.cycle.cycle_service.budget
                                                                )
                                                            )
                                                        )}
                                                    </TableCell>
                                                    <TableCell className={classes.cellTotal}>
                                                        {formatNumber(Number(invoiceSelectedRow.subtotal))}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        className={classes.colSpanCell}
                                                        colSpan={4}
                                                    >
                                                        SubTotal
                                                    </TableCell>
                                                    <TableCell className={classes.cellTotal}>
                                                        {formatNumber(Number(invoiceSelectedRow.subtotal))}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        className={classes.colSpanCell}
                                                        colSpan={4}
                                                    >
                                                        VAT
                                                    </TableCell>
                                                    <TableCell className={classes.cellTotal}>
                                                        {formatNumber(Number(invoiceSelectedRow.vat))}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell
                                                        className={classes.colSpanCell}
                                                        colSpan={4}
                                                    >
                                                        Total
                                                    </TableCell>
                                                    <TableCell className={classes.cellTotal}>
                                                        {formatNumber(Number(invoiceSelectedRow.total))}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </section>

                                    <section className={classes.invoiceDateSection}>
                                        <div>
                                            {isEditModeOn ? (
                                                <TextField
                                                    id="date"
                                                    label="Invoice Date"
                                                    type="date"
                                                    defaultValue="2021-08-10"
                                                    className={classes.textField}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            ) : (
                                                <>
                                                    <label htmlFor="">Invoice Date:</label>
                                                    <span>10/08/21</span>
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            {isEditModeOn ? (
                                                <TextField
                                                    label="Due Date"
                                                    type="date"
                                                    defaultValue="2021-08-31"
                                                    className={classes.textField}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            ) : (
                                                <>
                                                    <label htmlFor="">Due Date:</label>
                                                    <span>30/08/21</span>
                                                </>
                                            )}
                                        </div>
                                        <div>
                                            {isEditModeOn ? (
                                                <TextField
                                                    label="Date of Paid"
                                                    type="date"
                                                    defaultValue="2021-08-30"
                                                    className={classes.textField}
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                />
                                            ) : (
                                                <>
                                                    <label>Date of Paid:</label>
                                                    <span>30/08/21</span>
                                                </>
                                            )}
                                        </div>
                                    </section>

                                    <bottom className={classes.invoiceBottomSection}>
                                        {isEditModeOn ? (
                                            <Button
                                                onClick={handleEditMode}
                                                variant="contained"
                                                color="success"
                                            >
                                                SAVE
                                            </Button>
                                        ) : (
                                            <Button
                                                onClick={handleEditMode}
                                                variant="contained"
                                                color="secondary"
                                            >
                                                EDIT
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                window.location.href = `https://admin.kaumediagroup.com/auth/invoice/generate/${invoiceSelectedRow.id}`;
                                            }}
                                        >
                                            Download
                                        </Button>
                                    </bottom>
                                </main>
                            )}
                        </div>
                    </Fade>
                </Modal>
            )}
        </>
    );
}

export default InvoiceModal;
