/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
// import { createBrowserHistory } from "history";
// import { Router, Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import ReduxToastr from "react-redux-toastr";
// import store from "./store/configureStore";
import store from "./store";
import Routes from "./routes";
import ProtectedRoute from "components/Admin/ProtectedRoute";

import { ThemeProvider } from "@material-ui/styles";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import theme from "themes/theme";
// import LoginPage from "components/Admin/Auth/LoginPage";
// import LogoutPage from "components/Admin/Auth/LogoutPage";
// import { ConnectedRouter } from 'connected-react-router';
// // import { loadUser } from "./actions/authActions";

// // import AuthLayout from "layouts/Auth.js";
// // import RtlLayout from "layouts/RTL.js";
// import AdminLayout from "layouts/Admin.js";

// import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

// const hist = createBrowserHistory();

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Routes />
        <ReduxToastr />
      </Provider>
    </ThemeProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);

ReactDOM.render(<App />, document.getElementById("root"));
