import React from "react";
import PropTypes from "prop-types";
import { useSpring, animated } from "react-spring/web.cjs";
import Chip from "@material-ui/core/Chip";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Heading from "components/Heading/Heading.js";
import Table from "components/Table/Table.js";
import Backdrop from "@material-ui/core/Backdrop";

import Modal from "@material-ui/core/Modal";

import { CardHeader } from "@material-ui/core";

import api from "services/api";

import format from "date-fns/format";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";

import DateFnsUtils from "@date-io/date-fns";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll"
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    }
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func
};

export default function SalesDashboardReport() {
  const [selectedDate, setSelectedDate] = React.useState(new Date("2020-02"));
  const [dateFrom, setDateFrom] = React.useState("00/00/0000");
  const [dateTo, setDateTo] = React.useState("00/00/0000");
  const [lastTimeUpdate, setLastTimeUpdate] = React.useState(new Date());
  const [data, setData] = React.useState([]);
  const [serviceType, setServiceType] = React.useState("");
  const [modalData, setModalData] = React.useState([]);

  const classes = useStyles();

  const [openModal, setOpenModal] = React.useState(false);

  React.useEffect(() => {
    async function loadData() {
      const response = await api.get("reports/sales-dashboard");

      setDateFrom(response.data.date_range_from);
      setDateTo(response.data.date_range_to);
      setLastTimeUpdate(new Date(response.data.date_time));
      setData(response.data.data);
      console.log(response.data);
    }

    loadData();
  }, []);

  const handleDateChange = date => {
    if (isBefore(date, new Date("2019-09-30")) || isAfter(date, new Date())) {
      console.log("date filter error..");
      setSelectedDate(date);
      return;
    }
    const formatedDate = format(date, "MM/yyyy");

    async function loadData() {
      const response = await api.get("reports/sales-dashboard", {
        params: {
          date: formatedDate
        }
      });

      setDateFrom(response.data.date_range_from);
      setDateTo(response.data.date_range_to);
      setLastTimeUpdate(new Date(response.data.date_time));
      setData(response.data.data);
    }

    loadData();
    setSelectedDate(date);
  };

  const handleModal = (data, service) => {
    console.log(data, service);
    setServiceType(service);
    setModalData(data);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <Heading
                textAlign="center"
                title={`Sales Dashboard - ${format(
                  lastTimeUpdate,
                  "dd/MM/yyyy HH:mm:ss"
                )}`}
              />
              <div>
                <div id="date-from">
                  <Chip label="Date From:" />
                  <Chip label={dateFrom} color="primary" />
                </div>
                <div id="date-to">
                  <Chip label="Date To:" />
                  <Chip label={dateTo} color="primary" />
                </div>
              </div>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12}>
          <GridContainer justify="center">
            <DatePicker
              // variant="inline"
              inputVariant="outlined"
              views={["year", "month"]}
              label="Select Source Month"
              // helperText="With min and max"
              minDate={new Date("2019-10-01")}
              maxDate={new Date()}
              value={selectedDate}
              // onChange={() => handleDateChange}
              onChange={handleDateChange}
            />
          </GridContainer>
        </GridItem>
        {data.map(item => (
          <GridItem key={item.name} xs={12}>
            <Card>
              <CardBody>
                <Heading textAlign="center" title={item.name} />
                <Table
                  // tableHead={["desc", "value"]}
                  tableData={[
                    ["Total Book:", `£ ${item.TotalBook}`],
                    ["SEM Book:", `£ ${item.SEMBook}`],
                    [
                      "No of SEM Accounts",
                      <a
                        key={item.name}
                        onClick={() => handleModal(item, "SEM")}
                      >
                        {item.numPPCAccounts}
                      </a>
                    ],
                    [
                      "No of SmartChat Accounts",
                      <a
                        key={item.name}
                        onClick={() => handleModal(item, "SmartChat")}
                      >
                        {item.numSmartChatAccounts}
                      </a>
                    ],
                    [
                      "No of KMGSelect Accounts",
                      <a
                        key={item.name}
                        onClick={() => handleModal(item, "KMGSelect")}
                      >
                        {item.numKMGSelectAccounts}
                      </a>
                    ],
                    ["Select Book", `£ ${item.KMGSelectBook}`],
                    ["Month to date Revenue", `£ ${item.MonthRevenue}`],
                    ["Revenue Target", `£ ${item.RevenueTarget}`],
                    {
                      color:
                        parseFloat(item.EstimatedVarianceTarget) < 0
                          ? "danger"
                          : "primary",
                      data: [
                        "Estimated Variance to Target",
                        `£ ${item.EstimatedVarianceTarget}`
                      ]
                    },
                    ["Estimated Commisions", `£ ${item.EstimatedCommissions}`],
                    {
                      color:
                        parseFloat(item.EstimatedCommissions3MonthsAgoRevenue) <
                          0
                          ? "danger"
                          : "primary",
                      data: [
                        "Estimated 3 month Revenue Change",
                        `£ ${item.EstimatedCommissions3MonthsAgoRevenue}`
                      ]
                    },
                    ["Month in business", item.MonthInBusiness],
                    [
                      "Estimated End of Month Revenue",
                      `£ ${item.EstimatedEndMonthRevenue}`
                    ]
                  ]}
                />
              </CardBody>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
      <Modal
        aria-labelledby="modal-title"
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <>
          {serviceType === "SEM" && (
            <div className={classes.paper}>
              <Heading
                textAlign="center"
                title={`${serviceType} Clients - ${modalData.name}`}
              />
              <Table
                tableHead={["Client Name", "Spend", "Book"]}
                tableData={modalData.clientsPPC.map(item => [
                  item.client,
                  `£ ${item.cost}`,
                  `£ ${item.book}`
                ])}
              />
            </div>
          )}
          {serviceType === "SmartChat" && (
            <div className={classes.paper}>
              <Heading
                textAlign="center"
                title={`${serviceType} Clients - ${modalData.name}`}
              />
              <Table
                tableHead={["Client Name", "Spend", "Book"]}
                tableData={modalData.clientsSmartChat.map(item => [
                  item.client,
                  `£ ${item.cost}`,
                  `£ ${item.book}`
                ])}
              />
            </div>
          )}
          {serviceType === "KMGSelect" && (
            <div className={classes.paper}>
              <Heading
                textAlign="center"
                title={`${serviceType} Clients - ${modalData.name}`}
              />
              <Table
                tableHead={["Client Name", "Service", "Spend", "Book"]}
                tableData={modalData.clientsKMGSelect.map(item => [
                  item.client,
                  item.service,
                  `£ ${item.cost}`,
                  `£ ${item.book}`
                ])}
              />
              {console.log(modalData.clientsKMGSelect)}
            </div>
          )}
        </>
      </Modal>
    </GridContainer>
  );
}
