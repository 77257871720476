import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Switch } from "react-router-dom";

import history from "./history";

import Private from "./private";
import Guest from "./guest";

import LoginPage from "components/Admin/Auth/LoginPage";
import LogoutPage from "components/Admin/Auth/LogoutPage";
import Profile from "views/Pages/ProfilePage";
import ForgotPasswordPage from "views/Pages/ForgotPasswordPage";

import AdminLayout from "layouts/Admin.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";
import "assets/animate.css";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

const Routes = () => (
  <ConnectedRouter history={history}>
    <Switch>
      <Guest path="/auth" component={LoginPage} />
      <Guest path="/logout" component={LogoutPage} />
      <Guest path="/forgot-password" component={ForgotPasswordPage} />
      <Private path="/admin" component={AdminLayout} />
      <Private path="/" component={AdminLayout} />
    </Switch>
  </ConnectedRouter>
);

export default Routes;
