import React, { useState } from "react";

import PropTypes from "prop-types";

import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";

import Paper from "@material-ui/core/Paper";
// import CardActionArea from "@material-ui/core/CardActionArea";
import Button from "@material-ui/core/Button";
// import Button from "components/CustomButtons/Button";

import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import AddCircle from "@material-ui/icons/AddCircle";

// import CreateCycleDialog from "views/Components/CreateCycleDialog";
import CreateCycleDialog from "./CreateCycles/CreateCycleDialog";

import api from "services/api";

import { formatNumber } from "utils/formats";

import { toastr } from "react-redux-toastr";
import toastrOptions from "variables/toastr";
import SweetAlert from "react-bootstrap-sweetalert";

import { Typography, TableContainer } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Skeleton from "@material-ui/lab/Skeleton";

import { styled } from "@material-ui/core/styles";

const StyledTable = withStyles(theme => ({
  root: {
    border: "1px solid #E0E0E0",
    borderRadius: 4,
    borderCollapse: "unset"
  }
}))(Table);

// const StyledTableCell = withStyles(theme => {
//   console.log(theme);
//   return {
//     head: {
//       backgroundColor: theme.palette.common.black,
//       color: theme.palette.common.white
//     },
//     body: {
//       fontSize: 14,
//       border: 0,
//       color: theme.props.title
//         ? theme.palette.text.secondary
//         : theme.palette.common.black
//     }
//   };
// })(TableCell);

const StyledTableCell = props => {
  const theme = useTheme();

  const useStyles = makeStyles({
    root: {
      color: props.title
        ? theme.palette.text.secondary
        : theme.palette.common.black
    }
  });
  const { children, title, ...rest } = props;
  const classes = useStyles();
  return (
    <TableCell className={classes.root} {...rest}>
      {children}
    </TableCell>
  );
};

StyledTableCell.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.bool
};

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const StyledTypographyTitle = withStyles(theme => ({}));

const useStyles = makeStyles(theme => ({
  cardCycle: {
    // width: "210px"
  },
  cardAddCycleContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "321px"
  },
  cardCycleContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "255px"
    // width: "210px"
  },
  cardActions: {
    justifyContent: "flex-end",
    paddingRight: 16,
    paddingBottom: 23
  },
  addCircle: {
    fontSize: "60px",
    color: "#a2abac",
    paddingBottom: "10px"
  },
  gridItemCycles: {
    "& + div": {
      marginLeft: 19
    }
  },
  gridItemAddCycle: {
    minHeight: "66px"
  },
  gridItemFilterCaption: {
    marginBottom: "9px"
  },
  tableContainer: {
    marginTop: 10
  },
  gridFilterContainer: {
    marginBottom: 12
  },
  paperFilterContainer: {
    padding: "14px 24px 14px 24px"
  },
  paperTotals: {
    padding: "19px 21px 23px 21px",
    display: "flex",
    flexDirection: "column"
  },
  paperCycles: {
    padding: "19px 21px 7px 21px",
    display: "flex",
    flexDirection: "column"
  },
  paperCyclesHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  buttonCreateCycles: {
    marginTop: 27,
    display: "flex",
    alignSelf: "flex-end"
  },
  buttonDeletecycles: {
    color: theme.palette.text.secondary,
    alignSelf: "flex-end"
  },
  bottonEditCycles: {
    display: "flex",
    alignSelf: "flex-end"
  },
  buttonGroup: {
    marginTop: 5,
    display: "flex",
    justifyContent: "flex-end"
  },
  skeletonButton: {
    alignSelf: "flex-end"
  }
}));

let render = 0;
export default function CreateCyclesPages() {
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const [advertiserList, setAdvertiserList] = React.useState([]);
  const [advertiserSelected, setAdvertiserSelected] = React.useState(null);
  const [totals, setTotals] = React.useState({
    totalSEM: 0.0,
    totalSmartChat: 0.0,
    totalKMGSelect: 0.0,
    total: 0.0
  });
  const [cycleToEdit, setCycleToEdit] = React.useState(null);
  const [newCycles, setNewCycles] = React.useState([]);
  const [alert, setAlert] = React.useState(null);

  const handleHideModal = () => {
    setOpenDialog(false);
  };

  React.useEffect(() => {
    // console.log("Now it is -> ", openDialog);
    if (!openDialog) setCycleToEdit(null);
  }, [openDialog]);

  React.useEffect(() => {
    async function loadData() {
      const advertisers = await api.get(
        "advertisers?fields=advertiser_name,vat_payable"
      );
      setAdvertiserList(advertisers.data);
      setIsLoading(false);
    }
    loadData();
  }, []);

  React.useEffect(() => {
    if (newCycles.length) {
      const totalSEM = newCycles
        .filter(item => !item.service_selected.kgm)
        .map(item => item.cycleTotal && parseFloat(item.cycleTotal))
        .reduce((prev, next) => prev + next, 0);
      console.log("totalSEM: ", totalSEM);
      const totalSmartChat = newCycles
        .filter(
          item =>
            item.service_selected.kgm &&
            item.service_selected.name === "Smartchat"
        )
        .map(item => item.cycleTotal && parseFloat(item.cycleTotal))
        .reduce((prev, next) => prev + next, 0);
      console.log("totalSmartChat: ", totalSmartChat);
      const totalKMGSelect = newCycles
        .filter(
          item =>
            item.service_selected.kgm &&
            item.service_selected.name !== "Smartchat"
        )
        .map(item => item.cycleTotal && parseFloat(item.cycleTotal))
        .reduce((prev, next) => prev + next, 0);
      console.log("totalKMGSelect: ", totalKMGSelect);

      const total = totalSEM + totalSmartChat + totalKMGSelect;

      console.log("total: ", total);

      setTotals({
        totalSEM,
        totalSmartChat,
        totalKMGSelect,
        total
      });
    } else {
      setTotals({
        totalSEM: 0.0,
        totalSmartChat: 0.0,
        totalKMGSelect: 0.0,
        total: 0.0
      });
    }
  }, [newCycles]);

  const handleCreateCycleSave = data => {
    console.log("Data: ", data);
    setNewCycles([...newCycles, data]);
  };

  const handleUpdateCycle = (data, index) => {
    console.log("HandleUpdateCycle...");
    let cycles = [...newCycles];
    cycles.splice(index, 1, data);
    setNewCycles(cycles);
  };

  const handleChangeAdvertiser = (event, value) => {
    setAdvertiserSelected(value);
  };

  const handleAddCycle = e => {
    if (advertiserSelected) {
      setOpenDialog(true);
    } else {
      toastr.warning(
        "Select an Advertiser",
        "You need to select an advertiser first.",
        toastrOptions
      );
    }
  };

  const handleEditCycle = index => {
    setCycleToEdit({
      index,
      data: newCycles[index]
    });
    setOpenDialog(true);
  };

  const handleDeleteCycle = index => {
    let cycles = [...newCycles];
    cycles.splice(index, 1);
    setNewCycles(cycles);
  };
  const hideAlert = () => {
    setAlert(null);
    setAdvertiserSelected(null);
    setNewCycles([]);
  };

  const successAlert = () => {
    console.log("successAlert");
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => hideAlert()}
        // confirmBtnCssClass={classes.button + " " + classes.success}
        showConfirm={false}
      >
        The cycle has been succesfully created!
      </SweetAlert>
    );
    setTimeout(hideAlert, 4000);
  };

  return (
    <Grid container>
      {alert}
      CyclePage render: {++render}
      <Grid container className={classes.gridFilterContainer}>
        <Grid item xs={12}>
          <Paper className={classes.paperFilterContainer}>
            <Grid container alignItems="center">
              <Grid item xs={12} className={classes.gridItemFilterCaption}>
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width={400}
                    height={20}
                  />
                ) : (
                  <Typography variant="caption">Select Advertiser</Typography>
                )}
              </Grid>
              <Grid item xs={10}>
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    // width={40}
                    height={96}
                  />
                ) : (
                  <Autocomplete
                    renderInput={params => (
                      <TextField
                        {...params}
                        // label="Advertiser"
                        placeholder="Advertiser"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    id="advertiser"
                    value={advertiserSelected}
                    options={advertiserList}
                    getOptionLabel={option => option.advertiser_name}
                    onChange={handleChangeAdvertiser}
                  />
                )}
              </Grid>
              <Grid
                container
                item
                justify="center"
                alignItems="center"
                xs={2}
                className={classes.gridItemAddCycle}
              >
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="rect"
                    width={181}
                    height={66}
                  />
                ) : (
                  <Button
                    onClick={handleAddCycle}
                    variant="outlined"
                    color="secondary"
                    startIcon={<AddCircle />}
                  >
                    ADD CYCLE
                  </Button>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={10}>
          <Grid container>
            {newCycles.map((cycle, index) => (
              <Grid
                item
                key={index}
                xs={3}
                md={3}
                className={classes.gridItemCycles}
              >
                <Paper className={classes.paperCycles}>
                  <div className={classes.paperCyclesHeader}>
                    <Typography variant="h6">
                      {cycle.service_selected.name}
                    </Typography>
                    <IconButton
                      aria-label="delete"
                      className={classes.margin}
                      onClick={e => handleDeleteCycle(index)}
                    >
                      <CloseIcon fontSize="default" />
                    </IconButton>
                  </div>
                  <TableContainer className={classes.tableContainer}>
                    <StyledTable>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell title component="th" scope="row">
                            Setup Fee
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {formatNumber(cycle.setup_fee)}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell title component="th" scope="row">
                            Tracking & Tech Fee
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {formatNumber(cycle.mngm_fee)}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell title component="th" scope="row">
                            Account Budget
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {formatNumber(cycle.budget)}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell title component="th" scope="row">
                            Go Live Date
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            {/* 28th August 2020 */}
                            {cycle.live_date}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell component="th" scope="row">
                            <Typography variant="body1">Total</Typography>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row">
                            <Typography variant="body1">
                              {formatNumber(cycle.cycleTotal)}
                            </Typography>
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </StyledTable>
                  </TableContainer>
                  <div className={classes.buttonGroup}>
                    <Button
                      size="medium"
                      className={classes.buttonDeletecycles}
                      onClick={e => handleDeleteCycle(index)}
                    >
                      DELETE
                    </Button>
                    <Button
                      size="medium"
                      color="secondary"
                      className={classes.bottonEditCycles}
                      onClick={e => handleEditCycle(index)}
                    >
                      EDIT
                    </Button>
                  </div>
                </Paper>
              </Grid>
              // <Grid item key={index} xs={3} md={3}>
              //   <Paper className={classes.cardCycle}>
              //     <CardContent className={classes.cardCycleContent}>
              //       <div>Service: {cycle.service_selected.name}</div>
              //       <div>Acc. Setup Fee: {formatNumber(cycle.setup_fee)}</div>
              //       <div>
              //         Tracking & Tech Fee: {formatNumber(cycle.mngm_fee)}
              //       </div>
              //       <div>Acc. Budget: {formatNumber(cycle.budget)}</div>
              //       {/* <div>1st cycle payment: $ 1300.00</div> */}
              //       <div>Go live from date: {cycle.live_date}</div>
              //       <div>Total: {formatNumber(cycle.cycleTotal)}</div>
              //     </CardContent>
              //     <CardActions className={classes.cardActions}>
              //       <Button
              //         justIcon
              //         round
              //         color="info"
              //         onClick={e => handleEditCycle(index)}
              //       >
              //         <EditIcon />
              //       </Button>
              //       <Button
              //         justIcon
              //         round
              //         color="danger"
              //         onClick={e => handleDeleteCycle(index)}
              //       >
              //         <DeleteForeverIcon />
              //       </Button>
              //     </CardActions>
              //   </Paper>
              // </Grid>
            ))}
            {
              // <Grid item xs={3} md={3}>
              //   <Paper className={classes.paperCycles}>
              //     <div className={classes.paperCyclesHeader}>
              //       <Typography variant="h6">PPC</Typography>
              //       <IconButton aria-label="delete" className={classes.margin}>
              //         <CloseIcon fontSize="medium" />
              //       </IconButton>
              //     </div>
              //     <TableContainer className={classes.tableContainer}>
              //       <StyledTable>
              //         <TableBody>
              //           <StyledTableRow>
              //             <StyledTableCell title component="th" scope="row">
              //               Setup Fee
              //             </StyledTableCell>
              //             <StyledTableCell component="th" scope="row">
              //               $200.00
              //             </StyledTableCell>
              //           </StyledTableRow>
              //           <StyledTableRow>
              //             <StyledTableCell title component="th" scope="row">
              //               Tracking & Tech Fee
              //             </StyledTableCell>
              //             <StyledTableCell component="th" scope="row">
              //               $150.00
              //             </StyledTableCell>
              //           </StyledTableRow>
              //           <StyledTableRow>
              //             <StyledTableCell title component="th" scope="row">
              //               Account Budget
              //             </StyledTableCell>
              //             <StyledTableCell component="th" scope="row">
              //               $1,500.00
              //             </StyledTableCell>
              //           </StyledTableRow>
              //           <StyledTableRow>
              //             <StyledTableCell title component="th" scope="row">
              //               Go Live Date
              //             </StyledTableCell>
              //             <StyledTableCell component="th" scope="row">
              //               28th August 2020
              //             </StyledTableCell>
              //           </StyledTableRow>
              //           <StyledTableRow>
              //             <StyledTableCell component="th" scope="row">
              //               <Typography variant="body1">Total</Typography>
              //             </StyledTableCell>
              //             <StyledTableCell component="th" scope="row">
              //               <Typography variant="body1">$1,800.00</Typography>
              //             </StyledTableCell>
              //           </StyledTableRow>
              //         </TableBody>
              //       </StyledTable>
              //     </TableContainer>
              //     <div className={classes.buttonGroup}>
              //       <Button
              //         size="medium"
              //         className={classes.buttonDeletecycles}
              //       >
              //         DELETE
              //       </Button>
              //       <Button
              //         size="medium"
              //         color="secondary"
              //         className={classes.bottonEditCycles}
              //       >
              //         EDIT
              //       </Button>
              //     </div>
              //   </Paper>
              // </Grid>
              /*

            <GridItem xs={3} md={3}>
              <Card className={classes.cardCycle}>
                <CardContent className={classes.cardCycleContent}>
                  <div>Service: SmartChat</div>
                  <div>Acc. Setup Fee: $ 100.00</div>
                  <div>Acc. Budget: $ 201.60</div>
                  <div>Price Per Lead: 9.60</div>
                  <div>1st cycle payment: $ 360.00</div>
                  <div>Lead per Cycle: 21</div>
                  <div>Go live from date: 01/03/2020</div>
                  <div>Total: 301.60</div>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button justIcon round color="info">
                    <EditIcon />
                  </Button>
                  <Button justIcon round color="danger">
                    <DeleteForeverIcon />
                  </Button>
                </CardActions>
              </Card>
            </GridItem>

            <GridItem xs={3} md={3}>
              <Card className={classes.cardCycle}>
                <CardContent className={classes.cardCycleContent}>
                  <div>Service: Retainer</div>
                  <div>Acc. Budget: $ 10,000.60</div>
                  <div>1st cycle payment: $ 10,000.00</div>
                  <div>Go live from date: 01/03/2020</div>
                  <div>Total: 10,000.00</div>
                </CardContent>
                <CardActions className={classes.cardActions}>
                  <Button justIcon round color="info">
                    <EditIcon />
                  </Button>
                  <Button justIcon round color="danger">
                    <DeleteForeverIcon />
                  </Button>
                </CardActions>
              </Card>
            </GridItem> */
            }

            {/* <GridItem xs={3} md={3}>
              <Card
                onClick={() => {
                  console.log("clicked");
                  setOpenDialog(true);
                }}
              >
                <CardContent className={classes.cardAddCycleContent}>
                  <AddCircleOutlineIcon className={classes.addCircle} />
                  ADD CYCLE
                </CardContent>
              </Card>
            </GridItem> */}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Paper className={classes.paperTotals}>
            {isLoading ? (
              <>
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={138}
                  height={32}
                />
                <br />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  // width={313}
                  height={188}
                />
                <br />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={160}
                  height={42}
                  className={classes.skeletonButton}
                />
              </>
            ) : (
              <>
                {/* <CardContent> */}
                <Typography variant="h6" component="span">
                  Totals
                </Typography>
                <TableContainer className={classes.tableContainer}>
                  <StyledTable>
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell title component="th" scope="row">
                          <Typography variant="body2">Total SEM</Typography>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {formatNumber(totals.totalSEM)}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell title component="th" scope="row">
                          Total SmartChat
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {formatNumber(totals.totalSmartChat)}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell title component="th" scope="row">
                          Total KMGSelect
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {formatNumber(totals.totalKMGSelect)}
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          <Typography variant="body1">Total</Typography>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <Typography variant="body1">
                            {formatNumber(totals.total)}
                          </Typography>
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </StyledTable>
                </TableContainer>
                {/* </CardContent> */}
                {/* <CardActions className={classes.cardActions}> */}
                <Button
                  size="large"
                  color="primary"
                  className={classes.buttonCreateCycles}
                  onClick={successAlert}
                >
                  Create Cycles
                </Button>
                {/* </CardActions> */}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      <CreateCycleDialog
        open={openDialog}
        handleClose={handleHideModal}
        handleSave={handleCreateCycleSave}
        handleUpdate={handleUpdateCycle}
        advertiser={advertiserSelected}
        initialValues={cycleToEdit}
      />
      {/* <InfoArea title="blabla" description="huhuhu" iconColor="success" /> */}
    </Grid>
  );
}
