import React from "react";

import { toastr } from "react-redux-toastr";

// form validation
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import {
  ERROR_MESSAGE_REQUIRED,
  ERROR_MESSAGE_NUMBER,
  ERROR_MESSAGE_NUMBER_MIN
} from "variables/messages";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import Close from "@material-ui/icons/Close";

// core componentes
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import api from "services/api";

import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

const useStyles = makeStyles(styles);
const settingsStyles = makeStyles({
  saveButton: {
    float: "center"
  }
});

const toastrOptions = {
  closeButton: false,
  timeOut: 4000,
  progressBar: true
};

let renderCount = 0;

const BankDetailsSchema = yup.object().shape({
  companynumber: yup.string().required(ERROR_MESSAGE_REQUIRED),
  vatnumber: yup
    .number()
    .integer()
    .min(100000000, ERROR_MESSAGE_NUMBER_MIN)
    .required()
    .typeError(ERROR_MESSAGE_NUMBER),
  iban: yup.string().required(ERROR_MESSAGE_REQUIRED),
  accountnumber: yup.string().required(),
  sortcode: yup.string().required()
});

export default function BankDetailsPage() {
  const classes = useStyles();

  const { register, handleSubmit, errors, reset } = useForm({
    // defaultValues: {
    //   directdebitkey: "abcdef"
    // },
    validationSchema: BankDetailsSchema,
    mode: "onBlur"
  }); // initialise the hook

  React.useEffect(() => {
    async function loadData() {
      const result = await api.get("bank-details");

      const {
        company_number,
        vat_number,
        iban,
        account_number,
        sort_code
      } = result.data;

      reset({
        companynumber: company_number,
        vatnumber: vat_number,
        iban,
        accountnumber: account_number,
        sortcode: sort_code
      });
    }
    loadData();
  }, []);

  const onSubmit = (data, e) => {
    e.preventDefault();
    console.log(data);
    toastr.success(
      "Bank Details",
      "The bank details settings has been successfully saved",
      toastrOptions
    );
    // props.history.push("/");
  };

  return (
    <div className="animated fadeInRight">
      <span className="counter">Render Count: {renderCount}</span>
      <GridContainer>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <SettingsOutlinedIcon />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Bank Details</h4>
          </CardHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardBody>
              <GridContainer
                directio="row"
                justify="center"
                alignItems="center"
              >
                <GridContainer xs={8} item={true}>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Company Number
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdorment: errors.companynumber ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }}
                      inputRef={register}
                      error={!!errors.companynumber}
                      helperText={
                        errors.companynumber ? errors.companynumber.message : ""
                      }
                      id="companynumber"
                      name="companynumber"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer xs={8} item={true}>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      VAT Number
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdorment: errors.vatnumber ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }}
                      inputRef={register}
                      error={!!errors.vatnumber}
                      helperText={
                        errors.vatnumber ? errors.vatnumber.message : ""
                      }
                      id="vatnumber"
                      name="vatnumber"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer xs={8} item={true}>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      IBAN
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdorment: errors.vatnumber ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }}
                      inputRef={register}
                      error={!!errors.iban}
                      helperText={errors.iban ? errors.iban.message : ""}
                      id="iban"
                      name="iban"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer xs={8} item={true}>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Account Number
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdorment: errors.vatnumber ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }}
                      inputRef={register}
                      error={!!errors.accountnumber}
                      helperText={
                        errors.accountnumber ? errors.accountnumber.message : ""
                      }
                      id="accountnumber"
                      name="accountnumber"
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer xs={8} item={true}>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      Sort Code
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={9}>
                    <CustomInput
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdorment: errors.vatnumber ? (
                          <InputAdornment position="end">
                            <Close className={classes.danger} />
                          </InputAdornment>
                        ) : (
                          undefined
                        )
                      }}
                      inputRef={register}
                      error={!!errors.sortcode}
                      helperText={
                        errors.sortcode ? errors.sortcode.message : ""
                      }
                      id="sortcode"
                      name="sortcode"
                    />
                  </GridItem>
                </GridContainer>
              </GridContainer>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button color="rose" type="submit">
                Save Your Changes
              </Button>
            </CardFooter>
          </form>
        </Card>
      </GridContainer>
    </div>
  );
}
